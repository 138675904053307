import axios from "axios";
import Base58 from "bs58";
import init, {
  Hash,
  P2PKHAddress,
  Script,
  SigHash,
  Transaction,
  TxIn,
  TxOut,
  PrivateKey,
} from "bsv-wasm-web";
import { useEffect, useState } from "react";
import {
  DEFAULT_TP_WALLET_PATH,
  DEFAULT_NABOX_WALLET_PATH,
  DEFAULT_OKX_WALLET_PATH,
  FEE_PER_BYTE,
  SWEEP_PATH,
} from "../utils/constants";
import {
  decrypt,
  deriveKey,
  encrypt,
  generateRandomSalt,
} from "../utils/crypto";
import {
  generateKeysFromTag,
  getKeys,
  getKeysFromWifs,
  Keys,
} from "../utils/keys";
import { UTXO } from "./useTbc";
import { useTBChainPyPool } from "./useTBChainPyPool";
import { useNetwork } from "./useNetwork";
import { usePasswordSetting } from "./usePasswordSetting";
import { useTuringBitChain } from "./useTuringBitChain";
import { useAccounts } from "./useAccounts";
import * as tbc from "tbc-lib-js";

import * as contract from "tbc-contract";
import { set } from "lodash";

export type KeyStorage = {
  encryptedKeys: string;
  passKey: string;
  salt: string;
  msAddress: string;
};

export type WifKeys = {
  payPk: string;
  ordPk?: string;
  mnemonic?: string;
  identityPk?: string;
};

export type SupportedWalletImports =
  | "turings"
  | "tp"
  | "okx"
  | "nabox"
  | "wif"
  | "taproot";

export const useKeys = () => {
  const [tbcAddress, setTbcAddress] = useState("");
  const [ordAddress, setOrdAddress] = useState("");
  const [identityAddress, setIdentityAddress] = useState("");
  const [tbcPubKey, setTbcPubKey] = useState("");
  const [ordPubKey, setOrdPubKey] = useState("");
  const [identityPubKey, setIdentityPubKey] = useState("");
  const [taprootAddress, setTaprootAddress] = useState("");
  const [accountType, setAccountType] = useState(1);

  const {
    addAccount,
    getCurrAccount,
    getStorage,
    Account,
    initStorage,
    allAccounts,
  } = useAccounts();
  const { isAddressOnRightNetwork } = useNetwork();
  const { isPasswordRequired } = usePasswordSetting();
  const { getBaseUrl } = useTuringBitChain();
  const { broadcastWithTBChainPyPool } = useTBChainPyPool();

  // const getChainParams = (network: NetWork): ChainParams => {
  //   return network === NetWork.Mainnet ? ChainParams.mainnet() : ChainParams.testnet();
  // };

  // useEffect(() => {
  //   (async () => {
  //     await init();
  //     if (tbcPubKey) {
  //       const walletAddr = PublicKey.from_hex(tbcPubKey)
  //         .to_address()
  //         .set_chain_params(getChainParams(network))
  //         .to_string();

  //       setTbcAddress(walletAddr);
  //     }

  //     if (ordPubKey) {
  //       const ordAddr = PublicKey.from_hex(ordPubKey)
  //         .to_address()
  //         .set_chain_params(getChainParams(network))
  //         .to_string();

  //       setOrdAddress(ordAddr);
  //     }
  //   })();
  // }, [tbcPubKey, ordPubKey, network]);

  const checkPaykey = (payPk: string) => {
    const buf = Buffer.from(Base58.decode(payPk));
    if (buf.length < 4) {
      return false;
    }

    const data = buf.toString("hex").slice(0, -8);
    const csum = buf.toString("hex").slice(-8);

    const hash = Hash.sha_256d(Buffer.from(data, "hex"));
    const hash4 = hash.to_bytes().slice(0, 4);
    if (csum !== hash4.toString()) {
      return false;
    }

    return true;
  };

  const generateMultiSignAddress = async (
    pubkeys: string[],
    signatureCount: number,
    password: string
  ): Promise<string> => {
    const { walletAddress, walletWif } = await retrieveKeys(password);
    if (!walletAddress || !walletWif) throw new Error("Invalid keys!");
    const paymentPk = PrivateKey.from_wif(walletWif).to_hex();
    const tbcPrivateKey = tbc.PrivateKey.fromString(paymentPk);

    try {
      const utxos = await contract.API.getUTXOs(walletAddress, 0.01, "mainnet");
      const txraw = contract.MultiSig.createMultiSigWallet(
        walletAddress,
        pubkeys,
        signatureCount,
        pubkeys.length,
        utxos,
        tbcPrivateKey
      );
      const txid = await contract.API.broadcastTXraw(txraw, "mainnet");
      console.log(txid);

      return contract.MultiSig.getMultiSigAddress(
        pubkeys,
        signatureCount,
        pubkeys.length
      );
    } catch (error) {
      throw new Error("Create failed!");
    }
  };

  interface MultiSigWallet {
    multi_address: string;
    pubkey_list: string[];
  }

  interface PubKeyInfo {
    multiSigAddress: string;
    pubKeys: string[];
  }

  const getMultiSignAddressPubkeys = (address: string): PubKeyInfo[] => {
    try {
      const storedData = localStorage.getItem(
        `multiSignAddressPubkeys:${address}`
      );

      if (!storedData) {
        throw new Error(
          "No data found in localStorage for the provided address."
        );
      }
      const parsedData: { [key: string]: string[] } = JSON.parse(storedData);
      const result: PubKeyInfo[] = Object.entries(parsedData).map(
        ([multiSigAddress, pubKeys]) => ({
          multiSigAddress,
          pubKeys,
        })
      );
      return result;
    } catch (error) {
      throw error;
    }
  };

  const getMultiSignAddressPubkeys_dictionary = (
    address: string
  ): { [key: string]: string[] } => {
    try {
      const storedData = localStorage.getItem(
        `multiSignAddressPubkeys:${address}`
      );

      if (!storedData) {
        throw new Error(
          "No data found in localStorage for the provided address."
        );
      }
      const parsedData: { [key: string]: string[] } = JSON.parse(storedData);
      return parsedData;
    } catch (error) {
      console.error(
        "Error retrieving multisign address pubkeys from localStorage:",
        error
      );
      throw error;
    }
  };

  const fetchMultiSignAddressPubkeys = async (
    address: string
  ): Promise<{ [key: string]: string[] }> => {
    const url = `https://turingwallet.xyz/v1/tbc/main/multisig/pubkeys/address/${address}`;

    try {
      const response = await axios.get<{ multi_wallet_list: MultiSigWallet[] }>(
        url
      );
      let result = response.data.multi_wallet_list.map(
        (wallet: MultiSigWallet) => ({
          multiSigAddress: wallet.multi_address,
          pubKeys: wallet.pubkey_list,
        })
      );

      result = result.filter(
        (wallet, index, self) =>
          wallet.pubKeys.length > 0 &&
          index ===
          self.findIndex(
            (t) =>
              t.multiSigAddress === wallet.multiSigAddress &&
              t.pubKeys.length > 0
          )
      );

      const transformedResult: { [key: string]: string[] } = {};
      result.forEach((wallet) => {
        transformedResult[wallet.multiSigAddress] = wallet.pubKeys;
      });
      localStorage.setItem(
        `multiSignAddressPubkeys:${address}`,
        JSON.stringify(transformedResult)
      );
      return transformedResult;
    } catch (error: any) {
      console.error("Error fetching multisign address pubkeys:", error);
      throw new Error(error.message);
    }
  };

  const generateSeedAndStoreEncrypted = async (
    //函数的主要目的看起来是为了生成一种加密形式的种子或秘密信息，并可能涉及钱包导入以及与不同服务相关的派生路径
    accountName: string,
    password: string,
    isFirstLoaded: boolean,
    mnemonic?: string,
    walletDerivation: string | null = null,
    ordDerivation: string | null = null,
    identityDerivation: string | null = null,
    importWallet?: SupportedWalletImports
  ) => {
    const { passKey, salt } = await getPassKeyAndSalt(password, isFirstLoaded);
    switch (importWallet) {
      case "tp":
        walletDerivation = DEFAULT_TP_WALLET_PATH;
        break;
      case "okx":
        walletDerivation = DEFAULT_OKX_WALLET_PATH;
        break;
      case "nabox":
        walletDerivation = DEFAULT_NABOX_WALLET_PATH;
        break;
    }
    const keys = getKeys(
      mnemonic,
      walletDerivation,
      ordDerivation,
      identityDerivation
    );
    if (keys == null) return null;
    // if (mnemonic) {
    //   sweepLegacy(keys);
    // }
    const encryptedKeys = encrypt(JSON.stringify(keys), passKey);
    await addAccount(accountName, encryptedKeys, passKey, salt, keys);
    return keys;
  };

  const getPassKeyAndSalt = async (
    password: string,
    isFirstLoaded: boolean
  ) => {
    if (!isFirstLoaded) {
      const isVerified = await verifyPassword(password);
      if (!isVerified) throw new Error("Unauthorized!");
      const accountObject = await getStorage();
      if (!accountObject?.salt || !accountObject?.passKey)
        throw new Error("Credentials not found");
      return { passKey: accountObject.passKey, salt: accountObject.salt };
    } else {
      const salt = generateRandomSalt();
      if (!salt) throw new Error("Salt not found");
      const passKey = deriveKey(password, salt);
      if (!passKey) throw new Error("Passkey not found");
      return { passKey, salt };
    }
  };

  const sweepLegacy = async (keys: Keys) => {
    //用于扫描和转移指定地址上的未花费交易输出（UTXO），并将其合并为一笔新的交易以进行转移
    return;
    await init();
    const sweepWallet = generateKeysFromTag(keys.mnemonic, SWEEP_PATH);
    if (!isAddressOnRightNetwork(sweepWallet.address)) return;
    const { data } = await axios.get<UTXO[]>(
      `${getBaseUrl()}/address/${sweepWallet.address}/unspent`
    );
    const utxos = data;
    if (utxos.length === 0) return;
    const tx = new Transaction(1, 0);
    const changeAddress = P2PKHAddress.from_string(sweepWallet.address);

    let satsIn = 0;
    utxos.forEach((utxo: any, vin: number) => {
      //@ts-ignore
      const txin = new TxIn(
        Buffer.from(utxo.tx_hash, "hex"),
        utxo.tx_pos,
        Script.from_hex("")
      );
      tx.add_input(txin);
      satsIn += utxo.value;
      const sig = tx.sign(
        sweepWallet.privKey,
        SigHash.Input,
        vin,
        changeAddress.get_locking_script(),
        BigInt(utxo.value)
      );
      const asm = `${sig.to_hex()} ${sweepWallet.pubKey.to_hex()}`;
      txin?.set_unlocking_script(Script.from_asm_string(asm));
      tx.set_input(vin, txin);
    });

    const size = tx.to_bytes().length + 34;
    const fee = Math.ceil(size * FEE_PER_BYTE);
    const changeAmount = satsIn - fee;
    tx.add_output(
      new TxOut(
        BigInt(changeAmount),
        P2PKHAddress.from_string(keys.walletAddress).get_locking_script()
      )
    );

    const rawTx = tx.to_hex();
    const { txid } = await broadcastWithTBChainPyPool(rawTx);
    //console.log('Change sweep:', txid);
  };

  const generateKeysFromWifAndStoreEncrypted = async (
    accountName: string,
    password: string,
    wifs: WifKeys,
    isFirstLoaded: boolean
  ) => {
    const { passKey, salt } = await getPassKeyAndSalt(password, isFirstLoaded);
    const keys = getKeysFromWifs(wifs);
    const encryptedKeys = encrypt(JSON.stringify(keys), passKey);
    await addAccount(accountName, encryptedKeys, passKey, salt, keys);
    return keys;
  };

  const getTaprootTweakPrivateKey = (privateKeyWif: string): string => {
    const privateKeyHex = tbc.Taproot.wifToSeckey(privateKeyWif);
    const taprootTweakPrivateKey =
      tbc.Taproot.seckeyToTaprootTweakSeckey(privateKeyHex);
    return taprootTweakPrivateKey.toString("hex");
  }

  const getTaprootTweakPublicKey = (privateKeyWif: string): string => {
    const privateKeyHex = tbc.Taproot.wifToSeckey(privateKeyWif);
    const publicKey = tbc.Taproot.pubkeyGen(privateKeyHex);
    return tbc.Taproot.pubkeyToTaprootTweakPubkey(publicKey).toString("hex");
  }

  const generateTaprootKeysFromWifAndStoreEncrypted = async (
    accountName: string,
    password: string,
    wifs: WifKeys,
    isFirstLoaded: boolean
  ) => {
    const { passKey, salt } = await getPassKeyAndSalt(password, isFirstLoaded);
    const tbcKeys = getKeysFromWifs(wifs);
    const privateKeyHex = tbc.Taproot.wifToSeckey(wifs.payPk);
    const publicKey = tbc.Taproot.pubkeyGen(privateKeyHex);
    const taprootTweakPublicKey = tbc.Taproot.pubkeyToTaprootTweakPubkey(publicKey);
    const taprootAddress = tbc.Taproot.taprootTweakPubkeyToTaprootAddress(
      taprootTweakPublicKey
    );
    const taprootLegacyAddress = tbc.Taproot.pubkeyToLegacyAddress(
      taprootTweakPublicKey
    );

    const keys: Partial<Keys> = {
      ...tbcKeys,
      walletAddress: taprootLegacyAddress,
      taprootAddress: taprootAddress,
    };
    const encryptedKeys = encrypt(JSON.stringify(keys), passKey);
    await addAccount(accountName, encryptedKeys, passKey, salt, keys, 2);
    return keys;
  };
  /**
   *
   * @param password An optional password can be passed to unlock sensitive information
   * @returns
   */
  const retrieveKeys = async (
    password?: string,
    isBelowNoApprovalLimit?: boolean
  ): Promise<Keys | Partial<Keys>> => {
    try {
      await init();
      const result = await getStorage();
      // 获取当前账户信息
      const currAccount = await getCurrAccount();
      // 如果当前账户存在加密密钥和密码，则进行解密
      if (currAccount?.encryptedKeys && result?.passKey) {
        const decryptedData = decrypt(
          currAccount.encryptedKeys,
          result.passKey
        );
        const keys: Keys = JSON.parse(decryptedData);
        setTbcAddress(keys.walletAddress);
        setOrdAddress(keys.ordAddress);
        setTbcPubKey(keys.walletPubKey);
        setOrdPubKey(keys.ordPubKey);
        const type = getAccountTypeByTbcAddress(keys.walletAddress);
        setAccountType(type ?? 1);

        // 如果存在身份地址，进行格式化处理
        if (keys.identityAddress) {
          setIdentityAddress(keys.identityAddress);
          setIdentityPubKey(keys.identityPubKey);
        }
        if (keys.taprootAddress) {
          setTaprootAddress(keys.taprootAddress);
        }

        // 根据是否需要密码验证来判断是否解锁
        if (!isPasswordRequired || isBelowNoApprovalLimit || password) {
          const isVerified =
            isBelowNoApprovalLimit ||
            !isPasswordRequired ||
            (await verifyPassword(password ?? ""));

          // 如果密码验证成功，返回完整的密钥对象
          if (isVerified) {
            return Object.assign({}, keys, {
              ordAddress: keys.ordAddress,
              walletAddress: keys.walletAddress,
            });
          } else {
            throw new Error("Unauthorized!");
          }
        } else {
          // 如果不需要密码验证，返回部分密钥信息
          return {
            ordAddress: keys.ordAddress,
            walletAddress: keys.walletAddress,
            walletPubKey: keys.walletPubKey,
            ordPubKey: keys.ordPubKey,
          };
        }
      } else {
        throw new Error("Encrypted keys or passKey not found");
      }
    } catch (error: any) {
      throw new Error(`Error retrieving keys: ${error.message}`);
    }
  };

  const verifyPassword = async (password: string): Promise<boolean> => {
    return new Promise(async (resolve, reject) => {
      if (!isPasswordRequired) resolve(true);
      try {
        const result = await getStorage();
        const derivedKey = deriveKey(password, result.salt);
        resolve(derivedKey === result.passKey);
      } catch (error) {
        reject(error);
      }
    });
  };

  function getAccountTypeByTbcAddress(tbcAddress: string): number | null {
    const accountsString = localStorage.getItem('accounts');
    if (!accountsString) return null;
    let accounts: { [key: string]: any } = JSON.parse(accountsString);
    if (typeof accounts !== 'object' || accounts === null || Object.keys(accounts).length === 0) {
      return null;
    }
    const account = accounts[tbcAddress];
    return account ? account.accountType : null;
  }

  useEffect(() => {
    initStorage();
  }, []);

  useEffect(() => {
    if (!Account) return;
    retrieveKeys();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Account]);

  return {
    allAccounts,
    Account,
    checkPaykey,
    generateSeedAndStoreEncrypted,
    generateKeysFromWifAndStoreEncrypted,
    generateTaprootKeysFromWifAndStoreEncrypted,
    retrieveKeys,
    verifyPassword,
    generateMultiSignAddress,
    tbcAddress,
    ordAddress,
    identityAddress,
    tbcPubKey,
    ordPubKey,
    identityPubKey,
    taprootAddress,
    getMultiSignAddressPubkeys,
    fetchMultiSignAddressPubkeys,
    getMultiSignAddressPubkeys_dictionary,
    getTaprootTweakPrivateKey,
    getAccountTypeByTbcAddress,
    accountType,
    getTaprootTweakPublicKey,
  };
};
