//这里是代码交易在本地钱包的工作流程
//这里是与发送币相关的代码
import init, {
  ChainParams,
  P2PKHAddress,
  PrivateKey,
  PublicKey,
  Script,
  SigHash,
  Signature,
  Transaction,
  TxIn,
  TxOut,
} from 'bsv-wasm-web';
import * as tbc from 'tbc-lib-js';
import * as contract from 'tbc-contract';
// import { buildInscription } from 'js-1sat-ord-web';
import { useEffect, useState } from 'react';
import { SignMessageResponse } from '../pages/requests/SignMessageRequest';
import {
  TBC_DECIMAL_CONVERSION,
  FEE_PER_BYTE,
  MAX_BYTES_PER_TX,
  MAX_FEE_PER_TX,
  P2PKH_INPUT_SIZE,
  P2PKH_OUTPUT_SIZE,
  DUST,
} from '../utils/constants';
import { removeBase64Prefix } from '../utils/format';
import { DerivationTag, getPrivateKeyFromTag, Keys } from '../utils/keys';
import { NetWork } from '../utils/network';
import { storage } from '../utils/storage';
import { useTBChainPyPool } from './useTBChainPyPool';
import { useKeys } from './useKeys';
import { useNetwork } from './useNetwork';
import { useTuringBitChain } from './useTuringBitChain';
import { sleep } from '../utils/sleep';


export interface UTXO {
  satoshis: number;
  vout: number;
  txid: string;
  script: string;
}

export interface StoredUtxo extends UTXO {
  spent: boolean;
  spentUnixTime: number;
}

type SendTbcResponse = {
  txid?: string;
  rawtx?: string;
  amount?: number;
  change?: number;
  fee?: number;
  error?: string;
};

type FundRawTxResponse = { rawtx?: string; error?: string };

export type MimeTypes =
  | 'text/plain'
  | 'text/html'
  | 'text/css'
  | 'application/javascript'
  | 'application/json'
  | 'application/xml'
  | 'image/jpeg'
  | 'image/png'
  | 'image/gif'
  | 'image/svg+xml'
  | 'audio/mpeg'
  | 'audio/wav'
  | 'audio/wave'
  | 'video/mp4'
  | 'application/pdf'
  | 'application/msword'
  | 'application/vnd.ms-excel'
  | 'application/vnd.ms-powerpoint'
  | 'application/zip'
  | 'application/x-7z-compressed'
  | 'application/x-gzip'
  | 'application/x-tar'
  | 'application/x-bzip2';

export type MAP = { app: string; type: string;[prop: string]: string };

export type RawInscription = {
  base64Data: string;
  mimeType: MimeTypes;
  map?: MAP;
};

export type Web3SendTransactionRequest = {
  satoshis: number;
  address?: string;
  collection_data?: string;
  ft_data?: string;
  nft_data?: string;
  collection_id?: string;
  nft_contract_address?: string;
  ft_contract_address?: string;
  tbc_amount?: number;
  ft_amount?: number;
  flag?: "P2PKH" | "COLLECTION_CREATE" | "NFT_CREATE" | "NFT_TRANSFER" | "FT_MINT" | "FT_TRANSFER" | "POOLNFT_MINT" | "POOLNFT_INIT" | "POOLNFT_LP_INCREASE" | "POOLNFT_LP_CONSUME" | "POOLNFT_SWAP_TO_TOKEN" | "POOLNFT_SWAP_TO_TBC" | "POOLNFT_MERGE" | "FTLP_MERGE";
  merge_times?: number;
  with_lock?: boolean;
  data?: string[];
  script?: string;
  inscription?: RawInscription;
}[];

export type Web3BroadcastRequest = {
  rawtx: string;
  fund?: boolean;
};

export type Web3SignMessageRequest = {
  message: string;
  encoding?: 'utf8' | 'hex' | 'base64';
  tag?: DerivationTag;
};

export type Web3EncryptRequest = {
  message: string;
  tag?: DerivationTag;
};

export type Web3DecryptRequest = {
  message: string;
  tag?: DerivationTag;
};

export const useTbc = () => {
  const [tbcBalance, setTbcBalance] = useState(0);
  const [exchangeRate, setExchangeRate] = useState(0);
  const [changePercent, setChangePercent] = useState(0);
  const [isProcessing, setIsProcessing] = useState(false);
  const { retrieveKeys, tbcAddress, verifyPassword, tbcPubKey, identityAddress, identityPubKey, accountType, getTaprootTweakPrivateKey, getTaprootTweakPublicKey } = useKeys();
  const { network } = useNetwork();
  const { broadcastWithTBChainPyPool, getTxOut } = useTBChainPyPool();
  const { getUtxos, getTbcBalance, getExchangeRate, getInputs, getInputs_merge } = useTuringBitChain();

  const getChainParams = (network: NetWork): ChainParams => {
    return network === NetWork.Mainnet ? ChainParams.mainnet() : ChainParams.testnet();
  };

  // useEffect(() => {
  //   if (!tbcAddress) return;
  //   getUtxos(tbcAddress);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tbcAddress]);
  const sendTbcToMulti = async (receiveAddress: string, sendAmount: number, passwordConfirm: string) => {
    try {
      const amount_tbc = sendAmount * 1e-6;
      const { walletAddress, walletWif } = await retrieveKeys(passwordConfirm);
      if (!walletAddress || !walletWif) throw new Error('Invalid keys!');
      const paymentPk = PrivateKey.from_wif(walletWif).to_hex();
      const tbcPrivateKey = tbc.PrivateKey.fromString(paymentPk);

      const utxos = await contract.API.getUTXOs(walletAddress, amount_tbc + 0.01, "mainnet");

      const txraw = contract.MultiSig.p2pkhToMultiSig_sendTBC(walletAddress, receiveAddress, amount_tbc, utxos, tbcPrivateKey);
      await contract.API.broadcastTXraw(txraw, "mainnet");
      return {
        result: true
      };
    } catch (error: any) {
      return { error: error.message ?? 'send fail' };
    }

  }
  const multiSend = async (
    multisigAddress: string,
    tokenAddress: string | undefined,
    receiveAddress: string,
    sendAmount: number,
    passwordConfirm: string) => {
    try {
      setIsProcessing(true);
      await init();

      const { walletAddress, walletWif } = await retrieveKeys(passwordConfirm);
      if (!walletAddress || !walletWif) throw new Error('Invalid keys!');

      const payWifPk = walletWif;
      const paymentPk = PrivateKey.from_wif(payWifPk).to_hex();
      const tbcPrivateKey = tbc.PrivateKey.fromString(paymentPk);

      if (!tokenAddress) {
        const script_asm = contract.MultiSig.getMultiSigLockScript(multisigAddress);
        const umtxos = await contract.API.getUMTXOs(script_asm, sendAmount + 0.0003, "mainnet");
        const multiTxraw = contract.MultiSig.buildMultiSigTransaction_sendTBC(multisigAddress, receiveAddress, sendAmount, umtxos);
        const sigs = contract.MultiSig.signMultiSigTransaction_sendTBC(multisigAddress, multiTxraw, tbcPrivateKey);
        return { multiTxraw, sigs };
      } else {
        const script_asm = contract.MultiSig.getMultiSigLockScript(multisigAddress);
        const umtxo = await contract.API.fetchUMTXO(script_asm, "mainnet");
        const Token = new contract.FT(tokenAddress);
        const TokenInfo = await contract.API.fetchFtInfo(Token.contractTxid, "mainnet");
        Token.initialize(TokenInfo);
        const transferTokenAmountBN = BigInt(Math.ceil(sendAmount * Math.pow(10, Token.decimal)));
        const hash_from = tbc.crypto.Hash.sha256ripemd160(tbc.crypto.Hash.sha256(tbc.Script.fromASM(script_asm).toBuffer())).toString("hex");
        const ftutxo_codeScript = contract.FT.buildFTtransferCode(Token.codeScript, hash_from).toBuffer().toString('hex');
        const ftutxos = await contract.API.fetchFtUTXOS_multiSig(Token.contractTxid, hash_from, ftutxo_codeScript, transferTokenAmountBN, "mainnet");//准备ft utxo
        let preTXs: tbc.Transaction[] = [];
        let prepreTxDatas: string[] = [];
        for (let i = 0; i < ftutxos.length; i++) {
          preTXs.push(await contract.API.fetchTXraw(ftutxos[i].txId, "mainnet"));//获取每个ft输入的父交易
          prepreTxDatas.push(await contract.API.fetchFtPrePreTxData(preTXs[i], ftutxos[i].outputIndex, "mainnet"));//获取每个ft输入的爷交易
        }
        const contractTX = await contract.API.fetchTXraw(umtxo.txId, "mainnet");
        const multiTxraw = contract.MultiSig.buildMultiSigTransaction_transferFT(multisigAddress, receiveAddress, Token, sendAmount, umtxo, ftutxos, preTXs, prepreTxDatas, contractTX, tbcPrivateKey);
        const sigs = contract.MultiSig.signMultiSigTransaction_transferFT(multisigAddress, Token, multiTxraw, tbcPrivateKey);
        return { multiTxraw, sigs };
      }

    } catch (error: any) {
      return { error: error.message ?? 'send fail' };
    }
  }

  const signMultiTbcTransaction = async (passwordConfirm: string, multiAddress: string, txraw: contract.MultiSigTxRaw): Promise<string[]> => {
    const { walletAddress, walletWif } = await retrieveKeys(passwordConfirm);
    if (!walletAddress || !walletWif) throw new Error('Invalid keys!');

    const payWifPk = walletWif;
    const paymentPk = PrivateKey.from_wif(payWifPk).to_hex();
    const tbcPrivateKey = tbc.PrivateKey.fromString(paymentPk);
    const sigs = contract.MultiSig.signMultiSigTransaction_sendTBC(multiAddress, txraw, tbcPrivateKey);
    return sigs;
  }


  const signMultiFTTransaction = async (passwordConfirm: string, multiAddress: string, txraw: contract.MultiSigTxRaw, contract_id: string): Promise<string[]> => {
    try {
      const { walletAddress, walletWif } = await retrieveKeys(passwordConfirm);
      if (!walletAddress || !walletWif) throw new Error('Invalid keys!');

      const payWifPk = walletWif;
      const paymentPk = PrivateKey.from_wif(payWifPk).to_hex();
      const tbcPrivateKey = tbc.PrivateKey.fromString(paymentPk);
      const Token = new contract.FT(contract_id);
      const TokenInfo = await contract.API.fetchFtInfo(Token.contractTxid, "mainnet");
      Token.initialize(TokenInfo);
      const sigs = contract.MultiSig.signMultiSigTransaction_transferFT(multiAddress, Token, txraw, tbcPrivateKey);
      return sigs;
    } catch (error) {
      throw new Error("sign fail");
    }
  }

  const finishMultiTransaction = async (multitxraw: contract.MultiSigTxRaw, sigs: string[][], pubkeys: string[]) => {
    try {
      const txraw = contract.MultiSig.finishMultiSigTransaction_transferFT(multitxraw.txraw, sigs, pubkeys);
      return await contract.API.broadcastTXraw(txraw, "mainnet");
    } catch (error) {
      throw new Error("send fail");
    }
  }

  const assembleTbc = async (
    request: Web3SendTransactionRequest,
    password: string,
    noApprovalLimit?: number,
  ): Promise<SendTbcResponse> => {
    try {
      setIsProcessing(true);
      await init();
      const requestSats = request.reduce((a: number, item: { satoshis: number }) => a + item.satoshis, 0);
      const tbcSendAmount = requestSats / TBC_DECIMAL_CONVERSION;

      if (tbcSendAmount > Number(noApprovalLimit)) {
        const isAuthenticated = await verifyPassword(password);
        if (!isAuthenticated) {
          return { error: 'invalid-password' };
        }
      }

      let feeSats = 80;//这是每笔的最少手续费
      const isBelowNoApprovalLimit = Number(tbcSendAmount) <= Number(noApprovalLimit);
      const keys = await retrieveKeys(password, isBelowNoApprovalLimit);
      if (!keys?.walletWif || !keys.walletPubKey) throw Error('Undefined key');
      let paymentPk: PrivateKey;
      
      if (accountType === 1) {
        paymentPk = PrivateKey.from_wif(keys.walletWif);
      } else {
        paymentPk = PrivateKey.from_hex(getTaprootTweakPrivateKey(keys.walletWif));
      }   
      const fromAddress = tbcAddress;
      const amount = request.reduce((a, r) => a + r.satoshis, 0);

      // Format in and outs
      const fundingUtxos = await getUtxos(fromAddress, false);

      if (!fundingUtxos) throw Error('No Utxos!');
      const totalSats = fundingUtxos.reduce((a: number, item: UTXO) => a + item.satoshis, 0);
      if (totalSats < amount) {
        return { error: 'insufficient-funds' };
      }

      const sendAll = totalSats === amount;
      const satsOut = sendAll ? totalSats - feeSats : amount;
      const inputs = getInputs(fundingUtxos, satsOut + feeSats, sendAll);
      if ('error' in inputs) {
        return { error: inputs.error };
      }

      const totalInputSats = inputs.reduce((a, item) => a + item.satoshis, 0);

      const tx = new Transaction(10, 0)

      request.forEach((req) => {
        let outScript: Script;
        if (req.address) {
          outScript = P2PKHAddress.from_string(req.address).get_locking_script();
        } else if (req.script) {
          outScript = Script.from_hex(req.script);
          //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          // const reqBytes = JSON.stringify(req).length; // 这里是修改fee的逻辑
          // feeSats = Math.max( feeSats,Math.ceil(reqBytes * FEE_PER_BYTE));
        } else if ((req.data || []).length > 0) {
          let asm = `OP_0 OP_RETURN ${req.data?.join(' ')}`;
          try {
            outScript = Script.from_asm_string(asm);
          } catch (e) {
            throw Error('Invalid data');
          }
        } else {
          throw Error('Invalid request');
        }


        //feeSats = Math.max( feeSats,tx.to_bytes().byteLength);

        const estimatedOutputSize = tx.to_bytes().byteLength + P2PKH_OUTPUT_SIZE + P2PKH_OUTPUT_SIZE; // 估算输出的大小
        const estimatedInputSize = inputs.length * P2PKH_INPUT_SIZE; // 估算每个输入的大小
        const estimatedTotalSize = estimatedOutputSize + estimatedInputSize;
        feeSats = Math.max(feeSats, Math.ceil(estimatedTotalSize * FEE_PER_BYTE + 1));

        // TODO: In event where provider method calls this and happens to have multiple outputs that equal all sats available in users wallet, this tx will likely fail due to no fee to miner. Considering an edge case for now.
        const outSats = sendAll && request.length === 1 ? totalSats - feeSats : req.satoshis;
        //console.log('totalSats:', totalSats);
        //console.log('outSats:', outSats);
        //console.log('feeSats:', feeSats);
        if (outSats < DUST) {
          return { error: "output is below DUST" };
        }
        tx.add_output(new TxOut(BigInt(Math.ceil(outSats)), outScript));
      });

      let change = 0;
      if (!sendAll && totalInputSats - satsOut - feeSats >= DUST) {
        change = totalInputSats - satsOut - feeSats;
        tx.add_output(new TxOut(BigInt(Math.ceil(change)), P2PKHAddress.from_string(fromAddress).get_locking_script()));
      }

      // build txins from our inputs
      let idx = 0;
      for (let u of inputs || []) {
        // @ts-ignore
        const inTx = new TxIn(Buffer.from(u.txid, 'hex'), u.vout, Script.from_hex(''));

        inTx.set_satoshis(BigInt(Math.ceil(u.satoshis)));
        tx.add_input(inTx);

        const sig = tx.sign(paymentPk, SigHash.InputOutputs, idx, Script.from_hex(u.script), BigInt(Math.ceil(u.satoshis)));//签名

        if (accountType === 1) {
          inTx.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${paymentPk.to_public_key().to_hex()}`));
        } else {
          const pubkey = getTaprootTweakPublicKey(keys.walletWif);
          inTx.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${pubkey}`));
        }
        tx.set_input(idx, inTx);
        idx++;
      }
      // Fee checker
      const finalSatsIn = tx.satoshis_in() ?? 0n;
      const finalSatsOut = tx.satoshis_out() ?? 0n;
      if (finalSatsIn - finalSatsOut > MAX_FEE_PER_TX) return { error: 'fee-too-high' };

      // Size checker
      const bytes = tx.to_bytes().byteLength;
      if (bytes > MAX_BYTES_PER_TX) return { error: 'tx-size-too-large' };

      const rawtx = tx.to_hex();
      console.log('rawtx:', rawtx);
      return {
        rawtx: rawtx,
        amount: satsOut,
        change: change,
        fee: feeSats,
      };
    } catch (error: any) {
      //console.log(error);
      return { error: error.message ?? 'unknown' };
    }
  };

  const sendtbc = async (
    rawtx: string,
  ): Promise<{ txid?: string; error?: string }> => {
    try {
      // const isAuthenticated = await verifyPassword(password);
      // if (!isAuthenticated) {
      //   return { error: 'invalid-password' };
      // }
      ////console.log(rawtx);
      const { txid } = await broadcastWithTBChainPyPool(rawtx);
      ////console.log('txid:', txid);

      return { txid };
    } catch (error: any) {
      //console.log(error);
      return { error: error.message ?? 'unknown' };
    } finally {
      setIsProcessing(false);
    }
  };

  const sendTbc = async (
    request: Web3SendTransactionRequest,
    password: string,
    noApprovalLimit?: number,
  ): Promise<SendTbcResponse> => {
    try {
      setIsProcessing(true);
      await init();
      const requestSats = request.reduce((a: number, item: { satoshis: number }) => a + item.satoshis, 0);
      const tbcSendAmount = requestSats / TBC_DECIMAL_CONVERSION;

      if (tbcSendAmount > Number(noApprovalLimit)) {
        const isAuthenticated = await verifyPassword(password);
        if (!isAuthenticated) {
          return { error: 'invalid-password' };
        }
      }
      
      let feeSats = 80;//这是每笔的最少手续费
      const isBelowNoApprovalLimit = Number(tbcSendAmount) <= Number(noApprovalLimit);
      const keys = await retrieveKeys(password, isBelowNoApprovalLimit);
      if (!keys?.walletWif || !keys.walletPubKey) throw Error('Undefined key');

      let paymentPk: PrivateKey;
      if (accountType === 1) {
        paymentPk = PrivateKey.from_wif(keys.walletWif);
      } else {
        paymentPk = PrivateKey.from_hex(getTaprootTweakPrivateKey(keys.walletWif));
      }

      const fromAddress = tbcAddress;
      const amount = request.reduce((a, r) => a + r.satoshis, 0);

      // Format in and outs
      const fundingUtxos = await getUtxos(fromAddress, false);

      if (!fundingUtxos) throw Error('No Utxos!');
      const totalSats = fundingUtxos.reduce((a: number, item: UTXO) => a + item.satoshis, 0);
      if (totalSats < amount) {
        return { error: 'insufficient-funds' };
      }

      const sendAll = totalSats === amount;
      const satsOut = sendAll ? totalSats - feeSats : amount;
      const inputs = getInputs(fundingUtxos, satsOut + feeSats, sendAll);
      if ('error' in inputs) {
        return { error: inputs.error };
      }

      const totalInputSats = inputs.reduce((a, item) => a + item.satoshis, 0);

      const tx = new Transaction(10, 0)

      request.forEach((req) => {
        let outScript: Script;
        if (req.address) {
          if (req.inscription) {
            const { base64Data, mimeType, map } = req.inscription;
            const formattedBase64 = removeBase64Prefix(base64Data);
            outScript = P2PKHAddress.from_string(req.address).get_locking_script();
            //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          } else {
            outScript = P2PKHAddress.from_string(req.address).get_locking_script();
            //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          }
        } else if (req.script) {
          outScript = Script.from_hex(req.script);
          //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          // const reqBytes = JSON.stringify(req).length; // 这里是修改fee的逻辑
          // feeSats = Math.max( feeSats,Math.ceil(reqBytes * FEE_PER_BYTE));
        } else if ((req.data || []).length > 0) {
          let asm = `OP_0 OP_RETURN ${req.data?.join(' ')}`;
          try {
            outScript = Script.from_asm_string(asm);
          } catch (e) {
            throw Error('Invalid data');
          }
        } else {
          throw Error('Invalid request');
        }


        //feeSats = Math.max( feeSats,tx.to_bytes().byteLength);

        const estimatedOutputSize = tx.to_bytes().byteLength + P2PKH_OUTPUT_SIZE + P2PKH_OUTPUT_SIZE; // 估算输出的大小
        const estimatedInputSize = inputs.length * P2PKH_INPUT_SIZE; // 估算每个输入的大小
        const estimatedTotalSize = estimatedOutputSize + estimatedInputSize;
        feeSats = Math.max(feeSats, Math.ceil(estimatedTotalSize * FEE_PER_BYTE + 1));

        // TODO: In event where provider method calls this and happens to have multiple outputs that equal all sats available in users wallet, this tx will likely fail due to no fee to miner. Considering an edge case for now.
        const outSats = sendAll && request.length === 1 ? totalSats - feeSats : req.satoshis;
        //console.log('totalSats:', totalSats);
        //console.log('outSats:', outSats);
        //console.log('feeSats:', feeSats);
        if (outSats < DUST) {
          return { error: "output is below DUST" };
        }
        tx.add_output(new TxOut(BigInt(Math.ceil(outSats)), outScript));
      });

      let change = 0;
      if (!sendAll && totalInputSats - satsOut - feeSats >= DUST) {
        change = totalInputSats - satsOut - feeSats;
        tx.add_output(new TxOut(BigInt(Math.ceil(change)), P2PKHAddress.from_string(fromAddress).get_locking_script()));
      }

      // build txins from our inputs
      let idx = 0;
      for (let u of inputs || []) {
        // @ts-ignore
        const inTx = new TxIn(Buffer.from(u.txid, 'hex'), u.vout, Script.from_hex(''));

        inTx.set_satoshis(BigInt(Math.ceil(u.satoshis)));
        tx.add_input(inTx);
        
        const sig = tx.sign(paymentPk, SigHash.InputOutputs, idx, Script.from_hex(u.script), BigInt(Math.ceil(u.satoshis)));//签名
        if (accountType === 1) {
          inTx.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${paymentPk.to_public_key().to_hex()}`));
        } else {
          const pubkey = getTaprootTweakPublicKey(keys.walletWif);
          
          inTx.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${pubkey}`));
        }
        tx.set_input(idx, inTx);
        idx++;
      }
      // Fee checker
      const finalSatsIn = tx.satoshis_in() ?? 0n;
      const finalSatsOut = tx.satoshis_out() ?? 0n;
      if (finalSatsIn - finalSatsOut > MAX_FEE_PER_TX) return { error: 'fee-too-high' };

      // Size checker
      const bytes = tx.to_bytes().byteLength;
      if (bytes > MAX_BYTES_PER_TX) return { error: 'tx-size-too-large' };

      const rawtx = tx.to_hex();
      let { txid } = await broadcastWithTBChainPyPool(rawtx);//这里是调用广播交易的api
      // //console.log('txid:', txid);
      if (txid) {
        if (isBelowNoApprovalLimit) {
          storage.get(['noApprovalLimit'], ({ noApprovalLimit }) => {
            storage.set({
              noApprovalLimit: noApprovalLimit
                ? Number((noApprovalLimit - amount / TBC_DECIMAL_CONVERSION).toFixed(8))
                : 0,
            });
          });
        }
      }
      return { txid };
    } catch (error: any) {
      //console.log(error);
      return { error: error.message ?? 'unknown' };
    } finally {
      setIsProcessing(false);
    }
  };

  const mergeTbc = async (
    request: Web3SendTransactionRequest,
    password: string,
    noApprovalLimit?: number,
  ): Promise<SendTbcResponse> => {
    try {
      setIsProcessing(true);
      await init();
      const requestSats = request.reduce((a: number, item: { satoshis: number }) => a + item.satoshis, 0);
      const tbcSendAmount = requestSats / TBC_DECIMAL_CONVERSION;

      if (tbcSendAmount > Number(noApprovalLimit)) {
        const isAuthenticated = await verifyPassword(password);
        if (!isAuthenticated) {
          return { error: 'invalid-password' };
        }
      }

      let feeSats = 80;//这是每笔的最少手续费
      const isBelowNoApprovalLimit = Number(tbcSendAmount) <= Number(noApprovalLimit);
      const keys = await retrieveKeys(password, isBelowNoApprovalLimit);
      if (!keys?.walletWif || !keys.walletPubKey) throw Error('Undefined key');
      const paymentPk = PrivateKey.from_wif(keys.walletWif);
      const fromAddress = tbcAddress;
      const amount = request.reduce((a, r) => a + r.satoshis, 0);

      // Format in and outs
      const fundingUtxos = await getUtxos(fromAddress, false);

      if (!fundingUtxos) throw Error('No Utxos!');
      const totalSats = fundingUtxos.reduce((a: number, item: UTXO) => a + item.satoshis, 0);
      if (totalSats < amount) {
        return { error: 'insufficient-funds' };
      }

      const sendAll = totalSats === amount;
      const satsOut = sendAll ? totalSats - feeSats : amount;
      const inputs = getInputs_merge(fundingUtxos, satsOut + feeSats, sendAll);
      if ('error' in inputs) {
        return { error: inputs.error };
      }

      const totalInputSats = inputs.reduce((a, item) => a + item.satoshis, 0);

      const tx = new Transaction(10, 0)

      request.forEach((req) => {
        let outScript: Script;
        if (req.address) {
          if (req.inscription) {
            const { base64Data, mimeType, map } = req.inscription;
            const formattedBase64 = removeBase64Prefix(base64Data);
            outScript = P2PKHAddress.from_string(req.address).get_locking_script();
            //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          } else {
            outScript = P2PKHAddress.from_string(req.address).get_locking_script();
            //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          }
        } else if (req.script) {
          outScript = Script.from_hex(req.script);
          //feeSats += Math.ceil(outScript.to_bytes().byteLength * FEE_PER_BYTE);
          // const reqBytes = JSON.stringify(req).length; // 这里是修改fee的逻辑
          // feeSats = Math.max( feeSats,Math.ceil(reqBytes * FEE_PER_BYTE));
        } else if ((req.data || []).length > 0) {
          let asm = `OP_0 OP_RETURN ${req.data?.join(' ')}`;
          try {
            outScript = Script.from_asm_string(asm);
          } catch (e) {
            throw Error('Invalid data');
          }
        } else {
          throw Error('Invalid request');
        }



        //feeSats = Math.max( feeSats,tx.to_bytes().byteLength);

        const estimatedOutputSize = tx.to_bytes().byteLength + P2PKH_OUTPUT_SIZE + P2PKH_OUTPUT_SIZE; // 估算输出的大小

        const estimatedInputSize = inputs.length * P2PKH_INPUT_SIZE; // 估算每个输入的大小

        const estimatedTotalSize = estimatedOutputSize + estimatedInputSize;

        feeSats = Math.max(feeSats, Math.ceil(estimatedTotalSize * FEE_PER_BYTE + 1));


        // TODO: In event where provider method calls this and happens to have multiple outputs that equal all sats available in users wallet, this tx will likely fail due to no fee to miner. Considering an edge case for now.
        const outSats = sendAll && request.length === 1 ? totalSats - feeSats : req.satoshis;
        //console.log('totalSats:', totalSats);
        //console.log('outSats:', outSats);
        //console.log('feeSats:', feeSats);

        if (outSats < DUST) {
          return { error: "output is below DUST" };
        }

        tx.add_output(new TxOut(BigInt(Math.ceil(outSats)), outScript));

      });

      let change = 0;
      if (!sendAll && totalInputSats - satsOut - feeSats >= DUST) {
        change = totalInputSats - satsOut - feeSats;
        tx.add_output(new TxOut(BigInt(Math.ceil(change)), P2PKHAddress.from_string(fromAddress).get_locking_script()));
      }

      // build txins from our inputs
      let idx = 0;

      for (let u of inputs || []) {
        // @ts-ignore
        const inTx = new TxIn(Buffer.from(u.txid, 'hex'), u.vout, Script.from_hex(''));

        inTx.set_satoshis(BigInt(Math.ceil(u.satoshis)));
        tx.add_input(inTx);

        const sig = tx.sign(paymentPk, SigHash.InputOutputs, idx, Script.from_hex(u.script), BigInt(Math.ceil(u.satoshis)));//签名

        inTx.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${paymentPk.to_public_key().to_hex()}`));
        tx.set_input(idx, inTx);
        idx++;
      }

      // Fee checker
      const finalSatsIn = tx.satoshis_in() ?? 0n;
      const finalSatsOut = tx.satoshis_out() ?? 0n;

      if (finalSatsIn - finalSatsOut > MAX_FEE_PER_TX) return { error: 'fee-too-high' };

      // Size checker
      const bytes = tx.to_bytes().byteLength;

      if (bytes > MAX_BYTES_PER_TX) return { error: 'tx-size-too-large' };

      const rawtx = tx.to_hex();

      let { txid } = await broadcastWithTBChainPyPool(rawtx);//这里是调用广播交易的api
      // //console.log('txid:', txid);
      if (txid) {
        if (isBelowNoApprovalLimit) {
          storage.get(['noApprovalLimit'], ({ noApprovalLimit }) => {
            storage.set({
              noApprovalLimit: noApprovalLimit
                ? Number((noApprovalLimit - amount / TBC_DECIMAL_CONVERSION).toFixed(8))
                : 0,
            });
          });
        }
      }
      return { txid };
    } catch (error: any) {
      //console.log(error);
      return { error: error.message ?? 'unknown' };
    } finally {
      setIsProcessing(false);
    }
  };

  const signMessage = async (//这里是使用用户私钥来签名
    messageToSign: Web3SignMessageRequest,
    password: string,
  ): Promise<SignMessageResponse | undefined> => {
    const { message, encoding } = messageToSign;
    const isAuthenticated = await verifyPassword(password);//验证给定的消息和签名是否匹配指定的公钥。
    if (!isAuthenticated) {
      return { error: 'invalid-password' };
    }
    try {
      const keys = (await retrieveKeys(password)) as Keys;
      const derivationTag = messageToSign.tag ?? { label: 'turing', id: 'tbc', domain: '', meta: {} };
      const privateKey = getPrivateKeyFromTag(derivationTag, keys);
      const tbcPrivateKey = tbc.PrivateKey.fromString(privateKey.to_hex());

      if (!privateKey.to_wif()) {
        return { error: 'key-type' };
      }

      const publicKey = privateKey.to_public_key();
      const address = publicKey.to_address().set_chain_params(getChainParams(network)).to_string();

      const msgBuf = Buffer.from(message, encoding);
      const signature = tbc.Message.sign(msgBuf, tbcPrivateKey);
      return {
        address,
        pubKey: publicKey.to_hex(),
        message: message,
        sig: signature,
        derivationTag,
      };
    } catch (error) {
      //console.log(error);
    }
  };

  const verifyMessage = (
    message: string,
    signatureHex: string,
    publicKeyHex: string,
    encoding: 'utf8' | 'hex' | 'base64' = 'utf8',
  ) => {
    try {
      const msgBuf = Buffer.from(message, encoding);
      const publicKey = PublicKey.from_hex(publicKeyHex);
      // @ts-ignore
      const signature = Signature.from_compact_bytes(Buffer.from(signatureHex, 'hex'));
      const address = publicKey.to_address().set_chain_params(getChainParams(network));
      // @ts-ignore
      return address.verify_bitcoin_message(msgBuf, signature);
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const updateTbcBalance = async (isDelay: boolean = false) => {
    try {
      const cacheKey = `tbcBalance`;

      // 从缓存中获取余额并更新 UI
      storage.get(cacheKey, (cachedData: any) => {
        if (cachedData && cachedData[cacheKey]) {
          setTbcBalance(cachedData[cacheKey]);
        }
      });

      // 如果 isDelay 为 true，先等待 3 秒再拉取最新余额
      if (isDelay) {
        await sleep(3000);
        let retryCount = 0;
        const maxRetries = 2; // 最多重试 3 次（每次 500ms）

        // 首次请求后的 total
        let total = await getTbcBalance(tbcAddress);
        let cachedbalance = 0;
        storage.get(cacheKey, (cachedData: any) => {
          cachedbalance = cachedData[cacheKey] ? cachedData[cacheKey] : 0;
        });

        const retryUpdateBalance = async () => {
          if (retryCount < maxRetries) {
            if (total !== cachedbalance) {
              // total 与缓存不一致，更新缓存和 UI
              setTbcBalance(total ?? 0);
              storage.set({ [cacheKey]: String(total) }); // 更新缓存
            }
            if (total === cachedbalance) {
              // 如果 total 与缓存一致，500ms 后重试
              await sleep(3000);
              total = await getTbcBalance(tbcAddress); // 重新拉取 total
              retryCount++;
              retryUpdateBalance();
            }
          }
        };
        retryUpdateBalance();
      } else {
        // 如果 isDelay 为 false，则直接更新缓存和 UI
        const total = await getTbcBalance(tbcAddress);
        storage.get(cacheKey, (cachedData: any) => {
          const cachedbalance = cacheKey ? cachedData[cacheKey] : 0;
          if (total !== cachedbalance) {
            setTbcBalance(total ?? 0);
            storage.set({ [cacheKey]: String(total) });
          }
        });
      }
    } catch (error) {
      //console.error('Error updating balance:', error);
    }
  };



  const updateTbcUtxos = async (pullFresh: boolean) => {
    try {
      getUtxos(tbcAddress, pullFresh);
    } catch (error) {
      //console.error('Error updating balance:', error);
    }
  };
  const rate = async () => {
    try {
      storage.get(['exchangeRateCache'], async ({ exchangeRateCache }) => {
        if (exchangeRateCache?.rate && exchangeRateCache?.changePercent) {
          setExchangeRate(Number(exchangeRateCache.rate.toFixed(3)));
          setChangePercent(exchangeRateCache.changePercent);
        }
      });
      const { rate, changePercent } = await getExchangeRate();
      if (!rate) return;
      if (!changePercent) return;
      setExchangeRate(Number(rate.toFixed(3)));
      setChangePercent(changePercent);
      const currentTime = Date.now();
      storage.set({ exchangeRateCache: { rate, changePercent, timestamp: currentTime } });
    } catch (error) {

    }
  };
  //
  const fundRawTx = async (rawtx: string, password: string): Promise<FundRawTxResponse> => {
    const isAuthenticated = await verifyPassword(password);
    if (!isAuthenticated) {
      return { error: 'invalid-password' };
    }

    const keys = await retrieveKeys(password);
    if (!keys.walletWif) throw new Error('Missing keys');
    const paymentPk = PrivateKey.from_wif(keys.walletWif);

    let satsIn = 0;
    let satsOut = 0;
    const tx = Transaction.from_hex(rawtx);
    let inputCount = tx.get_ninputs();
    for (let i = 0; i < inputCount; i++) {
      const txIn = tx.get_input(i);
      const txOut = await getTxOut(txIn!.get_prev_tx_id_hex(), txIn!.get_vout());
      satsIn += Number(txOut!.get_satoshis());
    }
    for (let i = 0; i < tx.get_noutputs(); i++) {
      satsOut += Number(tx.get_output(i)!.get_satoshis()!);
    }
    let size = rawtx.length / 2 + P2PKH_OUTPUT_SIZE;
    let fee = Math.ceil(size * FEE_PER_BYTE);
    const fundingUtxos = await getUtxos(tbcAddress, false);
    while (satsIn < satsOut + fee) {
      const utxo = fundingUtxos.pop();
      if (!utxo) throw Error('Insufficient funds');
      // @ts-ignore
      const txIn = new TxIn(Buffer.from(utxo.txid, 'hex'), utxo.vout, Script.from_hex(''));
      tx.add_input(txIn);
      satsIn += Number(utxo.satoshis);
      size += P2PKH_INPUT_SIZE;
      fee = Math.ceil(size * FEE_PER_BYTE);
      const sig = tx.sign(paymentPk, SigHash.Input, inputCount, Script.from_hex(utxo.script), BigInt(Math.ceil(utxo.satoshis)));
      txIn.set_unlocking_script(Script.from_asm_string(`${sig.to_hex()} ${paymentPk.to_public_key().to_hex()}`));
      tx.set_input(inputCount++, txIn);
    }
    tx.add_output(new TxOut(BigInt(Math.ceil(satsIn - satsOut - fee)), P2PKHAddress.from_string(tbcAddress).get_locking_script()));
    return { rawtx: tx.to_hex() };
  };


  useEffect(() => {
    if (!tbcAddress) return;
    updateTbcBalance();
    // updateTbcUtxos(true);
    rate();
  }, [tbcAddress]);

  return {
    sendTbcToMulti,
    multiSend,
    tbcBalance,
    tbcAddress,
    tbcPubKey,
    identityAddress,
    identityPubKey,
    isProcessing,
    assembleTbc,
    sendtbc,
    sendTbc,
    mergeTbc,
    setIsProcessing,
    updateTbcBalance,
    updateTbcUtxos,
    exchangeRate,
    changePercent,
    signMessage,
    verifyMessage,
    fundRawTx,
    retrieveKeys,
    getChainParams,
    signMultiTbcTransaction,
    signMultiFTTransaction,
    finishMultiTransaction,
  };
};
