//这里是发送币所需的方法，调用的各种api
import axios from 'axios';
import init, { ChainParams, P2PKHAddress, TxOut } from 'bsv-wasm-web';
import { GP_BASE_URL, GP_TESTNET_BASE_URL, JUNGLE_BUS_URL, PAGE_SIZE } from '../utils/constants';
import { chunkedStringArray } from '../utils/format';
import {  Keys } from '../utils/keys';
import { NetWork } from '../utils/network';
import { isTBC20v2 } from '../utils/ordi';
import { useAccounts } from './useAccounts';
import { getCurrentUtcTimestamp } from '../utils/tools';
import { TBC20Txo, NFTResponse, NFTTxo, CollectionResponse, FTResponse } from './ordTypes';
import { useNetwork } from './useNetwork';
import * as tbc from 'tbc-lib-js'


type TBChainPyPoolErrorMessage = {
  message: string | null;
};

export type TBChainPyPoolBroadcastResponse = {
  txid?: string;
  message?: string;
};

export type Token = {
  txid: string;
  vout: number;
  height: number;
  idx: number;
  tick: string;
  id: string;
  sym: string;
  icon: string;
  max: string;
  lim: string;
  dec: number;
  amt: string;
  supply: string;
  status: number;
  available: string;
  pctMinted: number;
  accounts: number;
  pending: number;
  included: boolean;
  fundAddress: string;
  fundTotal: number;
  fundUsed: number;
  fundBalance: number;
};

export type MarketResponse = {
  txid: string;
  vout: number;
  outpoint: string;
  owner: string;
  script: string;
  spend: string;
  spendHeight: number;
  spendIdx: number;
  height: number;
  idx: number;
  op: string;
  tick: string;
  id: string;
  sym: string;
  dec: number;
  icon: string;
  amt: string;
  status: number;
  reason: string;
  listing: boolean;
  price: number;
  pricePer: number;
  payout: string;
  sale: boolean;
};

export const useTBChainPyPool = () => {
  const { network, isAddressOnRightNetwork } = useNetwork();
  const {getStorage,updateStorage}=useAccounts();
  const getOrdinalsBaseUrl = () => {//这里是编码后的交易数据发送到TBChainPyPool提供的API端点
    return network === NetWork.Mainnet ? GP_BASE_URL : GP_TESTNET_BASE_URL;
  };

  const getChainParams = (network: NetWork): ChainParams => {
    return network === NetWork.Mainnet ? ChainParams.mainnet() : ChainParams.testnet();
  };
  const getNftUtxos = async (collectionId: string, page: number): Promise<NFTResponse> => {
    try {
      // if (!isAddressOnRightNetwork(ordAddress)) return [];
      // const { data } = await axios.get<OrdinalTxo[]>(
      //   `${getOrdinalsBaseUrl()}/api/txos/address/${ordAddress}/unspent?limit=1500&offset=0`,
      // );
      // return data;
      if (!collectionId || collectionId.trim() === '') {
        console.error('Invalid collectionId:', collectionId);
        return { nftCount: -1, nftList: [] };; // 返回空数组以避免发送无效请求
      }
      //console.log(collectionId);
      const { data } = await axios.get(
        `https://turingwallet.xyz/v1/tbc/main/nft/collection/id/${collectionId}/page/${page}/size/${PAGE_SIZE}`,
      );
      return { nftCount: data.nftTotalCount, nftList: data.nftList };
    } catch (error) {
      //console.log(error);
      return { nftCount: -1, nftList: [] };
    }
  };
  //用来获取一个地址的未花费交易输出（unspent transaction outputs, UTXOs）列表。该函数接受一个 ordAddress 参数（表示需要查询UTXOs的地址），
  //并且返回一个名为 OrdinalResponse 类型的 Promise 对象
  const getOrdUtxos = async (address: string, page: number): Promise<NFTResponse> => {
    try {
      if (!address || address.trim() === '') {
        console.error('Invalid ordAddress:', address);
        return { nftCount: -1, nftList: [] };
      }
      const { data } = await axios.get(
        `https://turingwallet.xyz/v1/tbc/main/nft/address/${address}/page/${page}/size/${PAGE_SIZE}`,
      );
      return { nftCount: data.nftTotalCount, nftList: data.nftList };
    } catch (error) {
      //console.log(error);
      return { nftCount: -1, nftList: [] };
    }
  };
  const getCollectionUtxos = async (page: number, Address: string): Promise<CollectionResponse> => {
    try {

      if (!Address || Address.trim() === '') {
        console.error('Invalid Address:', Address);
        return { collectionCount: -1, collectionList: [] };
      }
      const { data } = await axios.get(
        `https://turingwallet.xyz/v1/tbc/main/nft/collection/address/${Address}/page/${page}/size/${PAGE_SIZE}`,
      );
      return data;
    } catch (error) {
      //console.log(error);
      return { collectionCount: -1, collectionList: [] };
    }
  };
  const getFtUtxos = async (address: string, ftAddress: string[]): Promise<FTResponse> => {
    try {

      if (!address || !ftAddress) {
        console.error('Invalid address:', address);
        return { ftCount: -1, ftList: [] };
      }
      const { data } = await axios.get(
        `https://turingwallet.xyz/v1/tbc/main/ft/utxo/address/${address}/contract/${ftAddress}`
      );
      return data;
    } catch (error) {
      //console.log(error);
      return { ftCount: -1, ftList: [] };
    }
  };
  const getFtBalance = async (address: string, ftAddresses: string[]) => {
    try {
      if (!address || !ftAddresses || ftAddresses.length === 0) {
        return null;
      }

      // 发送请求，获取多个 ftAddresses 的余额信息
      const { data } = await axios.post(
        `https://turingwallet.xyz/v1/tbc/main/ft/balance/address/${address}/contract/ids`,
        { ftContractId: ftAddresses }
      );
      // 处理返回的数据并将其转换为字典
      const balanceDict: { [key: string]: number } = {};

      // data 是一个对象数组，遍历它并将 ftContractId 映射到 ftBalance
      if (data) {
        data.forEach((item: { ftContractId: string, ftBalance: number }) => {
          // 确保 ftAddresses 中有对应的 ftContractId
          const index = ftAddresses.indexOf(item.ftContractId);
          if (index !== -1) {
            balanceDict[ftAddresses[index]] = item.ftBalance;
          }
        });
      }
      return balanceDict; // 返回字典形式的结果
    } catch (error) {
      //console.log(error);
      return null; // 请求失败时返回空字典
    }
  };

  // const sendAddFT = async (address:string,ftaddress:string)=> {
  //   try {

  //      if (!address || !ftaddress) {
  //     console.error('Invalid ftaddress:');
  //     return; // 返回空数组以避免发送无效请求
  //   }
  //     const { data } = await axios.post(
  //       `https://turingwallet.xyz/v1/tbc/main/nft/collection/script/hash/${address}`,{ftaddress:ftaddress}
  //     );
  //     return data;
  //   } catch (error) {
  //     //console.log(error);
  //     return ;
  //   }
  // };

  const broadcastWithTBChainPyPool = async (txhex: string): Promise<TBChainPyPoolBroadcastResponse> => {
    try {
      const res = await axios.post<{ result: string, error: string }>(`https://turingwallet.xyz/v1/tbc/main/broadcast/tx/raw`, {
        "txHex": txhex,
      });
      // const res = await axios.post<{ result: string, error: string }>(`https://turingwallet.xyz/v1/tbc/main/broadcast/tx/raw`, {
      //   "txHex": txhex,
      // });
      if (res.status === 200 && res.data.error === null) {
        await updateStoredPaymentUtxos(txhex)
        return { txid: res.data.result };
      } else {
        return { message: res.data.error ?? 'Unknown error while broadcasting tx' };
      }
    } catch (error: any) {
      //console.log(error);
      return { message: JSON.stringify(error.response.data ?? 'Unknown error while broadcasting tx') };
    }
  };

  const broadcastNFT = async (txhex: string): Promise<TBChainPyPoolBroadcastResponse> => {
    try {

      const res = await axios.post<{ result: string, error: string }>(`https://turingwallet.xyz/v1/tbc/main/nft/broadcast`, {
        "txHex": txhex,
      });
      if (res.status === 200 && res.data.error === null) {
        if (await updateStoredPaymentUtxos(txhex)) {
          //console.log("success");
        } else {
          //console.log("fail");
        }
        return { txid: res.data.result };
      } else {
        return { message: res.data.error ?? 'Unknown error while broadcasting tx' };
      }
    } catch (error: any) {
      //console.log(error);
      return { message: JSON.stringify(error.response.data ?? 'Unknown error while broadcasting tx') };
    }
  };

  const submitTx = async (txid: string) => {//其目的是通过网络接口提交一个交易ID（txid），并检查提交的状态
    try {
      let res = await axios.post(`${getOrdinalsBaseUrl()}/api/tx/${txid}/submit`);

      if (res.status !== 0) {
        console.error('submitTx failed: ', txid);
      }
    } catch (error) {
      console.error('submitTx failed: ', txid, error);
    }
  };

  const getNFTTxoByAddress = async (nftContractAddress: string): Promise<NFTTxo | null> => {//是通过网络请求获取特定的未花费交易输出（UTXO）的数据
    try {
      const { data } = await axios.post(`https://turingwallet.xyz/v1/tbc/main/nft/infos/contract_ids`, {
        if_icon_needed: false,
        nft_contract_list:
          [
            nftContractAddress
          ]
      });
      const nftInfo: NFTTxo = {
        ...data.nftInfoList[0],
        vout: 0,
        nftContractVout: 0,
      };
      return nftInfo;
    } catch (error) {
      return null;
    }
  };

  const getUtxoByOutpoint = async (outpoint: string): Promise<NFTTxo> => {//是通过网络请求获取特定的未花费交易输出（UTXO）的数据
    try {
      const { data } = await axios.get(`${getOrdinalsBaseUrl()}/api/txos/${outpoint}?script=true`);
      const ordUtxo: NFTTxo = data;
      if (!ordUtxo.script) throw Error('No script when fetching by outpoint');
      ordUtxo.script = Buffer.from(ordUtxo.script, 'base64').toString('hex');
      return ordUtxo;
    } catch (e) {
      throw new Error(JSON.stringify(e));
    }
  };

  const getMarketData = async (outpoint: string) => {//目的是从一个API端点获取与某个特定点相关的市场数据。
    try {
      const res = await axios.get(`${getOrdinalsBaseUrl()}/api/inscriptions/${outpoint}?script=true`);
      const data = res.data as NFTTxo;
      if (!data?.script || !data.origin?.outpoint.toString()) throw new Error('Could not get listing script');
      return { script: data.script, origin: data.origin.outpoint.toString() };
    } catch (error) {
      throw new Error(`Error getting market data: ${JSON.stringify(error)}`);
    }
  };

  const getTbc20Balances = async (ordAddress: string) => {
    if (!isAddressOnRightNetwork(ordAddress)) return [];
    // const res = await axios.get(`${getOrdinalsBaseUrl()}/api/tbc20/${ordAddress}/balance`);
    // const tbc20List: Array<TBC20> = res.data.map(//主要功能是获取一个给定比特币SV地址（ordAddress）在一个或多个TBC20资产中的余额。
    //   (b: {
    //     all: {
    //       confirmed: string;
    //       pending: string;
    //     };
    //     listed: {
    //       confirmed: string;
    //       pending: string;
    //     };
    //     tick?: string;
    //     sym?: string;
    //     id?: string;
    //     icon?: string;
    //     dec: number;
    //   }) => {
    //     const id = (b.tick || b.id) as string;
    //     return {
    //       id: id,
    //       tick: b.tick,
    //       sym: b.sym || null,
    //       icon: b.icon || null,
    //       dec: b.dec,
    //       all: {
    //         confirmed: BigInt(b.all.confirmed),
    //         pending: BigInt(b.all.pending),
    //       },
    //       listed: {
    //         confirmed: BigInt(b.all.confirmed),
    //         pending: BigInt(b.all.pending),
    //       },
    //     };
    //   },
    // );

    // return tbc20List;
    return [];
  };

  const getTBC20Utxos = async (contractId: string, address: string): Promise<TBC20Txo[] | undefined> => {//用来对特定的加密货币地址和代币标识符进行查询，以便获取与之关联的UTXO（Unspent Transaction Outputs，未花费交易输出）数组。
    try {
      if (!address) {
        return [];
      }

      // const url = isTBC20v2(tick)
      //   ? `${getOrdinalsBaseUrl()}/api/tbc20/${address}/id/${tick}`
      //   : `${getOrdinalsBaseUrl()}/api/tbc20/${address}/tick/${tick}`;
      const url = `https://turingwallet.xyz/v1/tbc/main/ft/utxo/address/${address}/contract/${contractId}`;
      const r = await axios.get(url);

      // return (r.data as TBC20Txo[]).filter((utxo) => utxo.status === 1 && !utxo.listing);
      return (r.data.ftUtxoList as TBC20Txo[]);
    } catch (error) {
      console.error('getTBC20Utxos', error);
      return [];
    }
  };

  const getFtInputs = (ftUtxos: TBC20Txo[], ftAmount: bigint, size: number) => {
    let inputs: TBC20Txo[] = [];
    const sortedUtxos = ftUtxos.sort((a, b) => b.ftBalance - a.ftBalance);

    inputs = sortedUtxos.length < size ? sortedUtxos : sortedUtxos.slice(0, size);

    // 计算 inputs 中的 UTXOs 的 balance 之和
    const totalBalance = inputs.reduce((sum, utxo) => sum + BigInt(utxo.ftBalance), 0n);

    // 检查 totalBalance 是否小于 ftAmount
    if (totalBalance < ftAmount) {
      inputs = [];
    }

    return inputs;
  };
  const getTbc20Details = async (tick: string) => {//根据传入的代币标识符（tick）异步获取TBC20代币的详细信息
    try {
      const url = isTBC20v2(tick)
        ? `${getOrdinalsBaseUrl()}/api/tbc20/id/${tick}`
        : `${getOrdinalsBaseUrl()}/api/tbc20/tick/${tick}`;

      const r = await axios.get<Token>(url);

      return r.data;
    } catch (error) {
      console.error('getTbc20Details', error);
    }
  };

  const getLockedUtxos = async (address: string) => {//此函数异步获取指定地址的锁定的UTXOs（未花费的交易输出）。它首先检查地址是否在正确的网络上，然后发送一个GET请求到指定的API端点，获取该地址的锁定UTXO列表。
    try {
      if (!isAddressOnRightNetwork(address)) return [];
      //TODO: use this instead of test endpoint - `${getOrdinalsBaseUrl()}/api/locks/address/${address}/unspent?limit=100&offset=0`
      const { data } = await axios.get(
        `${getOrdinalsBaseUrl()}/api/locks/address/${address}/unspent?limit=100&offset=0`,
      );
      const lockedUtxos: NFTTxo[] = data;
      return lockedUtxos;
    } catch (e) {
      throw new Error(JSON.stringify(e));
    }
  };

  const getSpentTxids = async (outpoints: string[]): Promise<Map<string, string>> => {//异步获取一组UTXOs是否已花费的信息，并返回一个包含UTXOs和其对应花费交易ID的映射对象。函数将输入的UTXOs数组按块分割，并对每一块进行查询
    try {
      const chunks = chunkedStringArray(outpoints, 50);
      let spentTxids = new Map<string, string>();
      for (const chunk of chunks) {
        try {
          //TODO: updata url to be dynamic for testnet
          const res = await axios.post(`${getOrdinalsBaseUrl()}/api/spends`, chunk);
          const txids = res.data as string[];
          txids.forEach((txid, i) => {
            spentTxids.set(chunk[i], txid);
          });
        } catch (error) { }
      }
      return spentTxids;
    } catch (error) {
      //console.log(error);
      return new Map();
    }
  };

  const getOrdContentByOriginOutpoint = async (originOutpoint: string) => {//此函数异步获取一个outpoint（交易输出点）所指向的ordinal内容。向相应的API端点发送GET请求，并以数组形式接收二进制响应数据，然后将其转换为Buffer。
    try {
      const res = await axios.get(`${getOrdinalsBaseUrl()}/content/${originOutpoint}?fuzzy=false`, {
        responseType: 'arraybuffer',
      });
      return Buffer.from(res.data);
    } catch (error) {
      //console.log(error);
    }
  };

  const setDerivationTags = async (identityAddress: string, keys: Keys) => {//此函数异步为一系列的ordinals设置衍生标签。它检索与给定地址相关联的UTXOs，解密获得的内容，并从中提取衍生标签和地址，最后存储这些信息。
    // const taggedOrds = await getOrdUtxos(identityAddress,0);
    // let tags: TaggedDerivationResponse[] = [];
    // for (const ord of taggedOrds) {
    //   try {
    //     if (!ord.origin?.outpoint || ord.origin.data?.insc?.file.type !== 'panda/tag') continue;
    //     const contentBuffer = await getOrdContentByOriginOutpoint(ord.origin.outpoint.toString());
    //     if (!contentBuffer || contentBuffer.length === 0) continue;

    //     const derivationTag = decryptUsingPrivKey(
    //       [Buffer.from(contentBuffer).toString('base64')],
    //       PrivateKey.from_wif(keys.identityWif),
    //     );

    //     const parsedTag: DerivationTag = JSON.parse(Buffer.from(derivationTag[0], 'base64').toString('utf8'));
    //     const taggedKeys = getTaggedDerivationKeys(parsedTag, keys.mnemonic);

    //     const taggedAddress = P2PKHAddress.from_string(taggedKeys.address)
    //       .set_chain_params(getChainParams(network))
    //       .to_string();

    //     tags.push({ tag: parsedTag, address: taggedAddress, pubKey: taggedKeys.pubKey.to_hex() });
    //   } catch (error) {
    //     //console.log(error);
    //   }
    // }

    // storage.set({ derivationTags: tags });
  };

  const getTxOut = async (txid: string, vout: number) => {//此函数异步获取一个交易的输出部分（特定的txid和vout），并返回一个TxOut对象。它发送GET请求获取交易输出数据，并将返回的数组形式二进制数据转换为TxOut。
    try {
      await init();
      const { data } = await axios.get(`${JUNGLE_BUS_URL}/v1/txo/get/${txid}_${vout}`, { responseType: 'arraybuffer' });
      return TxOut.from_hex(Buffer.from(data).toString('hex'));
    } catch (error) {
      //console.log(error);
    }
  };

  const updateStoredPaymentUtxos = async (rawtx: string) => {//这里是异步更新本地存储的支付UTXOs信息。它初始化存储系统，获得当前存储状态，并更新UTXOs的花费状态，以及新交易中获取到的UTXO。
    await init();
    const StorageData=await getStorage();
    const selectedAccount=StorageData.selectedAccount;
    const currAccount=StorageData.accounts[selectedAccount];
    const paymentUtxos=currAccount.paymentUtxos;
    const tbcAddress = currAccount.addresses.tbcAddress;

    const tbcTx = new tbc.Transaction(rawtx);
    let inputCount = tbcTx.inputs.length;
    let outputCount = tbcTx.outputs.length;
    const spends: string[] = [];
    for (let i = 0; i < inputCount; i++) {
      const txIn = tbcTx.inputs[i];
      spends.push(`${txIn!.prevTxId.toString('hex')}_${txIn!.outputIndex}`);
    }
    paymentUtxos.forEach((utxo) => {
      if (spends.includes(`${utxo.txid}_${utxo.vout}`)) {
        utxo.spent = true;
        utxo.spentUnixTime = getCurrentUtcTimestamp();
      }
    });

    const fundingScript = P2PKHAddress.from_string(tbcAddress!).get_locking_script().to_hex();
    const txid = tbcTx.id;

    for (let i = 0; i < outputCount; i++) {
      const txOut = tbcTx.outputs[i];
      const outScript = txOut?.script.toHex();
      if (outScript === fundingScript) {
        paymentUtxos.push({
          satoshis: Number(txOut!.satoshis),
          script: fundingScript,
          txid,
          vout: i,
          spent: false,
          spentUnixTime: 0,
        });
      }
    }
    const newCurrAccount={[tbcAddress]:{...currAccount,paymentUtxos}};
    await updateStorage({ accounts :{...StorageData.accounts,...newCurrAccount}});
    return paymentUtxos;
  };

  const getTokenPriceInSats = async (tokenIds: string[]) => {//此函数异步获取一组代币在市场上的最低价格（以sats为单位）。通过发送GET请求到市场API端点，获取每个代币的市场价格，并返回结果数组。
    let result: { id: string; satPrice: number }[] = [];
    for (const tokenId of tokenIds) {
      const { data } = await axios.get<MarketResponse[]>(
        `${getOrdinalsBaseUrl()}/api/tbc20/market?sort=price_per_token&dir=asc&limit=1&offset=0&${tokenId.length > 30 ? 'id' : 'tick'
        }=${tokenId}`,
      );
      if (data.length > 0) {
        result.push({ id: tokenId, satPrice: data[0].pricePer });
      }
    }
    return result;
  };

  return {
    getFtBalance,
    getFtUtxos,
    getOrdUtxos,
    getNftUtxos,
    getFtInputs,
    getCollectionUtxos,
    broadcastWithTBChainPyPool,
    broadcastNFT,
    getUtxoByOutpoint,
    getMarketData,
    getTbc20Balances,
    getTBC20Utxos,
    getLockedUtxos,
    getSpentTxids,
    submitTx,
    getOrdContentByOriginOutpoint,
    setDerivationTags,
    getTxOut,
    getTbc20Details,
    getTokenPriceInSats,
    getNFTTxoByAddress
  };
};
