import { validate } from 'bitcoin-address-validation';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import tbcCoin from '../../assets/TuringLiitleCoin.png';
import copyIcon from '../../assets/copy-green.svg';
import { BackButton } from '../../components/BackButton';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { PageLoader } from '../../components/PageLoader';
import { FormContainer, HeaderText, Text } from '../../components/Reusable';
import { Show } from '../../components/Show';
import { useBottomMenu } from '../../hooks/useBottomMenu';
import { useTbc, Web3SendTransactionRequest } from '../../hooks/useTbc';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useTheme } from '../../hooks/useTheme';
import { useWeb3Context } from '../../hooks/useWeb3Context';
import { ColorThemeProps } from '../../theme';
import { TBC_DECIMAL_CONVERSION } from '../../utils/constants';
import { truncate } from '../../utils/format';
import { sleep } from '../../utils/sleep';
import { storage } from '../../utils/storage';
import { useOrds } from '../../hooks/useOrds';
import { useTranslation } from 'react-i18next';

const RequestDetailsContainer = styled.div<ColorThemeProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: 10rem;
  overflow-y: auto;
  overflow-x: hidden;
  background: ${({ theme }) => theme.darkAccent + '80'};
  margin: 0.5rem;
`;

const LineItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.25rem;
  width: 90%;
  z-index: 100;
`;

const Icon = styled.img`
  width: 1rem;
  height: 1rem;
`;

const ConfirmContent = styled.div`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
margin-top: 1rem;
`;

const DetailContainer = styled.div`
  width: 90%;
  background-color: ${({ theme }) => theme.darkAccent};
  margin-left: 0 ;
  border-radius: 1rem;
  margin-top: 0.75rem;
`;

const DetailText = styled.p`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.gray ?? theme.white};
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin:0 0 0 0.5rem;
  text-align: left;
  width: 100%;
`;

const CopyWrapper = styled.div`
  text-align: left;
  padding: 0.5rem;
  cursor: pointer;
`;

const StyledCopy = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
`;

const ImageWrapper = styled.div<{ url: string }>`
  max-height: 100%; 
  max-width: 100%;
  min-height: 10rem;
  min-width: 10rem;
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 1.25rem;
  cursor: pointer;
  margin: 1rem  auto;
`;


export type TbcSendRequestProps = {
  web3Request: Web3SendTransactionRequest;
  requestWithinApp?: boolean;
  popupId: number | undefined;
  onResponse: () => void;
};

export const TbcSendRequest = (props: TbcSendRequestProps) => {
  const { web3Request, requestWithinApp, popupId, onResponse } = props;
  const { theme } = useTheme();
  const { setSelected } = useBottomMenu();
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [successTxId, setSuccessTxId] = useState('');
  const { addSnackbar, message } = useSnackbar();
  const { t } = useTranslation();
  const { isPasswordRequired, noApprovalLimit } = useWeb3Context();
  const { tbcAddress, tbcBalance, isProcessing, setIsProcessing, sendTbc, updateTbcBalance } = useTbc();
  const { createCollectionResponse, createNFTResponse, transferNFTResponse, mintFTResponse, transferFTResponse, mintPoolNFTResponse, initPoolNFTResponse, increaseLPResponse, consumeLPResponse, swapToTokenResponse, swapToTbcResponse, poolNFTMergeResponse, mergeFTLPResponse } = useOrds();


  // const requestSats = web3Request.reduce((a: number, item: { satoshis: number }) => a + item.satoshis, 0);
  // const tbcSendAmount = requestSats / TBC_DECIMAL_CONVERSION;

  // This useEffect handle the instance where the request is below the no approval setting and will immediately process the request.
  // useEffect(() => {
  //   if (hasSent || noApprovalLimit === undefined) return;
  //   if (web3Request.length > 0 && tbcSendAmount <= noApprovalLimit) {
  //     setHasSent(true);

  //     // Using timeout here so that the state that sendTbc relies on is ready. Not ideal but can refactor later.
  //     // TODO: Should consider a broader refactor of how state is being handled across hooks and context when the time is right.
  //     setTimeout(() => {
  //       processTbcSend();
  //     }, 100);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tbcSendAmount, noApprovalLimit, web3Request, hasSent]);

  useEffect(() => {
    if (requestWithinApp) return;
    setSelected('tbc');
  }, [requestWithinApp, setSelected]);

  useEffect(() => {
    if (!successTxId) return;
    if (!message && tbcAddress) {
      resetSendState();
      updateTbcBalance();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successTxId, message, tbcAddress]);

  useEffect(() => {
    if (requestWithinApp) return;

    const onbeforeunloadFn = () => {
      if (popupId) chrome.windows.remove(popupId);
    };

    window.addEventListener('beforeunload', onbeforeunloadFn);
    return () => {
      window.removeEventListener('beforeunload', onbeforeunloadFn);
    };
  }, [requestWithinApp, popupId]);

  const handleCopyToClipboard = (value: string | number) => {
    const textToCopy = typeof value === 'number' ? value.toString() : value;
    navigator.clipboard.writeText(textToCopy).then(() => {
      addSnackbar('Copied!', 'success');
    });
  };

  const processTbcSend = async () => {
    try {
      let validationFail = new Map<string, boolean>();
      validationFail.set('address', false);
      validationFail.set('script', false);
      validationFail.set('data', false);

      web3Request.forEach((request) => {
        // validate script or data if they are set
        if (request.script?.length === 0) {
          validationFail.set('script', true);
          return;
        } else if (request.data) {
          if (request.data.length === 0) {
            validationFail.set('data', true);
            return;
          }
        }
        // otherwise sending to address
        if (request.address && !validate(request.address)) {
          validationFail.set('address', true);
          return;
        }
      });
      let validationErrorMessage = '';
      if (validationFail.get('script')) {
        validationErrorMessage = 'Found an invalid script.';
      } else if (validationFail.get('data')) {
        validationErrorMessage = 'Found an invalid data.';
      } else if (validationFail.get('address')) {
        validationErrorMessage = 'Found an invalid receive address.';
      }

      if (validationErrorMessage) {
        addSnackbar(validationErrorMessage, 'error');
        return;
      }

      if (web3Request[0].address && !web3Request[0].satoshis) {
        addSnackbar('No sats supplied', 'info');
        return;
      }

      const sendRes = await sendTbc(web3Request, passwordConfirm, noApprovalLimit);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      if (!requestWithinApp) {
        chrome.runtime.sendMessage({
          action: 'sendTransactionResponse',
          txid: sendRes.txid,
          rawtx: sendRes.rawtx,
        });
      }

      setTimeout(async () => {
        onResponse();
        if (!requestWithinApp) {
          storage.remove('sendTransactionRequest');
        }

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  };

  const resetSendState = () => {
    setPasswordConfirm('');
    setSuccessTxId('');
    setIsProcessing(false);
  };

  const handleSendTbc = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (noApprovalLimit === undefined) throw Error('No approval limit must be a number');
    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    processTbcSend();
  };

  const handleCreateCollection = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      let collection_data;
      if (!web3Request[0].collection_data) {
        throw new Error("Please provide valid parameter!")
      } else {
        collection_data = JSON.parse(web3Request[0].collection_data);
        if (!collection_data.collectionName || !collection_data.description || !collection_data.supply || !collection_data.file) {
          throw new Error("Please provide valid parameter!")
        } else {
          collection_data.supply = Number(collection_data.supply);
        }
      }
      const sendRes = await createCollectionResponse(collection_data, passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }


  const handleCreateNft = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      if (!web3Request[0].nft_data || !web3Request[0].collection_id) {
        throw new Error("Please provide valid parameter!")
      }
      const sendRes = await createNFTResponse(web3Request[0].collection_id, JSON.parse(web3Request[0].nft_data), passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }
      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const handleTransferNft = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      if (!web3Request[0].nft_contract_address || !web3Request[0].address) {
        throw new Error("Please provide valid parameter!")
      }
      const sendRes = await transferNFTResponse(web3Request[0].nft_contract_address, web3Request[0].address, passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const handleMintFt = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      let ft_data;
      if (!web3Request[0].ft_data) {
        throw new Error("Please provide valid parameter!")
      } else {
        ft_data = JSON.parse(web3Request[0].ft_data);
        if (!ft_data.name || !ft_data.symbol || !ft_data.decimal || !ft_data.amount) {
          throw new Error("Please provide valid parameter!")
        } else {
          ft_data.decimal = Number(ft_data.decimal);
          ft_data.amount = Number(ft_data.amount);
        }
      }
      const sendRes = await mintFTResponse(JSON.parse(web3Request[0].ft_data), passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar(`Transaction Successful!`, 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const handleSendTbc20 = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      if (!web3Request[0].ft_contract_address || !web3Request[0].address || !web3Request[0].ft_amount) {
        throw new Error("Please provide valid parameter!")
      }
      const sendRes = await transferFTResponse(web3Request[0].ft_contract_address, web3Request[0].address, Number(web3Request[0].ft_amount), passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const handleMintPoolNft = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      if (!web3Request[0].ft_contract_address) {
        throw new Error("Please provide valid parameter!")
      }
      let with_lock: boolean = false;
      if (web3Request[0].with_lock) {
        with_lock = web3Request[0].with_lock;
      }
      const sendRes = await mintPoolNFTResponse(web3Request[0].ft_contract_address, with_lock, passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const handleInitPoolNft = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      if (!web3Request[0].nft_contract_address || !web3Request[0].address || !web3Request[0].ft_amount || !web3Request[0].tbc_amount) {
        throw new Error("Please provide valid parameter!")
      }
      const sendRes = await initPoolNFTResponse(web3Request[0].nft_contract_address, web3Request[0].address, Number(web3Request[0].tbc_amount), Number(web3Request[0].ft_amount), passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);

      addSnackbar(`Transaction Successful!`, 'success');

      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const handleIncreaseLP = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      if (!web3Request[0].nft_contract_address || !web3Request[0].address || !web3Request[0].tbc_amount) {
        throw new Error("Please provide valid parameter!")
      }
      const sendRes = await increaseLPResponse(web3Request[0].nft_contract_address, web3Request[0].address, Number(web3Request[0].tbc_amount), passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const handleConsumeLP = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      if (!web3Request[0].nft_contract_address || !web3Request[0].address || !web3Request[0].ft_amount) {
        throw new Error("Please provide valid parameter!")
      }
      const sendRes = await consumeLPResponse(web3Request[0].nft_contract_address, web3Request[0].address, Number(web3Request[0].ft_amount), passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const handleSwapToToken = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      if (!web3Request[0].nft_contract_address || !web3Request[0].address || !web3Request[0].tbc_amount) {
        throw new Error("Please provide valid parameter!")
      }
      const sendRes = await swapToTokenResponse(web3Request[0].nft_contract_address, web3Request[0].address, Number(web3Request[0].tbc_amount), passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const handleSwapToTbc = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      if (!web3Request[0].nft_contract_address || !web3Request[0].address || !web3Request[0].ft_amount) {
        throw new Error("Please provide valid parameter!")
      }
      const sendRes = await swapToTbcResponse(web3Request[0].nft_contract_address, web3Request[0].address, Number(web3Request[0].ft_amount), passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const handlePoolNFTMerge = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      if (!web3Request[0].nft_contract_address || !web3Request[0].merge_times) {
        throw new Error("Please provide valid parameter!")
      }
      const sendRes = await poolNFTMergeResponse(web3Request[0].nft_contract_address, Number(web3Request[0].merge_times), passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const handleFTLPMerge = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar('You must enter a password!', 'error');
      setIsProcessing(false);
      return;
    }

    try {
      if (!web3Request[0].nft_contract_address) {
        throw new Error("Please provide valid parameter!")
      }
      const sendRes = await mergeFTLPResponse(web3Request[0].nft_contract_address, passwordConfirm);
      if (!sendRes.txid || sendRes.error) {
        const message =
          sendRes.error === 'invalid-password'
            ? 'Invalid Password!'
            : sendRes.error === 'insufficient-funds'
              ? 'Insufficient Funds!'
              : sendRes.error === 'fee-too-high'
                ? 'Miner fee too high!'
                : sendRes.error === 'tx-size-too-large'
                  ? 'Tx too big. 50MB max'
                  : 'An unknown error has occurred! Try again.' + sendRes.error;

        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendRes.txid);
      addSnackbar('Transaction Successful!', 'success');

      // This should only get called if it's from the provider.
      chrome.runtime.sendMessage({
        action: 'sendTransactionResponse',
        txid: sendRes.txid,
        rawtx: '',
      });

      setTimeout(async () => {
        onResponse();

        storage.remove('sendTransactionRequest');

        if (popupId) chrome.windows.remove(popupId);
      }, 2000);
    } catch (error) {
      //console.log(error);
    } finally {
      setIsProcessing(false);
    }
  }

  const web3Details = () => {
    return web3Request.map((r, i) => {
      return (
        <LineItem key={i}>
          <Icon src={tbcCoin} />
          <Text style={{ margin: 0 }} theme={theme}>{`${t('Balance')}:${r.satoshis / TBC_DECIMAL_CONVERSION}`}</Text>
          <Text style={{ margin: 0 }} theme={theme}>
            {t('receive-address')}:{r.address ? truncate(r.address, 5, 5) : ''}
          </Text>
        </LineItem>
      );
    });
  };

  const clearRequest = () => {
    storage.remove('sendTransactionRequest');
    if (popupId) chrome.windows.remove(popupId);
    window.location.reload();
  };

  return (
    <>
      {isProcessing &&
        <PageLoader theme={theme} message="Sending..." />
      }

      {
        !isProcessing && !!web3Request && !web3Request[0].flag && <PageLoader theme={theme} message="Please provide types of transactions" />
      }

      {
        !isProcessing && !!web3Request && web3Request[0].flag && !["P2PKH", "COLLECTION_CREATE", "NFT_CREATE", "NFT_TRANSFER", "FT_MINT", "FT_TRANSFER", "POOLNFT_MINT", "POOLNFT_INIT", "POOLNFT_LP_INCREASE", "POOLNFT_LP_CONSUME", "POOLNFT_SWAP_TO_TOKEN", "POOLNFT_SWAP_TO_TBC", "POOLNFT_MERGE", "FTLP_MERGE"].includes(web3Request[0].flag) &&
        <PageLoader theme={theme} message="Invalid types of transactions" />
      }

      {(!isProcessing && !!web3Request && web3Request[0].flag === "P2PKH") && ((web3Request[0].satoshis && web3Request[0].address) ?
        (<ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('SEND_TBC Request')}</HeaderText>
          <Icon src={tbcCoin} />
          <Text
            theme={theme}
            style={{ cursor: 'pointer', margin: '0.75rem 0' }}
          >{`Available Balance: ${tbcBalance}`}</Text>
          <FormContainer noValidate onSubmit={(e) => handleSendTbc(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('tbc_amount')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} >
                  <span>
                    {web3Request[0].satoshis / TBC_DECIMAL_CONVERSION}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('receive-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {truncate(web3Request[0].address!, 12, 12)}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before sending TBC')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label={`Approve ${web3Request.reduce((a, item) => a + item.satoshis, 0) / TBC_DECIMAL_CONVERSION} TBC`}
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}

      {(!isProcessing && !!web3Request && web3Request[0].flag === 'COLLECTION_CREATE') && ((web3Request[0].collection_data) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('COLLECTION_CREATE Request')}</HeaderText>
          <HeaderText theme={theme}>{t('COLLECTION_CREATE Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleCreateCollection(e)}>

            <ImageWrapper url={JSON.parse(web3Request[0].collection_data).file} style={{ minWidth: '5rem', minHeight: '5rem' }}>
            </ImageWrapper>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('collection-name')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {JSON.parse(web3Request[0].collection_data).collectionName}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('description')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {JSON.parse(web3Request[0].collection_data).description}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('supply')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {JSON.parse(web3Request[0].collection_data).supply}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before creating collection')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='create'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)
      }

      {(!isProcessing && !!web3Request && web3Request[0].flag === 'NFT_CREATE') && ((web3Request[0].nft_data && web3Request[0].collection_id) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('NFT_CREATE Request')}</HeaderText>
          <HeaderText theme={theme}>{t('NFT_CREATE Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleCreateNft(e)}>

            {JSON.parse(web3Request[0].nft_data).file && <ImageWrapper url={JSON.parse(web3Request[0].nft_data).file} style={{ minWidth: '5rem', minHeight: '5rem' }}>
            </ImageWrapper>}
            <DetailContainer theme={theme} style={{ color: theme.white }}>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('contract-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(web3Request[0].collection_id!)}>
                  <span>
                    {truncate(web3Request[0].collection_id, 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('nft-name')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {JSON.parse(web3Request[0].nft_data).nftName}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('symbol')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {JSON.parse(web3Request[0].nft_data).symbol}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('description')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {JSON.parse(web3Request[0].nft_data).description}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('attributes')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {JSON.parse(web3Request[0].nft_data).attributes}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before creating NFT')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='create'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}


      {(!isProcessing && !!web3Request && web3Request[0].flag === 'NFT_TRANSFER') && ((web3Request[0].nft_contract_address && web3Request[0].address) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('NFT_TRANSFER Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleTransferNft(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('contract-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(web3Request[0].nft_contract_address!)}>
                  <span>
                    {truncate(web3Request[0].nft_contract_address!, 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('receive-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {truncate(web3Request[0].address!, 12, 12)}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before transfering NFT')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='transfer'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}

      {(!isProcessing && !!web3Request && web3Request[0].flag === 'FT_MINT') && ((web3Request[0].ft_data) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('FT_MINT Request')}</HeaderText>
          <HeaderText theme={theme}>{t('FT_MINT Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleMintFt(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('tokens')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {JSON.parse(web3Request[0].ft_data).name}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('symbol')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {JSON.parse(web3Request[0].ft_data).symbol}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('decimal')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {JSON.parse(web3Request[0].ft_data).decimal}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('tokens')}{t('Amount')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {JSON.parse(web3Request[0].ft_data).amount}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>

            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before minting FT')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='mint'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}

      {(!isProcessing && !!web3Request && web3Request[0].flag === 'FT_TRANSFER') && ((web3Request[0].ft_contract_address && web3Request[0].ft_amount && web3Request[0].address) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('FT_TRANSFER Request')}</HeaderText>
          <HeaderText theme={theme}>{t('FT_TRANSFER Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleSendTbc20(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('contract-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(web3Request[0].ft_contract_address!)}>
                  <span>
                    {truncate(web3Request[0].ft_contract_address!, 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('receive-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {truncate(web3Request[0].address!, 12, 12)}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('tokens')}{t('Amount')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {web3Request[0].ft_amount}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before transfering FT')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='transfer'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}


      {(!isProcessing && !!web3Request && web3Request[0].flag === 'POOLNFT_MINT') && ((web3Request[0].ft_contract_address) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('POOLNFT_MINT Request')}</HeaderText>
          <HeaderText theme={theme}>{t('POOLNFT_MINT Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleMintPoolNft(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('contract-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(web3Request[0].ft_contract_address!)}>
                  <span>
                    {truncate(web3Request[0].ft_contract_address!, 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('with-lock')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {web3Request[0].with_lock ? `${web3Request[0].with_lock}` : "false"}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before minting PoolLNFT')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='mint'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}

      {(!isProcessing && !!web3Request && web3Request[0].flag === 'POOLNFT_INIT') && ((web3Request[0].nft_contract_address && web3Request[0].address && web3Request[0].tbc_amount && web3Request[0].ft_amount) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('POOLNFT_INIT Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleInitPoolNft(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('contract-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(web3Request[0].nft_contract_address!)}>
                  <span>
                    {truncate(web3Request[0].nft_contract_address!, 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('Address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {truncate(web3Request[0].address!, 12, 12)}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  TBC{t('Amount')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {web3Request[0].tbc_amount}
                  </span>
                </DetailText>
              </CopyWrapper>


              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('tokens')}{t('Amount')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {web3Request[0].ft_amount}
                  </span>
                </DetailText>
              </CopyWrapper>
            </DetailContainer>

            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before initialize PoolNFT')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='initialize'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}

      {(!isProcessing && !!web3Request && web3Request[0].flag === 'POOLNFT_LP_INCREASE') && ((web3Request[0].nft_contract_address && web3Request[0].address && web3Request[0].tbc_amount) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('POOLNFT_LP_INCREASE Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleIncreaseLP(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('contract-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(web3Request[0].nft_contract_address!)}>
                  <span>
                    {truncate(web3Request[0].nft_contract_address!, 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('Address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {truncate(web3Request[0].address!, 12, 12)}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  TBC{t('Amount')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {web3Request[0].tbc_amount}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before increase PoolNFT LP')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='increaseLp'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}

      {(!isProcessing && !!web3Request && web3Request[0].flag === 'POOLNFT_LP_CONSUME') && ((web3Request[0].nft_contract_address && web3Request[0].address && web3Request[0].ft_amount) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('POOLNFT_LP_CONSUME Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleConsumeLP(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('contract-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(web3Request[0].nft_contract_address!)}>
                  <span>
                    {truncate(web3Request[0].nft_contract_address!, 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('Address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {truncate(web3Request[0].address!, 12, 12)}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('tokens')}{t('Amount')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {web3Request[0].ft_amount}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before consume PoolNFT LP')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='consumeLp'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}

      {(!isProcessing && !!web3Request && web3Request[0].flag === 'POOLNFT_SWAP_TO_TOKEN') && ((web3Request[0].nft_contract_address && web3Request[0].address && web3Request[0].tbc_amount) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('POOLNFT_SWAP_TO_TOKEN Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleSwapToToken(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('contract-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(web3Request[0].nft_contract_address!)}>
                  <span>
                    {truncate(web3Request[0].nft_contract_address!, 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('Address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {truncate(web3Request[0].address!, 12, 12)}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  TBC{t('Amount')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {web3Request[0].tbc_amount}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before swap to token')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='swap'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}

      {(!isProcessing && !!web3Request && web3Request[0].flag === 'POOLNFT_SWAP_TO_TBC') && ((web3Request[0].nft_contract_address && web3Request[0].address && web3Request[0].ft_amount) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('POOLNFT_SWAP_TO_TBC Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleSwapToTbc(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('contract-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(web3Request[0].nft_contract_address!)}>
                  <span>
                    {truncate(web3Request[0].nft_contract_address!, 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('Address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {truncate(web3Request[0].address!, 12, 12)}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('tokens')}{t('Amount')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {web3Request[0].ft_amount}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before swap to tbc')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='swap'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}

      {(!isProcessing && !!web3Request && web3Request[0].flag === 'POOLNFT_MERGE') && ((web3Request[0].nft_contract_address && web3Request[0].merge_times) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('POOLNFT_MERGE Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handlePoolNFTMerge(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('contract-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(web3Request[0].nft_contract_address!)}>
                  <span>
                    {truncate(web3Request[0].nft_contract_address!, 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('merge-times')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {web3Request[0].merge_times}
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before poolNFT merge')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='merge'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}

      {(!isProcessing && !!web3Request && web3Request[0].flag === 'FTLP_MERGE') && ((web3Request[0].nft_contract_address) ? (
        <ConfirmContent>
          <BackButton onClick={clearRequest} />
          <HeaderText theme={theme}>{t('FTLP_MERGE Request')}</HeaderText>
          <FormContainer noValidate onSubmit={(e) => handleFTLPMerge(e)}>

            <DetailContainer theme={theme} style={{ color: theme.white }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('contract-address')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(web3Request[0].nft_contract_address!)}>
                  <span>
                    {truncate(web3Request[0].nft_contract_address!, 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>
              </CopyWrapper>

            </DetailContainer>
            <Show when={isPasswordRequired}>
              <Input
                theme={theme}
                placeholder={t('enter-wallet-password')}
                type="password"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
            </Show>
            <Text theme={theme} style={{ margin: '1rem' }}>
              {t('Double check details before FTLP merge')}
            </Text>
            <Button
              theme={theme}
              type="primary"
              label='merge'
              disabled={isProcessing}
              isSubmit
            />
          </FormContainer>
        </ConfirmContent>) : <PageLoader theme={theme} message="Invalid params" />)}

    </>
  );
};
