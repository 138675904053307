import { storage } from '../utils/storage';
import { useState } from 'react';
import { Keys } from '../utils/keys';
import { TaggedDerivationResponse } from '../pages/requests/GenerateTaggedKeysRequest';
import { StoredUtxo } from './useTbc'
export type PubKeys = {
  tbcPubKey: string;
  ordPubKey: string;
  identityPubKey: string;
};
export type Addresses = {
  tbcAddress: string;
  ordAddress: string;
  identityAddress: string;
};
export type Balance = {
  tbc: number;
  satoshis: number;
  usdInCents: number;
};
export type WhitelistedApp = {
  domain: string;
  icon: string;
};
export type ftDtails = {
  ftContractId: string,
  ftCodeScript: string,
  ftTapeScript: string,
  ftSupply: 0,
  ftDecimal: 0,
  ftBalance: number,
  ftName: string,
  ftSymbol: string,
  ftDescription: string,
  ftOriginUtxo: string,
  ftCreatorCombineScript: string,
  ftHoldersCount: 0,
  ftIconUrl: string,
  ftCreateTimestamp: 0,
  ftTokenPrice: string
};
export type Settings = {
  noApprovalLimit: number;
  whitelist: WhitelistedApp[];
  isPasswordRequired: boolean;
  favoriteTokens: string[];
  customFeeRate: number;
};
export interface Account {
  accountName: string;
  accountType: 1 | 2;
  encryptedKeys: string;
  derivationTags: TaggedDerivationResponse[];
  addresses: Addresses;
  balance: Balance;
  pubKeys: PubKeys;
  paymentUtxos: StoredUtxo[];
  tokens: { [ftAddress: string]: ftDtails };
  settings: Settings;
}
export type ExchangeRateCache = {
  rate: number;
  changePercent: number;
  timestamp: number;
};
export interface StorageObject {
  accounts: { [tbcAddress: string]: Account };
  selectedAccount: string;
  accountNumber: number;
  exchangeRateCache: ExchangeRateCache;
  lastActiveTime: number;
  popupWindowId: number;
  passKey: string;
  salt: string;
  isLocked: boolean;
  network: 'mainnet';
  connectRequest?: any;
  sendTransactionRequest?: any;
  signMessageRequest?: any;
  broadcastRequest?: any;
  getSignaturesRequest?: any;
  generateTaggedKeysRequest?: any;
  encryptRequest?: any;
  decryptRequest?: any;
}
export const useAccounts = () => {
  // 获取所有的缓存数据
  const [allAccounts, setAllAccounts] = useState<any[]>([]);
  const [Account, setAccount] = useState<string>('');
  const getStorage = async (): Promise<StorageObject> => {
    try {
      return new Promise((resolve, reject) => {
        storage.get(null, (result) => {
          if (result) {
            resolve(result);
          } else {
            reject(new Error("Failed to get storage"));
          }
        });
      });
    } catch (error) {
      console.error("Error getting storage:", error);
      throw error;
    }
  };

  // 获取当前选中的账户对象
  const getCurrAccount = async (): Promise<Account> => {
    try {
      const storageData = await getStorage();
      const selectedAccount = storageData.selectedAccount;
      if (selectedAccount && storageData.accounts[selectedAccount]) {
        setAccount(selectedAccount);
        return storageData.accounts[selectedAccount];
      }
      throw new Error('Selected account not found.');
    } catch (error) {
      console.error("Error getting current account:", error);
      throw error;
    }
  };

  // 获取所有的账户
  const getAllAccounts = async (): Promise<Account[]> => {
    try {
      const storageData = await getStorage();
      if (!storageData.accounts) return [];
      return Object.values(storageData.accounts);
    } catch (error) {
      console.error("Error getting all accounts:", error);
      throw error;
    }
  };

  // 切换账户
  const switchAccount = async (address: string): Promise<void> => {
    try {
      const storageData = await getStorage();
      if (storageData.accounts[address]) {
        storageData.selectedAccount = address;
        await updateStorage(storageData);
        setAccount(address);
      } else {
        throw new Error(`Account with address ${address} not found.`);
      }
    } catch (error) {
      console.error("Error switching account:", error);
      throw error;
    }
  };

  // 删除账户
  const deleteAccount = async (address: string): Promise<void> => {
    try {
      const storageData = await getStorage();
      const { selectedAccount, accounts, accountNumber } = storageData;

      // 禁止删除最后一个账户
      if (Object.keys(accounts).length === 1) {
        throw new Error("Cannot delete the last account.");
      }

      // 禁止删除 selectedAccount
      if (address === selectedAccount) {
        throw new Error("Cannot delete the selected account.");
      }

      if (accounts[address] && Number(accountNumber) > 1) {
        delete accounts[address];
        const newAccountNumber = Number(accountNumber) - 1;
        await updateStorage({ ...storageData, accounts, accountNumber: newAccountNumber });
      } else {
        throw new Error(`Account with address ${address} not found.`);
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      throw error;
    }
  };

  // 在accounts中添加一个新的账户
  const addAccount = async (
    accountName: string,
    encryptedKeys: string,
    passKey: string,
    salt: string,
    keys: Partial<Keys> | Keys,
    accountType: 1 | 2 = 1,
  ): Promise<void> => {
    try {
      const storageData = await getStorage();
      const accounts = storageData.accounts || {};
      const tbcAddress = keys.walletAddress;
      if (accounts.tbcAddress) {
        throw new Error(`Account with address ${tbcAddress} already exists!`);
      }
      const accountNumber = storageData.accountNumber ? Number(storageData.accountNumber) + 1 : 1;
      // 添加新账户数据
      const newAccount = {
        [tbcAddress!]: {
          accountName,
          accountType,
          encryptedKeys,
          settings: {
            noApprovalLimit: 0,
            isPasswordRequired: storageData.isLocked,
            favoriteTokens: [],
          },
          addresses: {
            tbcAddress,
            ordAddress: keys.ordAddress,
            identityAddress: keys.identityAddress,
            taprootAddress: keys.taprootAddress,
          },
          balance: {
            tbc: 0,
            satoshis: 0,
            usdInCents: 0,
          },
          pubKeys: {
            tbcPubKey: keys.walletPubKey,
            ordPubKey: keys.ordPubKey,
            identityPubKey: keys.identityPubKey,
          },
          paymentUtxos: [],
          tokens: [],
        },
      };

      // 更新数据
      await updateStorage({
        ...storageData,
        accounts: { ...storageData.accounts, ...newAccount },
        derivationTags: [],
        whitelist: [],
        // isPasswordRequired: storageData.isLocked,
        isPasswordRequired: true,
        network: storageData.network || 'mainnet',
        accountNumber,
        passKey,
        salt
      });
    } catch (error) {
      console.error("Error adding new account:", error);
      throw error;
    }
  };

  // 更新 storage 中的数据
  const updateStorage = async (data: any): Promise<void> => {
    try {
      await new Promise<void>((resolve, reject) => {
        storage.set(data, () => {
          resolve();
        });
      });
    } catch (error) {
      console.error("Error updating storage:", error);
      throw error;
    }
  };

  const initStorage = async (): Promise<void> => {
    try {
      const storageData = await getStorage();
      if (!storageData.passKey) {
        await updateStorage({
          accounts: {},
          accountNumber: Number(0),
          selectedAccount: '',
        });
      }
      if (storageData.accountNumber > 0) {
        const allAccounts = Object.values(storageData.accounts);
        setAccount(storageData.selectedAccount);
        setAllAccounts(allAccounts);
      }
    } catch (error) {
      console.error("Error initializing storage:", error);
      throw error;
    }
  }

  return {
    allAccounts,
    Account,
    initStorage,
    updateStorage,
    getStorage,
    getCurrAccount,
    getAllAccounts,
    switchAccount,
    deleteAccount,
    addAccount
  };
};
