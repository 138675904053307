import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useViewport } from '../../hooks/useViewport';
import { Button } from '../../components/Button';
import { TopLogo, Text, TuringsLogo, FormContainer } from '../../components/Reusable';
import { Show } from '../../components/Show';
import { useBottomMenu } from '../../hooks/useBottomMenu';
import { useTheme } from '../../hooks/useTheme';
import { ColorThemeProps } from '../../theme';
import { storage } from '../../utils/storage';
import turingsLogo from '../../assets/turingmagic.png';
import topLogo from '../../assets/turingnewlog.png';
import earth from '../../assets/earth.png';
import { useTranslation } from 'react-i18next';
import { SelectSwitch } from '../../components/SelectSwitch';
import { SingleValue } from 'react-select';
import { useAccounts } from '../../hooks/useAccounts';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; 
  width: 100%;
  height: 50vh;  
  position: relative;
  padding: 0 20px;  
`;
const StyledEarth = styled.img<{ rotate: boolean }>`
  position: absolute;
  z-index: 1;
  transition: transform 0.5s ease-in-out;
  transform: ${({ rotate }) => rotate && 'rotate(270deg)'}; // 添加旋转效果
  width: 2rem;
  height: 2rem; 
`;

const EarthContainer = styled.div`
  position: absolute;
  top: 10px;  // 从顶部10px的地方开始
  right: 20px;  // 从右边10px的地方开始
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;  // 确保位于顶部
`;

const TitleText = styled.h1<ColorThemeProps>`
  font-size: 2rem;
  color: ${({ theme }) => theme.white};
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  margin: 0.25rem 0;
  text-align: center;
`;
const ButtonContainer = styled.div`
   width: 100%;
`;

export const Start = () => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [showStart, setShowStart] = useState(false);
  const { hideMenu, showMenu } = useBottomMenu();
  const {getStorage} = useAccounts();
  const { t, i18n } = useTranslation();
  const [selected, setSelected] = useState(i18n.language);
  const { isMobile } = useViewport();
  const [rotateEarth, setRotateEarth] = useState(false); // 用来控制地球旋转

  useEffect(() => {
    hideMenu();
    return () => {
      showMenu();
    };
  }, [hideMenu, showMenu]);

  useEffect(() => {
    storage.get('i18nextLng', (result) => {
      const savedLanguage = result['i18nextLng'];
      if (savedLanguage) {
        i18n.changeLanguage(savedLanguage);
        setSelected(savedLanguage);
      }
    })
  }, []);
  useEffect(() => {//这里是时候恢复跳转的页面的方法
    getStorage().then(result => {
      if (result?.connectRequest) {
        setShowStart(false);
        navigate('/connect');
        return;
      }
      // if (result?.msAddress) {
      //   setShowStart(false);
      //   navigate('/tbc-mswallet');
      //   return;
      // }
      if (result?.selectedAccount) {
        setShowStart(false);
        navigate('/tbc-wallet');
        return;
      }
      setShowStart(true);
    });
  }, [navigate]);

  const options = [
    { value: 'en', label: 'Eng' },
    { value: 'zh_hk', label: '繁中' },
    { value: 'fr', label: 'Français' },
    { value: 'ru', label: 'Русский' },
    { value: 'ar', label: 'العربية' },
    { value: 'es', label: 'Español' },
    { value: 'de', label: 'Deutsch' },
    { value: 'pt', label: 'Português' },
    { value: 'ja', label: '日本語' },
    { value: 'hi', label: 'हिन्दी' },
    { value: 'ko', label: '한국어' },
    { value: 'my', label: 'မြန်မာ' },
    { value: 'vi', label: 'Tiếng Việt' },
    { value: 'lo', label: 'ລາວ' },
    { value: 'th', label: 'ไทย' },
    { value: 'ms', label: 'Bahasa Malaysia' }
  ];

  const handleLanguageChange = (selectedOption: SingleValue<{ value: string; label: string }>) => {
    if (selectedOption && selectedOption.value !== i18n.language) {
      i18n.changeLanguage(selectedOption.value);
      setSelected(selectedOption.value);
      storage.set({ i18nextLng: selectedOption.value }); // 将所选语言保存到 storage
      setRotateEarth(!rotateEarth);
    }
  };

  return (
    <Show when={showStart}>
      <>
        <EarthContainer>
          <StyledEarth
            src={earth}
            rotate={rotateEarth} // 将旋转状态传递给StyledEarth
            style={{ marginTop: '1rem' }}
          />
          <SelectSwitch
            options={options}
            selected={selected}
            theme={theme}
            onChange={handleLanguageChange}
          />
        </EarthContainer>
        <Content style={{ marginBottom: isMobile ? '8rem' : '2rem' }}>
          <TopLogo src={topLogo} style={{ width: "150px", height: "auto", marginBottom: "50px" }} />
          <TuringsLogo src={turingsLogo} style={{ width: "220px", height: "auto", marginBottom: "15px", marginTop: "-20px" }} />
          <TitleText theme={theme}>{t('turing-wallet')}</TitleText>
          <Text theme={theme} style={{ margin: '0.25rem 0 1rem 0', fontWeight: 500, color: "#8C8C8C" }}>
            {t('the-wallet-for-second-life')} </Text>
          <ButtonContainer>
            <Button theme={theme} type="primary" label={t('create-new-wallet')} onClick={() => navigate('/create-wallet')} />
            {/* <Button theme={theme} type="primary" label='create a multi-signature wallet' onClick={() => navigate('/create-ms-wallet')}  /> */}
            <Button
              theme={theme}
              type="secondary"
              label={t('restore-wallet')}
              onClick={() => navigate('/restore-wallet')}
            />
          </ButtonContainer>
        </Content>
      </>
    </Show>
  );
};

 