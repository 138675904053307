import { validate } from 'bitcoin-address-validation';
import React, { useEffect, useState } from 'react';
import { useKeys } from '../hooks/useKeys';
import styled from 'styled-components';
import tbcCoin from '../assets/TuringLiitleCoin.png';
import turingsLogo from '../assets/TuringHome.png';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { PageLoader } from '../components/PageLoader';
import { SpeedBump } from '../components/SpeedBump';
import { QrCode } from '../components/QrCode';
import {
  ButtonContainer,
  ConfirmContent,
  FormContainer,
  HeaderText,
  MainContent,
  ReceiveContent,
  Text,
} from '../components/Reusable';
import { Show } from '../components/Show';
import { TopNav } from '../components/TopNav';
import { useBottomMenu } from '../hooks/useBottomMenu';
import { useTbc } from '../hooks/useTbc';
import { useSnackbar } from '../hooks/useSnackbar';
import { useTheme } from '../hooks/useTheme';
import { useWeb3Context } from '../hooks/useWeb3Context';
import { ColorThemeProps } from '../theme';
import { TBC_DECIMAL_CONVERSION, DUST, GAME_RECEIVE_ADDRESS } from '../utils/constants';
import { formatUSD } from '../utils/format';
import { sleep } from '../utils/sleep';
import copyIcon from '../assets/copy.svg';
import { AssetRow } from '../components/AssetRow';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';



// const Content = styled.div<{ $isMobile?: boolean }>`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: ${(props) => (props.$isMobile ? '100%' : '100%')};
//   height: ${(props) => (props.$isMobile ? '75%' : '100%')};
//   overflow-y: auto;
//   overflow-x: hidden;
// `;
const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
`;
const TuringsLogo = styled.img`
  width: 200px;
  height: 100%;
`;

const MiddleContainer = styled.div<ColorThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 2.75rem 1rem;
`;

const InputAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const CopyAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 2rem 0;
`;

const StyledCopy = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
`;

type PageState = 'main' | 'receive' | 'send' | 'assemble';
type AmountType = 'tbc' | 'usd';



export const TbcMsWallet = () => {
  const { theme } = useTheme();
  const { setSelected } = useBottomMenu();
  const [pageState, setPageState] = useState<PageState>('main');
  const [showSpeedBump, setShowSpeedBump] = useState(false);
  const [decisionType, setDecisionType] = useState<DecisionType | undefined>();
  const [speedBumpMessage, setSpeedBumpMessage] = useState('');
  const [satSendAmount, setSatSendAmount] = useState<number | null>(null);
  const [usdSendAmount, setUsdSendAmount] = useState<number | null>(null);
  const [receiveAddress, setReceiveAddress] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [amountType, setAmountType] = useState<AmountType>('tbc');
  const [successTxId, setSuccessTxId] = useState('');
  const [screenSwitchCount, setscreenSwitchCount] = useState(0);
  const [assembledTransaction, setAssembledTransaction] = useState<SendTbcResponse | null>(null);
  const { addSnackbar, message } = useSnackbar();
  const { isPasswordRequired } = useWeb3Context();
  const { verifyPassword } = useKeys();
  const location = useLocation(); // 用于获取当前页面的URL
  const { t } = useTranslation();
  const { tbcAddress, tbcBalance, isProcessing, setIsProcessing, assembleTbc, sendtbc, updateTbcBalance, updateTbcUtxos, exchangeRate } = useTbc();

  type SendTbcResponse = {
    txid?: string;
    rawtx?: string;
    amount?: number;
    change?: number;
    fee?: number;
    error?: string;
  };

  type DecisionType = 'password-confirm';
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setscreenSwitchCount((Count) => Count + 1); // 刷新余额
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange); // 清除事件监听器
    };
  }, []); // 仅在组件初次挂载时调用

  useEffect(() => {
    // // 获取URL中的flag参数
    // const params = new URLSearchParams(location.search);
    // const flagValue = params.get('charge/town') === 'true' ? true : false;// 如果flag为true，设置为true
    // // 获取URL的路径部分
    //const pathname = location.pathname;
    const fullUrl = window.location.href;
    ////console.log("pathname",pathname);
    // 检查路径中是否包含 "charge/town"
    const flagValue = fullUrl.includes('charge/town') ? true : false;
    //const flagValue = pathname.includes('tbc-wallet') ? true : false;

    ////console.log("flagValue",flagValue);
    // 根据flag决定是否自动填充收款地址
    if (flagValue) {
      setPageState('send');
      setReceiveAddress(GAME_RECEIVE_ADDRESS); // 自动填充收款地址
    }
    //  else {
    //   setReceiveAddress(''); // 清空收款地址
    // }
  }, [location]);

  useEffect(() => {
    if (screenSwitchCount > 0) {
      updateTbcBalance();
    }
  }, [screenSwitchCount]);

  useEffect(() => {
    setSelected('tbc');
  }, [setSelected]);

  useEffect(() => {
    if (!successTxId) return;
    if (!message && tbcAddress) {
      setPageState('main')
      resetSendState();
      //setPageState('main');
    }
  }, [successTxId, message, tbcAddress]);

  const resetSendState = () => {
    updateTbcBalance(true);
    setPasswordConfirm('');
    setSatSendAmount(null);
    setReceiveAddress('');
    setSuccessTxId('');
    setIsProcessing(false);
  };


  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(tbcAddress).then(() => {
      addSnackbar(`${t('Copied')}!`, 'success');
    });
  };

  const toggleAmountType = () => {
    if (amountType === 'tbc') {
      setAmountType('usd');
    } else {
      setAmountType('tbc');
    }
    setUsdSendAmount(null);
    setSatSendAmount(null);
  };

  const handleSpeedBumpConfirm = async (password?: string) => {
    // if (decisionType === 'password-confirm' && password && assembledTransaction?.rawtx) {
    if (assembledTransaction?.rawtx) {
      //setDecisionType(undefined);
      setShowSpeedBump(false);
      // const sendRes = await sendtbc(assembledTransaction.rawtx, password); // 使用弹窗输入的密码和保存的 rawtx
      const sendRes = await sendtbc(assembledTransaction.rawtx); // 使用弹窗输入的密码和保存的 rawtx
      if (!sendRes.txid) {
        addSnackbar(t('an-error-occurred-while-sending-tbc-please-try-again'), 'error');
        setIsProcessing(false);
        return;
      }
      setSuccessTxId(sendRes.txid);
      addSnackbar(t('transaction-successful-please-wait-a-few-minutes-for-your-balance-to-refresh'), 'success');
    }
  };
  const handleCancel = () => {
    setIsProcessing(false);
    setPageState('assemble');
    setTimeout(() => {
      updateTbcBalance();
    }, 50);
    setShowSpeedBump(false);
  };

  const handleSendTbc = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);
    if (!validate(receiveAddress)) {
      addSnackbar(t("you-must-enter-a-valid-tbc-address-paymail-not-yet-supported"), 'info');
      setIsProcessing(false);
      return;
    }

    if (!satSendAmount && !usdSendAmount) {
      addSnackbar(t('you-must-enter-an-amount'), 'info');
      setIsProcessing(false);
      return;
    }

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      setIsProcessing(false);
      return;
    }

    const isVerified = await verifyPassword(passwordConfirm);
    if (!isVerified) {
      addSnackbar(t('invalid-password'), 'error');
      setIsProcessing(false);
      return;
    }


    let satoshis = satSendAmount ?? 0;
    if (satoshis < DUST) {
      addSnackbar(`${t('output-is-below-dust-threshold')}${DUST}`, 'error');
      setPasswordConfirm('');
      setIsProcessing(false);
      return;
    }
    if (amountType === 'usd' && usdSendAmount) {
      satoshis = Math.ceil((usdSendAmount / exchangeRate) * TBC_DECIMAL_CONVERSION);
    }

    const assembleRes = await assembleTbc([{ flag: "P2PKH", address: receiveAddress, satoshis }], passwordConfirm);
    //const sendRes = await sendTbc([{ address: receiveAddress, satoshis }], passwordConfirm);
    if (assembleRes.rawtx == null || assembleRes.error) {
      // if (!sendRes.txid ) {
      const message =
        assembleRes.error === 'invalid-password'
          ? t('invalid-password')
          : assembleRes.error === 'insufficient-funds'
            ? t('insufficient-funds-including-transaction-fees')
            : assembleRes.error === 'fee-too-high'
              ? t('miner-fee-too-high')
              : assembleRes.error === 'output is below DUST'
                ? `${t('output-is-below-dust-threshold')}${DUST}`
                : t('an-unknown-error-has-occurred-try-again');//这里是等待确认错误的地方"output is below DUST"
      addSnackbar(message, 'error');
      setIsProcessing(false);
      setPasswordConfirm('');
      return;

    }
    setAssembledTransaction(assembleRes);  // 将 assembleRes 保存到状态中
    setPageState('send');
    //setDecisionType('password-confirm');
    setSpeedBumpMessage(`${t('Check Transaction Details')}:\n${t("Address")}: ${receiveAddress}\n${t("Amount")}: ${assembleRes.amount ? assembleRes.amount / 1e6 : 0}TBC\n${t("Change")}: ${assembleRes.change ? assembleRes.change / 1e6 : 0}TBC\n${t("Fee")}: ${assembleRes.fee}sats`);
    setShowSpeedBump(true);
  };



  const fillInputWithAllTbc = () => {
    setSatSendAmount(Math.round(tbcBalance * TBC_DECIMAL_CONVERSION));
  };

  useEffect(() => {
    const calcValue = () => {
      return amountType === 'tbc'
        ? satSendAmount
          ? satSendAmount / TBC_DECIMAL_CONVERSION
          : undefined
        : amountType === 'usd'
          ? usdSendAmount
            ? usdSendAmount
            : undefined
          : undefined;
    };

    calcValue();
  }, [satSendAmount, usdSendAmount, amountType]);

  const getLabel = () => {
    return amountType === 'tbc' && satSendAmount
      ? `${t('Send')} ${(satSendAmount / TBC_DECIMAL_CONVERSION).toFixed(6)}`
      : amountType === 'usd' && usdSendAmount
        ? `${t('Send')} ${formatUSD(usdSendAmount)}`
        : t('Enter Send Details');
  };

  const receive = (
    <ReceiveContent>
      <HeaderText style={{ marginTop: '1rem' }} theme={theme}>
        {t("Receive TBC")}
      </HeaderText>
      <Text style={{ marginBottom: '1.25rem' }} theme={theme}>
        {t('only-send-tbc-to-this-address')} {/* <Warning theme={theme}>Do not send Ordinals or TBC20 here.</Warning> */}
      </Text>
      <QrCode address={tbcAddress} onClick={handleCopyToClipboard} />
      <CopyAddressWrapper onClick={handleCopyToClipboard}>
        <StyledCopy src={copyIcon} />
        <Text theme={theme} style={{ margin: '0', color: theme.white, fontSize: '0.75rem' }}>
          {tbcAddress}
        </Text>
      </CopyAddressWrapper>
      <Button
        label={t('Go back')}
        theme={theme}
        type="secondary"
        onClick={() => {
          setPageState('main');
          updateTbcBalance();
        }}
      />
    </ReceiveContent>
  );

  const main = (
    <MainContent style={{ marginTop: '-3rem' }}>
      <MiddleContainer theme={theme}>
        <TuringsLogo src={turingsLogo} style={{ cursor: 'pointer' }} title="BlanceFresh" onClick={() => { updateTbcBalance() }} />

        <ButtonContainer>
          <Button theme={theme} type="primary" label={t("Receive")} onClick={() => setPageState('receive')} />
          <Button theme={theme} type="primary" label={t("Send")} onClick={() => { updateTbcUtxos(true); setPageState('assemble'); }} />
        </ButtonContainer>
        <AssetRow balance={tbcBalance} icon={tbcCoin} ticker="TBC" usdBalance={tbcBalance * exchangeRate} />
      </MiddleContainer>
    </MainContent>
  );

  const send = (
    <>
      <ConfirmContent>
        <HeaderText theme={theme}>{t('Send TBC')}</HeaderText>
        <Text
          theme={theme}
          style={{ cursor: 'pointer' }}
          onClick={fillInputWithAllTbc}
        >{`${t('Balance')}: ${tbcBalance}`}</Text>
        <FormContainer noValidate onSubmit={(e) => handleSendTbc(e)}>
          <Input
            theme={theme}
            placeholder={t('enter-address')}
            type="text"
            onChange={(e) => setReceiveAddress(e.target.value)}
            value={receiveAddress}
          />
          <InputAmountWrapper>
            <Input
              theme={theme}
              placeholder={amountType === 'tbc' ? t('enter-tbc-amount') : t('enter-usd-amount')}
              type='number'
              step="0.00000001"
              value={
                satSendAmount !== null && satSendAmount !== undefined
                  ? satSendAmount / TBC_DECIMAL_CONVERSION
                  : usdSendAmount !== null && usdSendAmount !== undefined
                    ? usdSendAmount
                    : ''
              }
              onChange={(e) => {
                const inputValue = e.target.value;
                // Check if the input is empty and if so, set the state to null
                if (inputValue === '') {
                  setSatSendAmount(null);
                  setUsdSendAmount(null);
                } else {
                  // Existing logic for setting state
                  if (amountType === 'tbc') {
                    setSatSendAmount(Math.round(Number(inputValue) * TBC_DECIMAL_CONVERSION));
                  } else {
                    setUsdSendAmount(Number(inputValue));
                  }
                }
              }}
            />
            {/* <Icon
              src={switchAsset}
              size="1rem"
              style={{
                position: 'absolute',
                right: '2.25rem',
                cursor: 'pointer',
              }}
              onClick={toggleAmountType}
            /> */}
          </InputAmountWrapper>
          <Show when={isPasswordRequired}>
            <Input
              theme={theme}
              placeholder={t('enter-wallet-password')}
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </Show>
          <Button
            theme={theme}
            type="primary"
            label={getLabel()}
            disabled={isProcessing || (!usdSendAmount && !satSendAmount)}
            isSubmit
          />
        </FormContainer>
        <Button
          label={t('Go back')}
          theme={theme}
          type="secondary"
          onClick={() => {
            setPageState('main');
            updateTbcBalance();
            resetSendState();
          }}
        />
      </ConfirmContent>
    </>
  );
  //这里是修改回插件
  return (
    <Show
      when={!showSpeedBump}
      whenFalseContent={
        <div style={{ whiteSpace: 'pre-wrap' }}>
          <SpeedBump
            theme={theme}
            message={speedBumpMessage}
            onCancel={handleCancel}
            onConfirm={(password?: string) => handleSpeedBumpConfirm(password)}
            showSpeedBump={showSpeedBump}
            withPassword={decisionType === 'password-confirm'}
          />
        </div>
      }
    >

      {/* <Content $isMobile={isMobile}> */}
      <Content>
        <TopNav />
        <Show when={isProcessing && pageState === 'main'}>
          <PageLoader theme={theme} message={t('loading-wallet')} />
        </Show>
        <Show when={isProcessing && pageState === 'assemble'}>
          <PageLoader theme={theme} message={t('assembling-transaction')} />
        </Show>
        <Show when={isProcessing && pageState === 'send'}>
          <PageLoader theme={theme} message={t('sending-tbc')} />
        </Show>
        <Show when={!isProcessing && pageState === 'main'}>{main}</Show>
        <Show when={!isProcessing && pageState === 'receive'}>{receive}</Show>
        <Show when={!isProcessing && pageState === 'assemble'}>{send}</Show>
        <Show when={!isProcessing && pageState === 'send'}>{send}</Show>
      </Content>
    </Show>
  );
};
