import { useState, useEffect } from "react";
import axios from "axios";
import { useKeys } from "./useKeys";
import { useNavigate } from "react-router-dom";

export interface MultisigData {
    history_count: number;
    history_list: TransactionData[];
}

export interface TransactionData {
    unsigned_txid: string;
    txid: string;
    tx_status: number;
    tx_raw: string;
    multi_sig_address: string;
    ft_contract_id: string;
    ft_decimal: number;
    balance: number;
    if_send: boolean;
    json_info: {
        pubkey_list: string[];
        vin_balance_list: number[];
        receiver_addresses: string[];
        collected_sig_list: {
            pubkey: string;
            sig_list: string[];
        }[];
    };
}

export const useMultisigTransaction = (pubkey: string) => {
    const [transactions, setTransactions] = useState<TransactionData[]>([]);
    const [filteredTransactions, setFilteredTransactions] = useState<TransactionData[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [txStatusFilter, setTxStatusFilter] = useState<0 | 1 | 2 | 3>(0);

    const pageSize = 20;
    const navigate = useNavigate()

    const fetchMultisigTransactionData = async (page: number = currentPage, pubkey: string): Promise<void> => {

        try {

            const response = await axios.get<MultisigData>(
                `https://turingwallet.xyz/multy/sig/history/address/${pubkey}/page/${currentPage - 1}/size/${pageSize}`
            );

            const { history_count, history_list } = response.data;
            setTotalPages(Math.ceil(history_count / pageSize));
            if (history_count > 0) {

                setTransactions(history_list);
            }

        } catch (error) {
            navigate("/")
        }
    };

    const applyFilter = (transactions: TransactionData[], status: number) => {
        const filtered = transactions.filter(transaction => transaction.tx_status === status);
        setFilteredTransactions(filtered);
    };

    const fetchNextPage = async () => {
        if (currentPage < totalPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    const fetchPrevPage = async () => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const changeStatusFilter = (status: 0 | 1 | 2 | 3) => {
        setTxStatusFilter(status);
        applyFilter(transactions, status);
    };

    useEffect(() => {
        fetchMultisigTransactionData(currentPage, pubkey);
    }, [currentPage]);

    useEffect(() => {
        applyFilter(transactions, txStatusFilter);
    }, [transactions, txStatusFilter])

    return {
        fetchMultisigTransactionData,
        filteredTransactions,
        currentPage,
        totalPages,
        fetchNextPage,
        fetchPrevPage,
        changeStatusFilter,
        txStatusFilter,
    };
};
