import { styled } from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import logo from '../assets/turingnewlog.png';
import { useTheme } from '../hooks/useTheme';
import { GithubIcon, Text } from './Reusable';
import activeCircle from '../assets/redround.png';
import { useKeys } from '../hooks/useKeys';
import { truncate } from '../utils/format';
import copyIcon from '../assets/copy-green.svg';
import { ColorThemeProps } from '../theme';
import points from '../assets/Point.png';
import { useSnackbar } from '../hooks/useSnackbar';
import switchIcon from '../assets/chevrons.svg';
import { useAccounts } from '../hooks/useAccounts';
import { useBottomMenu } from '../hooks/useBottomMenu';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// position: fixed;
//   width: 100%;这里是修改回插件
//   margin-left: 9px;
//   top: 20px;
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 9px;
  margin-left: 9px;
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Logo = styled.img`
  width: 90px;
`;

const DropDownAccountName = styled.p<ColorThemeProps>`
  color: ${({ theme }) => theme.white};
  font-size: 0.85rem;
  font-weight: 600;
  margin: 0;
`;
const Circle = styled.img`
  width: 1.25rem;
  height: 1.25rem;
  margin-left: 0.5rem;
`;
const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;
const SwitchIcon = styled.img`
  width: 1rem;
  height: 1rem;
  cursor: pointer;
`;
const Dropdown = styled.div<ColorThemeProps>`
  position: absolute;
  top: 2.5rem;
  left: 7rem;
  color: ${({ theme }) => theme.white};
  background: ${({ theme }) => theme.black + '90'};
  backdrop-filter: blur(10px);
  border: 1px solid ${({ theme }) => theme.gray};
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 12;
  min-width: 15rem;
  max-height: 18.75rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;
const DropdownItem = styled.div<ColorThemeProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.white + '10'};
  }
`;
const DropdownAddressText = styled.p`
  color: ${({ theme }) => theme.white};
  font-size: 0.75rem;
  margin: 0;
`;
const CopyIcon = styled.img`
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
`;
export const TopNav = () => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { tbcAddress,allAccounts,taprootAddress} = useKeys();
  const { addSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { handleSelect,hideMenu } = useBottomMenu();
  const { switchAccount } = useAccounts();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const toggleRef = useRef<HTMLDivElement | null>(null);

  const handleCopyToClipboard = (value: string | number) => {
    const textToCopy = typeof value === 'number' ? value.toString() : value;
    navigator.clipboard.writeText(textToCopy).then(() => {
      addSnackbar('Copied!', 'success');
    });
  };
  const handleSwitchAccount = async (selectedAddress: string) => {
    if (selectedAddress === tbcAddress) {
      setDropdownVisible(false);
      return;
    }
    await switchAccount(selectedAddress);
    setDropdownVisible(false);
    navigate('/tbc-wallet?reload=true');
  };
 
  const toggleDropdown = (event: React.MouseEvent) => {
    event.stopPropagation();
    setDropdownVisible((prev) => !prev);
  };

  return (
    <Container>
    <LogoWrapper>
      <Logo src={logo} />
      <Text style={{ margin: '0', marginLeft: '0.5rem', color: theme.primaryButton }} theme={theme}>
        |
      </Text>
      <Circle src={activeCircle} />
        {/* <Text
          style={{ margin: '0 0 0 0.25rem', color: theme.white, fontSize: '0.9rem', cursor: 'pointer' }}
          theme={theme}
          onClick={() =>handleCopyToClipboard(tbcAddress)}
        >
          {truncate(tbcAddress, 5, 5)}
        </Text> */}
         <FlexContainer style={{ justifyContent: 'flex-start', minWidth: 'fit-content' }} ref={toggleRef}>
          <Text
            style={{
              margin: '0 0.25rem 0 0.25rem',
              textAlign: 'left',
              color: theme.white,
              fontSize: '0.9rem',
              cursor: 'pointer',
              minWidth: 'fit-content',
            }}
            theme={theme}
            onClick={toggleDropdown}
          >
            {taprootAddress?truncate(taprootAddress,6, 6):truncate(tbcAddress,6, 6)}
          </Text>
          <SwitchIcon src={switchIcon}  onClick={toggleDropdown} />
        </FlexContainer>
        {dropdownVisible && (
          <Dropdown theme={theme} ref={dropdownRef}>
            {allAccounts.map((account:any,index) => (
              <DropdownItem
                key={index}
                theme={theme}
                onClick={() => handleSwitchAccount(account.addresses?.tbcAddress)}
              >
                <FlexContainer>
                <DropdownAddressText theme={theme} style={{ color: theme.primaryButton }}>
                  {`${t('Account')}${index + 1}:`}
                  </DropdownAddressText>
                  <DropdownAddressText theme={theme} 
                  style={{ 
                  color: account.addresses?.tbcAddress === tbcAddress ? `${theme.primaryButton}` : 'none',
                  cursor: 'pointer' }}>
                 {account.addresses?.taprootAddress?truncate(account.addresses.taprootAddress, 6, 6):truncate(account.addresses?.tbcAddress, 6, 6)}
                  </DropdownAddressText>
                  <CopyIcon
                    src={copyIcon}
                    onClick={(e) => {
                      e.stopPropagation();  
                      handleCopyToClipboard(account.addresses?.tbcAddress);
                    }}
                  />
                </FlexContainer>
              </DropdownItem>
            ))}
            <DropdownItem key={'new-account'} theme={theme} onClick={() => {hideMenu();handleSelect('settings', 'manage-accounts')}}>
              <FlexContainer>
                <DropDownAccountName style={{ textAlign: 'left' }} theme={theme}>
                  + {t('Add New Account')}
                </DropDownAccountName>
              </FlexContainer>
            </DropdownItem>
          </Dropdown>
        )}
    </LogoWrapper>
    <GithubIcon
      style={{ marginRight: '1.5rem', cursor: 'pointer' }}
      src={points}
      title={t('TBC points are coming soon...')}
      onClick={() => addSnackbar(t('TBC points are coming soon...'), 'info')}
    />
  </Container>
  );
};
