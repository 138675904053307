import { useState } from 'react';
import { styled } from 'styled-components';
import { useKeys } from '../hooks/useKeys';
import { useTheme } from '../hooks/useTheme';
import { useViewport } from '../hooks/useViewport';
import copyIcon from '../assets/copy-green.svg';
import { ColorThemeProps } from '../theme';
import { storage } from '../utils/storage';
import { Button } from './Button';
import { Input } from './Input';
import { PageLoader } from '../components/PageLoader';
import turingsLogo from '../assets/TuringBox.png';
import { FormContainer, HeaderText, Text, BoxLogo } from './Reusable';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from '../hooks/useSnackbar';
import { sleep } from '../utils/sleep';
import { SpeedBump } from '../components/SpeedBump';
import { Show } from '../components/Show';
import { idbstorage } from '../utils/idbstorage';
import { Modal } from '../components/Modal';

const Container = styled.div<ColorThemeProps & { $isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: ${(props) => (props.$isMobile ? '100vw' : '22.5rem')};
  height: ${(props) => (props.$isMobile ? '100vh' : '44.7rem')};
  margin: 0;
  background-color: ${({ theme }) => theme.mainBackground};
  color: ${({ theme }) => theme.white};
  z-index: 99;
`;
const StyledCopy = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
`;

export type UnlockWalletProps = {
  onUnlock: () => void;
};

export const UnlockWallet = (props: UnlockWalletProps) => {
  const { onUnlock } = props;
  const { theme } = useTheme();
  const [password, setPassword] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [verificationFailed, setVerificationFailed] = useState(false);
  const [showSpeedBump, setShowSpeedBump] = useState(false);
  const [speedBumpMessage, setSpeedBumpMessage] = useState('');
  const [confirmWords, setConfirmWords] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const { isMobile } = useViewport();
  const [loading, setLoading] = useState(false);
  const { addSnackbar} = useSnackbar();
  const { verifyPassword } = useKeys();
  const { t } = useTranslation();

  const handleUnlock = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    setIsProcessing(true);
    await sleep(25);
    const isVerified = await verifyPassword(password);
    if (isVerified) {
      setVerificationFailed(false);
      const timestamp = Date.now();
      storage.set({ lastActiveTime: timestamp });
      onUnlock();
    } else {
      addSnackbar(`${t('Invalid Password')}!`, 'error');
      setVerificationFailed(true);
      setPassword('');
      setVerificationFailed(false);
      setIsProcessing(false);
      setLoading(false);
    }
  };

  const signOut = async () => {
    if(confirmWords !== t('confirm-words')){
      addSnackbar(`${t('Invalid Confirm Words')}!`, 'error');
      return
    }
    await idbstorage.clear();
    await storage.clear();
    window.location.reload();
  };
  
  const handleCopyToClipboard = (value: string | number) => {
    const textToCopy = typeof value === 'number' ? value.toString() : value;
    navigator.clipboard.writeText(textToCopy).then(() => {
      addSnackbar('Copied!', 'success');
    });
  };

  return (
    <>
     <Show
      when={!showSpeedBump}
      whenFalseContent={
        <SpeedBump
          theme={theme}
          message={speedBumpMessage}
          onCancel={() =>  setShowSpeedBump(false)}
          onConfirm={() => {
            setModalOpen(true);
            setShowSpeedBump(false);
           }}
          showSpeedBump={showSpeedBump}
        />
      }
    >
    {modalOpen && (
        <Modal isOpen={modalOpen} onClose={() => {setModalOpen(false);}}>
         
          <HeaderText theme={theme}  style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(t('confirm-words'))}>
                {t('confirm-words')}&nbsp;
                <StyledCopy src={copyIcon} />
            </HeaderText>
          <FormContainer onSubmit={()=>{signOut()}}>
            <Input
              theme={theme}
              placeholder={t('enter-confirm-words')}
              type="text"
              value={confirmWords}
              onChange={(e) => setConfirmWords(e.target.value)}
            />
            <Button theme={theme} type="primary" label={t('confirm')} isSubmit disabled={confirmWords!==t('confirm-words')}/>
          </FormContainer>
        </Modal>
      )}

    {loading? <PageLoader theme={theme} message={t('unlocking')} /> :(
    <Container $isMobile={isMobile} theme={theme} style={{height:'100%',width:'100%'}}>
      <BoxLogo src={turingsLogo} style={{width:"auto"}}/>
      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
        <HeaderText style={{ fontSize: '1.75rem' }} theme={theme}>
          {t('unlock-wallet')} </HeaderText>
        <Text theme={theme}>{t('use-password-to-unlock-your-wallet')}</Text>
      </div>
      <FormContainer onSubmit={handleUnlock}>
        <Input
          theme={theme}
          placeholder={t('password')}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          shake={verificationFailed ? 'true' : 'false'}
        />
        <Button
          theme={theme}
          type="primary"
          label={isProcessing ? t('unlocking') : t('unlock')}
          disabled={isProcessing}
          isSubmit
        />
        <Text style={{ margin: '1rem' }} theme={theme}>
          ------ {t('If-you-forget-your-recovery-phrase-you-assets-will-be-lost-after-logging-out')} ------
        </Text>
        <Button
          theme={theme}
          type="secondary"
          label={'Sign Out'}
          onClick={ () => {
            setShowSpeedBump(true);
            setSpeedBumpMessage(t('sign-out-message'));
          }}
        />
      </FormContainer>
    </Container>)}
    </Show>
    </>
    
  );
};