import { useContext, useEffect, useState } from 'react';
import { MemoryRouter as Router, Route, Routes } from 'react-router-dom';
import back from './assets/back.png';
import styled from 'styled-components';
import { Show } from './components/Show';
import { UnlockWallet } from './components/UnlockWallet';
import { BottomMenuContext, BottomMenuProvider } from './contexts/BottomMenuContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import { useActivityDetector } from './hooks/useActivityDetector';
import {
  Web3BroadcastRequest,
  Web3DecryptRequest,
  Web3EncryptRequest,
  Web3SendTransactionRequest,
  Web3SignMessageRequest,
} from './hooks/useTbc';
import { Web3GetSignaturesRequest } from './hooks/useContracts';
import { Web3TransferOrdinalRequest } from './hooks/useOrds';
import { useTheme } from './hooks/useTheme';
import { useViewport } from './hooks/useViewport';
import { useWalletLockState } from './hooks/useWalletLockState';
import { AppsAndTools } from './pages/AppsAndTools';
import { TbcMsWallet } from './pages/TbcMsWallet';
import { TbcWallet } from './pages/TbcWallet';
import { CreateWallet } from './pages/onboarding/CreateWallet';
import { CreateMsWallet } from './pages/onboarding/CreateMsWallet';
import { ImportWallet } from './pages/onboarding/ImportWallet';
import { RestoreWallet } from './pages/onboarding/RestoreWallet';
import { Start } from './pages/onboarding/Start';
import { OrdWallet } from './pages/OrdWallet';
import { BroadcastRequest } from './pages/requests/BroadcastRequest';
import { TbcSendRequest } from './pages/requests/TbcSendRequest';
import { ConnectRequest } from './pages/requests/ConnectRequest';
import { DecryptRequest } from './pages/requests/DecryptRequest';
import { EncryptRequest } from './pages/requests/EncryptRequest';
import { GenerateTaggedKeysRequest } from './pages/requests/GenerateTaggedKeysRequest';
import { GetSignaturesRequest } from './pages/requests/GetSignaturesRequest';
import { OrdPurchaseRequest, Web3PurchaseOrdinalRequest } from './pages/requests/OrdPurchaseRequest';
import { OrdTransferRequest } from './pages/requests/OrdTransferRequest';
import { SignMessageRequest } from './pages/requests/SignMessageRequest';
import { Settings } from './pages/Settings';
import { ColorThemeProps } from './theme';
import { DerivationTag } from './utils/keys';
import { storage } from './utils/storage';

export type ThirdPartyAppRequestData = {
  appName: string;
  appIcon: string;
  domain: string;
  isAuthorized: boolean;
};

export type WhitelistedApp = {
  domain: string;
  icon: string;
};

const OuterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw; // 确保宽度占满整个视窗宽度
  min-height: 100vh; // 确保最小高度至少占满整个视窗高度
  background-color: ${({ theme }) => theme.mainBackground};
  background-image: url(${back}); // 设置背景图片
  background-size: cover; // 确保背景图片覆盖整个容器
  background-position: center; // 将背景图片居中显示
  background-repeat: no-repeat; // 防止背景图片重复
`;

const MainContainer = styled.div<{ $isMobile?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.$isMobile ? '100vw' : '22.5rem')};
  height: ${(props) => (props.$isMobile ? '100vh' : '44.7rem')};
  position: relative;
  padding: 0;
`;

const Container = styled.div<ColorThemeProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.mainBackground};
  position: relative;
`;
export const App = () => {
  const { isLocked } = useWalletLockState();
  const { isMobile, isTablet, isDesktop } = useViewport();
  const { theme } = useTheme();
  const menuContext = useContext(BottomMenuContext);
  const [popupId, setPopupId] = useState<number | undefined>();
  const [whitelistedApps, setWhitelistedApps] = useState<WhitelistedApp[]>([]);

  const [messageToSign, setMessageToSign] = useState<Web3SignMessageRequest | undefined>();
  const [broadcastRequest, setBroadcastRequest] = useState<Web3BroadcastRequest | undefined>();
  const [thirdPartyAppRequestData, setThirdPartyAppRequestData] = useState<ThirdPartyAppRequestData | undefined>();
  const [transactionSendRequest, setTransactionSendRequest] = useState<Web3SendTransactionRequest | undefined>();
  const [ordinalTransferRequest, setOrdinalTransferRequest] = useState<Web3TransferOrdinalRequest | undefined>();
  const [ordinalPurchaseRequest, setOrdinalPurchaseRequest] = useState<Web3PurchaseOrdinalRequest | undefined>();
  const [getSignaturesRequest, setGetSignaturesRequest] = useState<Web3GetSignaturesRequest | undefined>();
  const [pubKeyFromTagRequest, setPubKeyFromTagRequest] = useState<DerivationTag | undefined>();
  const [messageToEncrypt, setMessageToEncrypt] = useState<Web3EncryptRequest | undefined>();
  const [messagesToDecrypt, setMessagesToDecrypt] = useState<Web3DecryptRequest | undefined>();

  useActivityDetector(isLocked);
  const isTabletOrDesktop = isTablet || isDesktop;
  const handleUnlock = async () => {
    window.location.reload();
  };

  useEffect(() => {
    // `useEffect` 是 React 的一个钩子，用于在组件渲染后执行副作用操作。
    // 这里的依赖数组包含 `isLocked` 和 `menuContext`，意味着当这些值改变时会重新执行此 `useEffect`。

    storage.get(
      [
        'sendTransactionRequest',
        'transferOrdinalRequest',
        'purchaseOrdinalRequest',
        'connectRequest',
        'popupWindowId',
        'whitelist',
        'signMessageRequest',
        'signTransactionRequest',
        'broadcastRequest',
        'getSignaturesRequest',
        'generateTaggedKeysRequest',
        'encryptRequest',
        'decryptRequest',
      ],
      (result) => {
        // 调用 `storage.get` 获取本地存储中的数据。
        // 第一个参数是一个包含多个键名的数组，这些键名对应着不同类型的请求或状态。
        // 第二个参数是一个回调函数，当数据获取成功后执行这个回调，将结果作为参数传递进来。

        const {
          popupWindowId,
          connectRequest,
          whitelist,
          sendTransactionRequest,
          transferOrdinalRequest,
          purchaseOrdinalRequest,
          signMessageRequest,
          broadcastRequest,
          getSignaturesRequest,
          generateTaggedKeysRequest,
          encryptRequest,
          decryptRequest,
        } = result;
        // 使用解构赋值从 `result` 中提取各个请求数据。这些数据可能是从本地存储中读取的上次操作的状态。

        if (popupWindowId) setPopupId(popupWindowId);
        // 如果 `popupWindowId` 存在，则将其更新到 `popupId` 状态中。
        // `setPopupId` 是一个状态更新函数，用于保存弹窗的 ID。

        if (isLocked) return;
        // 如果 `isLocked` 为真（钱包处于锁定状态），则直接返回，不进行后续操作。
        // 这是因为在锁定状态下，不需要处理或显示其他请求。

        if (connectRequest && !isLocked) {
          setThirdPartyAppRequestData(connectRequest);
          // 如果存在 `connectRequest` 且钱包未锁定，则更新 `thirdPartyAppRequestData` 状态。
          // 这将设置第三方应用连接请求的数据，用于显示连接授权请求界面。
        }

        if (whitelist) {
          setWhitelistedApps(whitelist);
          // 如果 `whitelist` 存在，则将其更新到 `whitelistedApps` 状态。
          // `whitelistedApps` 是一个包含白名单应用的列表，可以在应用中显示或检查是否在白名单内。
        }

        if (sendTransactionRequest) {
          setTransactionSendRequest(sendTransactionRequest);
          // 如果 `sendTbcRequest` 存在，则更新 `tbcSendRequest` 状态。
          // 这代表有一个 TBC 发送请求，应用可以根据这个状态显示发送请求的界面。
        }

        if (transferOrdinalRequest) {
          setOrdinalTransferRequest(transferOrdinalRequest);
          menuContext?.handleSelect('ords');
          // 如果 `transferOrdinalRequest` 存在，则更新 `ordinalTransferRequest` 状态。
          // 并调用 `menuContext.handleSelect('ords')` 以选择相应的菜单项显示“Ord”相关请求的内容。
        }

        if (purchaseOrdinalRequest) {
          setOrdinalPurchaseRequest(purchaseOrdinalRequest);
          menuContext?.handleSelect('ords');
          // 如果 `purchaseOrdinalRequest` 存在，则更新 `ordinalPurchaseRequest` 状态。
          // 这可能触发“Ord”相关的界面，显示购买请求的详细信息。
        }

        if (signMessageRequest) {
          setMessageToSign(signMessageRequest);
          // 如果 `signMessageRequest` 存在，则更新 `messageToSign` 状态。
          // 这个状态会触发一个签名请求界面，让用户可以签名一个消息。
        }

        if (broadcastRequest) {
          setBroadcastRequest(broadcastRequest);
          // 如果 `broadcastRequest` 存在，则更新 `broadcastRequest` 状态。
          // `broadcastRequest` 可能用于广播一笔交易或请求，将在 UI 中显示该请求的内容。
        }

        if (getSignaturesRequest) {
          setGetSignaturesRequest(getSignaturesRequest);
          // 如果 `getSignaturesRequest` 存在，则更新 `getSignaturesRequest` 状态。
          // 这会触发显示获取签名请求的界面，用户可以查看和批准签名请求。
        }

        if (generateTaggedKeysRequest) {
          setPubKeyFromTagRequest(generateTaggedKeysRequest);
          // 如果 `generateTaggedKeysRequest` 存在，则更新 `pubKeyFromTagRequest` 状态。
          // `pubKeyFromTagRequest` 表示生成标签密钥的请求，可能用于生成或派生密钥对。
        }

        if (encryptRequest) {
          setMessageToEncrypt(encryptRequest);
          // 如果 `encryptRequest` 存在，则更新 `messageToEncrypt` 状态。
          // 这个状态触发加密请求的显示，让用户可以加密一个消息。
        }

        if (decryptRequest) {
          setMessagesToDecrypt(decryptRequest);
          // 如果 `decryptRequest` 存在，则更新 `messagesToDecrypt` 状态。
          // `messagesToDecrypt` 用于显示解密请求的界面，用户可以解密一个或多个消息。
        }
      },
    );
  }, [isLocked, menuContext]);
  // `useEffect` 的依赖项数组，包含 `isLocked` 和 `menuContext`。
  // 当 `isLocked` 或 `menuContext` 发生变化时，`useEffect` 将重新运行。

  //这里是修改插件
  return (
    <OuterContainer theme={theme}>
      <MainContainer $isMobile={isMobile} >
        {/* <MainContainer theme={theme}> */}
        <BottomMenuProvider>
          <Container theme={theme}>
            {/* <Container $isMobile={isMobile} theme={theme}> */}
            <SnackbarProvider>
              <Show when={!isLocked} whenFalseContent={<UnlockWallet onUnlock={handleUnlock} />}>
                <Router>
                  <Routes>
                    <Route path="/" element={<Start />} />
                    <Route path="/create-wallet" element={<CreateWallet onNavigateBack={() => null} isFirstLoaded/>} />
                    {/* <Route path="/create-ms-wallet" element={<CreateMsWallet />} /> */}
                    <Route path="/restore-wallet" element={<RestoreWallet onNavigateBack={() => null} isFirstLoaded/>} />
                    <Route path="/import-wallet" element={<ImportWallet onNavigateBack={() => null} isFirstLoaded isTaproot/>} />
                    <Route
                      path="/connect"
                      element={
                        <ConnectRequest
                          thirdPartyAppRequestData={thirdPartyAppRequestData}
                          popupId={popupId}
                          whiteListedApps={whitelistedApps}
                          onDecision={() => setThirdPartyAppRequestData(undefined)}
                        />
                      }
                    />
                    <Route
                      path="/tbc-wallet"
                      element={
                        <Show
                          when={
                            !transactionSendRequest &&
                            !messageToSign &&
                            !broadcastRequest &&
                            !getSignaturesRequest &&
                            !pubKeyFromTagRequest &&
                            !messageToEncrypt &&
                            !messagesToDecrypt
                          }
                          whenFalseContent={
                            <>
                              <Show when={!!transactionSendRequest}>
                                <TbcSendRequest
                                  popupId={popupId}
                                  web3Request={transactionSendRequest as Web3SendTransactionRequest}
                                  onResponse={() => setTransactionSendRequest(undefined)}
                                />
                              </Show>
                              <Show when={!!messageToSign}>
                                <SignMessageRequest
                                  messageToSign={messageToSign as Web3SignMessageRequest}
                                  popupId={popupId}
                                  onSignature={() => setMessageToSign(undefined)}
                                />
                              </Show>
                              <Show when={!!broadcastRequest}>
                                <BroadcastRequest
                                  request={broadcastRequest as Web3BroadcastRequest}
                                  popupId={popupId}
                                  onBroadcast={() => setBroadcastRequest(undefined)}
                                />
                              </Show>
                              <Show when={!!getSignaturesRequest}>
                                <GetSignaturesRequest
                                  getSigsRequest={getSignaturesRequest as Web3GetSignaturesRequest}
                                  popupId={popupId}
                                  onSignature={() => setGetSignaturesRequest(undefined)}
                                />
                              </Show>
                              <Show when={!!pubKeyFromTagRequest}>
                                <GenerateTaggedKeysRequest
                                  web3Request={pubKeyFromTagRequest as DerivationTag}
                                  popupId={popupId}
                                  onResponse={() => setPubKeyFromTagRequest(undefined)}
                                />
                              </Show>
                              <Show when={!!messageToEncrypt}>
                                <EncryptRequest
                                  messageToEncrypt={messageToEncrypt as Web3EncryptRequest}
                                  popupId={popupId}
                                  onEncrypt={() => setMessageToEncrypt(undefined)}
                                />
                              </Show>
                              <Show when={!!messagesToDecrypt}>
                                <DecryptRequest
                                  encryptedMessage={messagesToDecrypt as Web3DecryptRequest}
                                  popupId={popupId}
                                  onDecrypt={() => setMessagesToDecrypt(undefined)}
                                />
                              </Show>
                            </>
                          }
                        >
                          <TbcWallet isOrdRequest={!!ordinalTransferRequest || !!ordinalPurchaseRequest} />
                        </Show>
                      }
                    />
                    <Route path="/tbc-mswallet" element={<TbcMsWallet />} />
                    <Route
                      path="/ord-wallet"
                      element={
                        <Show
                          when={!ordinalTransferRequest && !ordinalPurchaseRequest}
                          whenFalseContent={
                            <>
                              <Show when={!!ordinalPurchaseRequest}>
                                <OrdPurchaseRequest
                                  popupId={popupId}
                                  web3Request={ordinalPurchaseRequest as Web3PurchaseOrdinalRequest}
                                  onResponse={() => setOrdinalPurchaseRequest(undefined)}
                                />
                              </Show>
                              <Show when={!!ordinalTransferRequest}>
                                <OrdTransferRequest
                                  popupId={popupId}
                                  web3Request={ordinalTransferRequest as Web3TransferOrdinalRequest}
                                  onResponse={() => setOrdinalTransferRequest(undefined)}
                                />
                              </Show>
                            </>
                          }
                        >
                          <OrdWallet />
                        </Show>
                      }
                    />
                    {/* <Route path="/apps" element={<AppsAndTools />} /> */}
                    <Route path="/settings" element={<Settings />} />
                  </Routes>
                </Router>
              </Show>
            </SnackbarProvider>
          </Container>
        </BottomMenuProvider>
      </MainContainer>
    </OuterContainer>
  );
};
