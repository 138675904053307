import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { PageLoader } from '../../components/PageLoader';
import { HeaderText, Text, BoxLogo,ButtonContainer } from '../../components/Reusable';
import { Show } from '../../components/Show';
import { useBottomMenu } from '../../hooks/useBottomMenu';
import { useKeys } from '../../hooks/useKeys';
import { useTbc } from '../../hooks/useTbc';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useTheme } from '../../hooks/useTheme';
import { ColorThemeProps } from '../../theme';
import copyIcon from '../../assets/copy-green.svg';
import { Modal } from '../../components/Modal';
import turingsLogo from '../../assets/turingsuccess.png';
import { useTranslation } from 'react-i18next';
import { useWeb3Context } from '../../hooks/useWeb3Context';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
`;

const SeedContainer = styled.div<ColorThemeProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.darkAccent};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.gray + '50'};
  width: 80%;
  padding: 1rem;
  margin: 0.5rem 0 1rem 0;
`;

const CopyToClipboardContainer = styled.div<ColorThemeProps>`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  border: none;
  background: none;
`;

const CopyIcon = styled.img`
  width: 0.85rem;
  height: 0.85rem;
`;

const AddressItem = styled.div<ColorThemeProps>`
  display: flex;
  justify-Content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.darkAccent};
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid ${({ theme }) => theme.gray + '50'};
`;
const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: ${({ theme }) => theme.primaryButton};
  &:hover {
    color: ${({ theme }) => theme.black};
  }
`;
const StyledText = styled(Text)`
  word-wrap: break-word;
  word-break: break-all;
`;
interface MsTransactionProps {
  pubkey: string;
}

export const CreateMsWallet: React.FC<MsTransactionProps> = ({ pubkey }) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [seedWords, setSeedWords] = useState<string[]>([]);
  const {tbcBalance} = useTbc();
  const { addSnackbar } = useSnackbar();
  const { hideMenu, showMenu } = useBottomMenu();
  const [totalSignatures, setTotalSignatures] = useState('');
  const [unlockSignatures, setUnlockSignatures] = useState('');
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [newPubkeys, setNewPubkeys] = useState('');
  const [associatedPubKeyes, setAssociatedPubKeyes] = useState<string[]>([pubkey]);
  const [msAddress, setMsAddress] = useState('');
  const { t } = useTranslation();

  const [passwordConfirm, setPasswordConfirm] = useState('');
  const { isPasswordRequired } = useWeb3Context();
  const { verifyPassword, generateMultiSignAddress } = useKeys();

  useEffect(() => {
    hideMenu();

    return () => {
      showMenu();
    };
  }, [hideMenu, showMenu]);

  useEffect(() => {
    if (modalOpen) {
      // 模态框打开时重置输入框的值
      setNewPubkeys('');
    }
  }, [modalOpen]);

  const handleAddAddress = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const trimmedAddress = newPubkeys.trim();

    const btcCompressedPubkeyRegex = /^[0-9a-fA-F]{66}$/;
    const isValidBitcoinPubkey = btcCompressedPubkeyRegex.test(trimmedAddress) && (trimmedAddress.startsWith('02') || trimmedAddress.startsWith('03'));

    if (trimmedAddress && !isValidBitcoinPubkey) {
      addSnackbar(t('invalid-pubkey'), 'error');
      return;
    }

    if (trimmedAddress && associatedPubKeyes.includes(trimmedAddress)) {
      addSnackbar(t('the-pubkey-is-duplicate-please-check'), 'error');
      return;
    }

    if (trimmedAddress && !associatedPubKeyes.includes(trimmedAddress)) {
      const updatedPubKeys = [...associatedPubKeyes, trimmedAddress];

      setAssociatedPubKeyes(updatedPubKeys);
      setNewPubkeys('');
      setModalOpen(false);
    }

  };

  const handleKeyGeneration = async () => {

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      return;
    }

    const isVerified = await verifyPassword(passwordConfirm);
    if (!isVerified) {
      addSnackbar(t('invalid-password'), 'error');
      return;
    }
    associatedPubKeyes.sort();
    try {
      const msAddress = await generateMultiSignAddress(associatedPubKeyes, parseInt(unlockSignatures), passwordConfirm);
      setMsAddress(msAddress);
    } catch (error) {
      addSnackbar(t('create failed'), 'info');
      return;
    }

    setLoading(false);
    setStep(3);
  };

  const handleCopyToClipboard = (seed: string) => {
    navigator.clipboard.writeText(seed).then(() => {
      addSnackbar(t('Copied')!, 'success');
    });
  };

  const handleTotalSignaturesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTotalSignatures(e.target.value);
  };

  const handleTotalSignaturesBlur = () => {
    const value = totalSignatures.trim();

    if (!value) {
      return;
    }

    const parsedValue = parseInt(value);

    if (isNaN(parsedValue)) {
      return;
    }

    if (parsedValue < 3) {
      addSnackbar(t('pubkey-limit-reached-minimum-3'), 'error');
      setTotalSignatures('3');
      return;
    }

    if (parsedValue > 10) {
      addSnackbar(t('pubkey-limit-reached-maximum-10'), 'error');
      setTotalSignatures('10');
      return;
    }

    setTotalSignatures(value);

    if (parseInt(unlockSignatures) > parsedValue) {
      addSnackbar(t('unlock-signatures-cannot-exceed-total-pubkeys'), 'error');
      setUnlockSignatures(value);
    }

    if (associatedPubKeyes.length > parsedValue) {
      addSnackbar(t('associated-pubkeys-exceed-total-pubkeys'), 'error');
      setAssociatedPubKeyes(associatedPubKeyes.slice(0, parsedValue));
    }
  };

  const handleUnlockSignaturesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (parseInt(value) < 1) {
      addSnackbar(t('address-limit-reached-minimum-1-addresses-allowed'), 'error');
      return;
    }
    if (parseInt(value) > 6) {
      addSnackbar(t('address-limit-reached-maximum-6-addresses-allowed'), 'error');
      return;
    }
    setUnlockSignatures(value);
    if (parseInt(value) > parseInt(totalSignatures)) {
      addSnackbar(t('unlock-signatures-cannot-exceed-total-signatures'), 'error');
    }
  };

  const promptStep = (
    <>
      <Content>
        <div style={{ marginTop: "20px" }}>
          <HeaderText theme={theme} style={{ fontSize: '2rem', color: theme.primaryButton }}>{t('multisignature')}</HeaderText>
          {/* <HeaderText theme={theme} style={{ fontSize: '2rem', color: theme.primaryButton }}>create process</HeaderText> */}
          <Text
            theme={theme}
            style={{
              marginBottom: '1rem',
              textAlign: 'left',
              paddingLeft: '1rem'
            }}
          >
            {t('if-this-is-your-first-time-creating-please-read-the-instructions-below-otherwise-click-next')} </Text>
        </div>
        <SeedContainer theme={theme} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem' }}>
          <div style={{ fontSize: '1.5rem', fontWeight: 'bold', color: theme.primaryButton, marginRight: '1rem' }}>1.</div>
          <div>
            <Text style={{ fontWeight: 'bold', fontSize: '1.1rem', marginBottom: '0', color: 'white' }} theme={theme}>
              {t('set-the-associated-pubkeys')} </Text>
            <Text theme={theme} style={{ color: "#8C8C8C", textAlign: "left", paddingLeft: "0.5rem" }}>
              {t('The initial creation of a multisig wallet will deposit 6000 satoshis into the wallet, and your multisig address along with the corresponding public keys will be saved on the blockchain.')} </Text>

          </div>
        </SeedContainer>
        <SeedContainer theme={theme} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: '1rem' }}>
          <div style={{ fontSize: '1.5rem', fontWeight: 'bold', color: theme.primaryButton, marginRight: '1rem' }}>2.</div>
          <div>
            <Text style={{ fontWeight: 'bold', fontSize: '1.1rem', marginBottom: '0', color: 'white' }} theme={theme}>
              {t('set-the-number-of-signatures')} </Text>
            <Text theme={theme} style={{ color: "#8C8C8C", textAlign: "left", paddingLeft: "0.2rem" }}>
              {t('the-number-of-signatures-required-to-execute-on-chain-transactions')} </Text>
          </div>
        </SeedContainer>
        <Button theme={theme} type="primary" label={t('next')} onClick={() => setStep(2)} />
        <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => navigate('/')} />
      </Content>
    </>
  );

  const CreateStep = (
    <>
      <Content>
        <div >
          <HeaderText theme={theme} style={{ fontSize: '1.5rem', color: theme.primaryButton, marginBottom: '0.5rem' }}>
            {t('multisignature-wallet-creation')} </HeaderText>
        </div>
        <FormContainer>
          <Input
            theme={theme}
            placeholder={t('total-pubkeys')}
            type="number"
            value={totalSignatures}
            onChange={handleTotalSignaturesChange}
            onBlur={handleTotalSignaturesBlur}
          />
          {/* <Text theme={theme} style={{ fontWeight: 'bold', fontSize: '0.9rem', marginLeft: '4rem', marginTop: '0.5rem', marginBottom: '-0.2rem', textAlign: 'left' }}>
            {t('unlock-signatures-required')} 
            </Text> */}
          <Input
            theme={theme}
            placeholder={t('unlock-signatures')}
            type="number"
            style={{
              borderColor: (unlockSignatures > totalSignatures) ? 'red' : ''
            }}
            value={unlockSignatures}
            onChange={handleUnlockSignaturesChange}
          />
        </FormContainer>
        <SeedContainer theme={theme} style={{
           display: 'flex',
           alignItems: 'center', 
           height: '40%',
           padding: "0.5rem",
           width:'90%',
           marginBottom : '0'}}>
          <Text theme={theme} style={{ fontWeight: 'bold', fontSize: '0.9rem', marginLeft: '1rem', textAlign: 'left' }}>
            {t('connected-wallets')}
          </Text>
          <div
            style={{
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
          >

            <style>
                    {`
              div::-webkit-scrollbar {
                display: none;
              }
            `}
            </style>

            {[...Array(Math.max(3, associatedPubKeyes.length)).keys()].map((index) => (
              <div key={index}>
                {index < associatedPubKeyes.length ? (
                  <AddressItem theme={theme}>
                    <Text
                      theme={theme}
                      style={{
                        marginBottom: '0.5rem',
                        color: theme.white,
                        whiteSpace: 'nowrap',
                        overflowX: "hidden",
                        textOverflow: 'ellipsis',
                      }}
                    >
                      0x{associatedPubKeyes[index]}
                    </Text>
                    <CloseButton
                      theme={theme}
                      onClick={() => {
                        const updatedAddresses = associatedPubKeyes.filter((_, i) => i !== index);
                        setAssociatedPubKeyes(updatedAddresses);
                      }}
                    >
                      &times;
                    </CloseButton>
                  </AddressItem>
                ) : (
                  <AddressItem theme={theme} >
                    <Text theme={theme} style={{ color: '#8C8C8C',height: '6px', alignItems: 'center' }}>
                      {t('pubkey')} {index + 1}:{t('waiting-for-input')}
                    </Text>
                  </AddressItem>

                )}
              </div>
            ))}
          </div>

          <Button
            theme={theme}
            type="primary"
            style={{ marginLeft: '1rem' }}
            label="+"
            onClick={() => setModalOpen(true)}
            disabled={associatedPubKeyes.length >= parseInt(totalSignatures)}
          />
        </SeedContainer>
        <Input
            theme={theme}
            placeholder={t('enter-wallet-password')}
            type="password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            style={{ marginBottom: '0' }}
          />
        <ButtonContainer>
        <Button
          theme={theme}
          type="primary"
          label={t('next')}
          style={{ margin: '0 0.5rem' }}
          onClick={() => {
            if (associatedPubKeyes.length !== parseInt(totalSignatures)) {
              addSnackbar(t('number of pubkeys must match total pubkeys'), 'error');
              return;
            }
            if (!associatedPubKeyes.includes(pubkey)) {
              addSnackbar(t('It-must-contain-your-own-public-key'), 'error');
              return;
            }
            if(tbcBalance < 0.006){
              addSnackbar(t('insufficient-funds'), 'error');
              return;
            }
            handleKeyGeneration();
            setStep(3);
          }}
        />
        <Button theme={theme} type="secondary" label={t('Go back')} style={{ margin: '0 0.5rem' }} onClick={() => setStep(1)} />
        </ButtonContainer>
        {/* 模态框 */}
        {modalOpen && (
          <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)}>
            <FormContainer onSubmit={handleAddAddress}>
              <Input
                theme={theme}
                placeholder={t('enter pubkey')}
                type="text"
                value={newPubkeys}
                onChange={(e) => setNewPubkeys(e.target.value)}
              />
              <Button theme={theme} type="primary" label={t('add')} isSubmit />
            </FormContainer>
          </Modal>
        )}
      </Content>
    </>
  );

  const copySeedStep = (
    <>
      <Content>
        <HeaderText theme={theme}>{t('your-multisigature-address')}</HeaderText>
        <Text theme={theme} style={{ marginBottom: '1rem' }}>
          {t('safely-write-down-and-store-your-seed-phrase-in-a-safe-place')} </Text>
        <SeedContainer theme={theme}>
          <StyledText style={{ textAlign: 'left', width: '100%', margin: '0', color: theme.white }} theme={theme}>
            {msAddress.trim()}
          </StyledText>
          <CopyToClipboardContainer onClick={() => handleCopyToClipboard(msAddress.trim())}>
            <CopyIcon src={copyIcon} />
            <Text
              style={{
                color: theme.primaryButton,
                textDecoration: 'underline',
                margin: '0 0 0 0.5rem',
                textAlign: 'left',
                fontSize: '0.75rem',
              }}
              theme={theme}
            >
              {t('copy-to-clipboard')} </Text>
          </CopyToClipboardContainer>
        </SeedContainer>
        <Button
          theme={theme}
          type="primary"
          label={t('next')}
          onClick={() => {
            setStep(4);
            setSeedWords([]);

          }}
        />
      </Content>
    </>
  );
  const successStep = (
    <>
      <Content style={{ marginTop: '6rem' }}>
        <BoxLogo src={turingsLogo} />
        <div style={{ marginTop: "50px", marginBottom: "40px" }}>
          <HeaderText theme={theme}>{t('success')}!</HeaderText>
          <Text theme={theme} style={{ marginBottom: '1rem' }}>
            {t('your-wallet-is-ready-to-go')} </Text>
        </div>
        <Button
          theme={theme}
          type="primary"
          label={t('enter')}
          onClick={() => {
            navigate('/');
          }}
        />
      </Content>
    </>
  );

  return (
    <>
      <Show when={loading}>
        <PageLoader theme={theme} message={t('generating-keys')} />
      </Show>
      <Show when={!loading && step === 1}>{promptStep}</Show>
      <Show when={!loading && step === 2}>{CreateStep}</Show>
      <Show when={!loading && step === 3}>{copySeedStep}</Show>
      <Show when={!loading && step === 4}>{successStep}</Show>
    </>
  );
};
