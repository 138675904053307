import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import init from 'bsv-wasm-web';
import styled from 'styled-components';
import turingsWhiteLogo from '../../assets/TuringLiitleCoin.png';
import turingsLogo from '../../assets/turingsuccess.png';
import wifWallet from '../../assets/restore.png';
import otherWallet from '../../assets/other-wallet.svg';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { PageLoader } from '../../components/PageLoader';
import { HeaderText, Text, BigCoinLogo } from '../../components/Reusable';
import { Show } from '../../components/Show';
import { ToggleSwitch } from '../../components/ToggleSwitch';
import { WalletRow } from '../../components/WalletRow';
import { useBottomMenu } from '../../hooks/useBottomMenu';
import { SupportedWalletImports, useKeys } from '../../hooks/useKeys';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useTheme } from '../../hooks/useTheme';
import { ColorThemeProps } from '../../theme';
import toplogo from '../../assets/turingnewlog.png';
import { useTranslation } from 'react-i18next';
import { useAccounts } from '../../hooks/useAccounts';
import { SettingsPage } from '../Settings';


const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
`;

const SeedInput = styled.textarea<ColorThemeProps & { $isExpert: boolean }>`
  background-color: ${({ theme }) => theme.darkAccent};
  border-radius: 0.5rem;
  border: 1px solid ${({ theme }) => theme.gray + '50'};
  width: 80%;
  height: 4rem;
  font-size: 0.85rem;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  padding: 1rem;
  margin: 0.5rem;
  outline: none;
  color: ${({ theme }) => theme.white + '80'};
  resize: none;

  &::placeholder {
    color: ${({ theme }) => theme.white + '80'};
  }
`;

const ExpertImportWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;
`;

const WalletWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TuringsWalletContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.black};
  width: 1.25rem;
  height: 1.25rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
`;

const WalletLogo = styled.img`
  width: auto;
  height: 2.25rem;
`;

const WalletText = styled(Text)`
  margin: 0 0 0 1rem;
  text-align: left;
  color: ${({ theme }) => theme.white};
  font-weight: 600;
`;

const TopLogo = styled.img`
  width: 130px;
  height: auto;
`;
export type RestoreAccountProps = {
  onNavigateBack: (page: SettingsPage) => void;
  isFirstLoaded?: boolean;
};

export const RestoreWallet =  ({ onNavigateBack, isFirstLoaded = false }: RestoreAccountProps) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [step, setStep] = useState(0);
  const [seedWords, setSeedWords] = useState<string>('');
  const { addSnackbar } = useSnackbar();
  const { switchAccount } = useAccounts();
  const { generateSeedAndStoreEncrypted } = useKeys();
  const { hideMenu, showMenu } = useBottomMenu();
  const [loading, setLoading] = useState(false);
  const [importType, setImportType] = useState<'mnemonic' | 'wif'|null>(null);
  const [isExpertImport, setIsExpertImport] = useState(false);
  const [importWallet, setImportWallet] = useState<SupportedWalletImports | undefined>();
  const [walletDerivation, setWalletDerivation] = useState<string | null>(null);
  const [accountName, setAccountName] = useState<string>('');
  const [ordDerivation, setOrdDerivation] = useState<string | null>(null);
  const [identityDerivation, setIdentityDerivation] = useState<string | null>(null);
  const { t } = useTranslation();
  useEffect(() => {
    hideMenu();

    return () => {
      showMenu();
    };
  }, [hideMenu, showMenu]);

  const handleExpertToggle = () => setIsExpertImport(!isExpertImport);

  const handleRestore = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    if (password.length < 8) {
      setLoading(false);
      addSnackbar(t('the-password-must-be-at-least-8-characters'), 'error');
      return;
    }

    if (isFirstLoaded&&password !== passwordConfirm) {
      setLoading(false);
      addSnackbar(t('the-passwords-do-not-match'), 'error');
      return;
    }

    // Some artificial delay for the loader
    await init();
    const keys = await generateSeedAndStoreEncrypted(//这里是从三个keys产生助记词的
      accountName,
      password,
      isFirstLoaded,
      seedWords,
      walletDerivation,
      ordDerivation,
      identityDerivation,
      importWallet,
    );
    if (!keys) {
      addSnackbar(t('an-error-occurred-while-restoring-the-wallet'), 'error');
      setLoading(false);
      setStep(2);
      return;
    }
    if(isFirstLoaded){
    await switchAccount(keys.walletAddress);
    }
    setLoading(false);
    setStep(4);
  };

  const handleWalletSelection = (wallet?: SupportedWalletImports) => {
    setImportWallet(wallet);
    if (wallet === 'wif') {
      isFirstLoaded ? navigate(`/import-wallet?type=${wallet}`) : onNavigateBack('import-wif');
      return;
    }
    if (wallet === 'taproot') {
      isFirstLoaded ? navigate(`/import-wallet?type=${wallet}`) : onNavigateBack('import-taproot');
      return;
    }
    setStep(2);
  };

  const getRestoreTitle = () => {
    return importWallet === 'turings'
      ? t('restore-turing-wallet')
      : importWallet === 'tp'
       ? t('restore-tp-wallet')
       : importWallet === 'okx'
         ? t('restore-nabox-wallet')
        : importWallet === 'nabox'
         ? t('restore-nabox-wallet')
            : t('restore-wallet');
  };

  const getRestoreDescription = () => {
    return importWallet
      ? t('enter-your-seed-phrase')
      : t('enter-a-seed-phrase-and-use-custom-derivation-paths-to-import-a-wallet-from-anywhere');
  };

  const passwordStep = (
    <>
      <Content style={{marginTop:'4rem'}}>
        <HeaderText theme={theme}>{isFirstLoaded?t('create-a-password'):t('Import Account')}</HeaderText>
        <Text theme={theme}>{isFirstLoaded?t('this-is-used-to-unlock-your-wallet'):t('Enter your existing password')}</Text>
        <FormContainer onSubmit={handleRestore}>
        <Input
              theme={theme}
              placeholder={t('Account Name')}
              type="text"
              value={accountName}
              onChange={(e) => setAccountName(e.target.value)}
              style={{borderColor: accountName.length>10 ? 'red' : ''} }
            />
            {accountName.length>10 ? (
          <Text style={{ color: 'red', margin: '0' }}>name too long</Text>
        ) : null}
          <Input
            theme={theme}
            placeholder={t('password')}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Show when={isFirstLoaded}>
          <Input
            theme={theme}
            placeholder={t('confirm-password-0')}
            type="password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            style={{ marginBottom: '2rem' }}
          />
          </Show>
          <Button theme={theme} type="primary" label={t('finish')} isSubmit disabled={accountName.length>10 ? true:false} />
          <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => setStep(2)} />
        </FormContainer>
      </Content>
    </>
  );

  const enterSeedStep = (
    <>
      <Content>
        <HeaderText theme={theme} style={{marginTop:'4rem'}}>{getRestoreTitle()}</HeaderText>
        <Text theme={theme} style={{ marginBottom: '1rem', width: '90%' }}>
          {getRestoreDescription()}
        </Text>
        <FormContainer onSubmit={() => setStep(3)}>
          <SeedInput
            theme={theme}
            placeholder={t('enter-secret-recovery-words')}
            onChange={(e) => setSeedWords(e.target.value.trim())}
            $isExpert={isExpertImport}
          />
          <Show when={isExpertImport}>
            <Input
              theme={theme}
              placeholder={t('wallet-derivation-ex-m-44-236-0-1-0')}
              type="text"
              value={walletDerivation ?? ''}
              onChange={(e) => setWalletDerivation(e.target.value)}
              style={{ margin: '0.1rem', width: '85%' }}
            />
          </Show>
          <Show when={!importWallet}>
            <ExpertImportWrapper>
              <ToggleSwitch theme={theme} on={isExpertImport} onChange={handleExpertToggle} />
              <Text theme={theme} style={{ margin: '0 0 0 0.5rem', textAlign: 'left' }}>
                {t('use-custom-derivations')} </Text>
            </ExpertImportWrapper>
          </Show>
          <Text theme={theme} style={{ margin: '1rem 0 1rem' }}>
            {t('make-sure-you-are-in-a-safe-place-and-no-one-is-watching')} </Text>
          <Button theme={theme} type="primary" label={t('next')} isSubmit disabled={!seedWords} />
          <Button theme={theme} type="secondary" label={t('Go back')} onClick={() =>{
            if(isExpertImport)
            {handleExpertToggle();}
            setStep(1)} } />
        </FormContainer>
      </Content>
    </>
  );
  //这里是修改恢复的时候的项目名称
  const availableWallets = (wallets: (SupportedWalletImports | undefined)[]) => {
    return wallets.map((wallet) => {
      return (
        <WalletRow
          key={window.crypto.randomUUID()}
          onClick={() => handleWalletSelection(wallet)}
          element={
            <>
              <Show when={wallet === 'turings'}>
                <WalletWrapper>
                  <TuringsWalletContainer theme={theme}>
                    <WalletLogo src={turingsWhiteLogo}  />
                  </TuringsWalletContainer>
                  <WalletText theme={theme}>{t('TBC-with-mnemonic')}</WalletText>
                </WalletWrapper>
              </Show>
              <Show when={wallet === 'tp'}>
                <WalletWrapper>
                    <WalletLogo src={turingsWhiteLogo} />
                  <WalletText theme={theme}>{t('tp-with-mnemonic')}</WalletText>
                </WalletWrapper>
              </Show>
              <Show when={wallet === 'nabox'}>
                <WalletWrapper>
                  <WalletLogo src={turingsWhiteLogo} />
                  <WalletText theme={theme}>{t('nabox-with-mnemonic')}</WalletText>
                </WalletWrapper>
              </Show>
              <Show when={wallet === 'okx'}>
                <WalletWrapper>
                  <WalletLogo src={turingsWhiteLogo} />
                  <WalletText theme={theme}>{t('okxweb3-with-mnemonic')}</WalletText>
                </WalletWrapper>
              </Show>
              <Show when={!wallet}>
                <WalletWrapper>
                  <WalletLogo src={otherWallet} />
                  <WalletText theme={theme}>{t('other')}</WalletText>
                </WalletWrapper>
              </Show>
              <Show when={wallet === 'wif'}>
                <WalletWrapper>
                  <WalletLogo src={wifWallet} />
                  <WalletText theme={theme}>{t('restore-with-private-key')}</WalletText>
                </WalletWrapper>
              </Show>
              <Show when={wallet === 'taproot'}>
                <WalletWrapper>
                  <WalletLogo src={wifWallet} />
                  <WalletText theme={theme}>{t('restore-taproot-key')}</WalletText>
                </WalletWrapper>
              </Show>
            </>
          }
        />
      );
    });
  };

  const selectMnemonicOrWif = (
    <>
      <Content>
        <TopLogo src={toplogo} />
        <div style={{ marginBottom: "30px", marginTop: "30px" }}>
          <HeaderText theme={theme}>{t('restore-a-wallet')} </HeaderText>
          <Text theme={theme} style={{ marginBottom: '1rem', width: '100%' }}>
            {t('select-the-wallet-youd-like-to-restore-from')} </Text>
        </div>
        <WalletRow
          key='mnemonic'
          onClick={() => {setImportType('mnemonic');setStep(1)}}
          element={
                <WalletWrapper>
                  <TuringsWalletContainer theme={theme}>
                    <WalletLogo src={turingsWhiteLogo}  />
                  </TuringsWalletContainer>
                  <WalletText theme={theme}>{t('with-mnemonic')}</WalletText>
                </WalletWrapper>
          }
        />
        <WalletRow
          key='wif'
          onClick={() => {setImportType('wif');setStep(1)}}
          element={
                <WalletWrapper>
                  <TuringsWalletContainer theme={theme}>
                    <WalletLogo src={wifWallet}/>
                  </TuringsWalletContainer>
                  <WalletText theme={theme}>{t('with-wif')}</WalletText>
                </WalletWrapper>
          }
        />
        <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => (isFirstLoaded ? navigate('/') : onNavigateBack('manage-accounts'))} />
      </Content>
    </>
  );

  const selectImportWallet = (
    <>
      <Content style={{overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none'}}>
        <TopLogo src={toplogo} />
        <div style={{ marginBottom: "15px", marginTop: "30px" }}>
          <HeaderText theme={theme}>{t('restore-a-wallet')} </HeaderText>
          <Text theme={theme} style={{ marginBottom: '1rem', width: '100%' }}>
            {t('select-the-wallet-youd-like-to-restore-from')} </Text>
        </div>
        {importType==='mnemonic'&&availableWallets(['turings','tp','okx','nabox',undefined])}
        {importType==='wif'&&availableWallets(['wif','taproot'])}
        <Button theme={theme} type="secondary" label={t('Go back')} onClick={() =>{setImportType(null);setStep(0)}} />
      </Content>
    </>
  );

  const successStep = (
    <>
      <Content style={{marginTop:'4rem'}}>
        <BigCoinLogo src={turingsLogo} />
        <div style={{ marginTop: "50px", marginBottom: "40px" }}>
          <HeaderText theme={theme}>{t('success')}!</HeaderText>
          <Text theme={theme} style={{ marginBottom: '1rem' }}>
            {t('your-wallet-has-been-restored')} </Text>
        </div>
        <Button
          theme={theme}
          type="primary"
          label={t('enter')}
          onClick={() => {
            window.location.reload();
          }}
        />
      </Content>
    </>
  );

  return (
    <>
      <Show when={loading}>
        <PageLoader theme={theme} message={t('restoring-wallet')} />
      </Show>
      <Show when={!loading && step === 0}>{selectMnemonicOrWif}</Show>
      <Show when={!loading && step === 1}>{selectImportWallet}</Show>
      <Show when={!loading && step === 2}>{enterSeedStep}</Show>
      <Show when={!loading && step === 3}>{passwordStep}</Show>
      <Show when={!loading && step === 4}>{successStep}</Show>
    </>
  );
};
