import { styled } from 'styled-components';
import { useTheme } from '../hooks/useTheme';
import { ColorThemeProps } from '../theme';
import { HeaderText, Text } from './Reusable';
import { formatNumberWithCommasAndDecimals, formatUSD } from '../utils/format';
import { Show } from './Show';
import { useTranslation } from 'react-i18next';

const Container = styled.div<ColorThemeProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.darkAccent};
  padding: 1rem 0;
  width: 90%;
  border-radius: 0.5rem;
  margin: 0.25rem;
`;

const Icon = styled.img<{ size?: string }>`
  width: 2.25rem;
  height: 2.25rem;
  margin-left: 1rem;
  border-radius: 50%;
`;

const TickerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TickerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1rem;
`;

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 1rem;
  width: 40%;
`;

export type AssetRowProps = {
  icon: string;
  ticker: string;
  balance: number;
  exchangeRate?:number;
  usdBalance?: number;
  isTBCUSD?: boolean;
  changePercent?:number
};

export const AssetRow = (props: AssetRowProps) => {
  const { icon, ticker, balance, usdBalance,exchangeRate,changePercent } = props;
  const { theme } = useTheme();
  const { t } = useTranslation();
  return (
    <Container theme={theme}>
      <TickerWrapper>
        <Show when={!!icon && icon.length > 0}>
          <Icon src={icon} />
        </Show>
        <TickerTextWrapper>
          <HeaderText style={{ fontSize: '1rem' }} theme={theme}>
            {ticker}
          </HeaderText>
          <div style={{display:'flex'}}>
          {exchangeRate&&<Text
          style={{
            margin: '0.1rem 0 0 0',
            textAlign: 'left',
          }}
          theme={theme}
        >
          {formatUSD(exchangeRate) } 
        </Text>}
        {changePercent?<Text
          style={{
            margin: '0.15rem 0 0 0.3rem',
            textAlign: 'left',
            fontSize:'0.75rem',
            color: changePercent > 0 ? 'rgba(206,241,76)' : changePercent < 0 ? 'rgba(187,105,151)' : 'white', // 根据changePercent设置颜色
          }}
          theme={theme}
        >
          {(changePercent * 100).toFixed(2) + '%' } 
        </Text>:
        <Text
        style={{
          margin: '0',
          textAlign: 'left',
        }}
        theme={theme}
      >
        {t('Balance')} 
      </Text>}
      </div>
        </TickerTextWrapper>
      </TickerWrapper>
      <BalanceWrapper>
        <HeaderText style={{ textAlign: 'right', fontSize: '1rem' }} theme={theme}>
        {`${formatNumberWithCommasAndDecimals(balance, 6)}`}
        </HeaderText>
        <Text style={{ textAlign: 'right', margin: '0' }} theme={theme}>
          {exchangeRate ? formatUSD(balance * exchangeRate):''}
        </Text>
      </BalanceWrapper>
    </Container>
  );
};
