import React, { createContext, useEffect } from 'react';
import { useNoApprovalLimitSetting } from '../hooks/useApprovalLimitSetting';
import { useTbc } from '../hooks/useTbc';
import { useTBChainPyPool } from '../hooks/useTBChainPyPool';
import { useKeys } from '../hooks/useKeys';
import { useNetwork } from '../hooks/useNetwork';
import { TBC20Data, OrdinalData, useOrds } from '../hooks/useOrds';
import { usePasswordSetting } from '../hooks/usePasswordSetting';
import { useWalletLockState } from '../hooks/useWalletLockState';
import { TBC_DECIMAL_CONVERSION } from '../utils/constants';
import { Keys } from '../utils/keys';
import { NetWork } from '../utils/network';
import { storage } from '../utils/storage';
import { useAccounts } from '../hooks/useAccounts';

export interface Web3ContextProps {
  network: NetWork;
  ordinals: OrdinalData;
  tbc20s: TBC20Data;
  isPasswordRequired: boolean;
  noApprovalLimit: number | undefined;
  exchangeRate: number;
  updateNetwork: (n: NetWork) => void;
  updateNoApprovalLimit: (amt: number) => void;
  updatePasswordRequirement: (passwordSetting: boolean) => void;
}

export const Web3Context = createContext<Web3ContextProps | undefined>(undefined);

interface Web3ProviderProps {
  children: React.ReactNode;
}
export const Web3Provider = (props: Web3ProviderProps) => {
  const { children } = props;
  const { isLocked } = useWalletLockState();
  const { tbcAddress, tbcBalance, exchangeRate } =
    useTbc();
  const { updateStorage, getStorage } = useAccounts();
  const { ordinals, tbc20s } = useOrds();
  const { retrieveKeys } = useKeys();
  const { setDerivationTags } = useTBChainPyPool();
  const { network, setNetwork } = useNetwork();
  const { isPasswordRequired, setIsPasswordRequired } = usePasswordSetting();
  const { noApprovalLimit, setNoAprrovalLimit } = useNoApprovalLimitSetting();

  // useEffect(() => {
  //   // Here we are pulling in any new Utxos unaccounted for.
  //   if (tbcAddress) {
  //     // setTimeout(() => {
  //     //   updateTbcBalance(true);
  //     // }, 1500);
  //   }

  //   // if (ordAddress) {
  //   //   setTimeout(() => {
  //   //     getOrdinals(0);
  //   //   }, 1500);
  //   // }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tbcAddress, ordAddress]);

    // if (isLocked) {
    //   storage.remove('appState');
    //   return;
    // }

    useEffect(() => {
      // Async function to handle key retrieval and derivation tag setting
      if(!tbcAddress||isLocked) return;
      (async () => {
        try {
          const keys = (await retrieveKeys(undefined, true)) as Keys;
          if (keys.identityAddress && keys.identityWif) {
            await setDerivationTags(keys.identityAddress, keys);
          }
        } catch (error) {
          console.error("Error retrieving keys or setting derivation tags:", error);
        }
      })();
    
      // 计算余额
      const balance = {
        tbc: tbcBalance,
        satoshis: Math.round(tbcBalance * TBC_DECIMAL_CONVERSION),
        usdInCents: Math.round(tbcBalance * exchangeRate * 100),
      };
    
      // 获取当前存储的账户数据
      getStorage()
        .then(storageData => {
          const accounts = storageData.accounts || {};
          const selectedAccount = storageData.selectedAccount;
          if (!selectedAccount || !accounts[selectedAccount]) {
            return;
          }
          // 获取并更新对应tbcAddress的账户余额
          const account = accounts[selectedAccount];
          const updatedAccount = {
            ...account,
            balance: balance,
          };
          // 更新账户数据
          accounts[selectedAccount] = updatedAccount;
          // 调用updateStorage函数更新存储
          updateStorage({ accounts: accounts })
        })
        .catch(error => {
          console.error("Error getting storage data:", error);
        });
    },[
    tbcAddress,
    tbcBalance,
    exchangeRate,
    isPasswordRequired,
    retrieveKeys,
    setDerivationTags,
  ]);

  const updateNetwork = (n: NetWork): void => {
    storage.set({
      network: n,
    });
    // storage.set({ paymentUtxos: [] });
    setNetwork(n);
  };

  const updatePasswordRequirement = (isRequired: boolean): void => {
    storage.set({ isPasswordRequired: isRequired });
    setIsPasswordRequired(isRequired);
  };

  const updateNoApprovalLimit = (amt: number) => {
    storage.set({ noApprovalLimit: amt });
    setNoAprrovalLimit(amt);
  };

  return (
    <Web3Context.Provider
      value={{
        network,
        updateNetwork,
        ordinals,
        tbc20s,
        updatePasswordRequirement,
        isPasswordRequired,
        noApprovalLimit,
        updateNoApprovalLimit,
        exchangeRate,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
