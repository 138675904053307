import validate from 'bitcoin-address-validation';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BackButton } from '../components/BackButton';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { Ordinal } from '../components/Ordinal';
import { PageLoader } from '../components/PageLoader';
import { useViewport } from '../hooks/useViewport';
import { QrCode } from '../components/QrCode';
import { useKeys } from '../hooks/useKeys';
import {
  ButtonContainer,
  ConfirmContent,
  FormContainer,
  HeaderText,
  ReceiveContent,
  SubHeaderText,
  Text,
} from '../components/Reusable';
import { Show } from '../components/Show';
import Tabs from '../components/Tabs';
import { NFTTxo, COLLECTIONTxo, TBC20Txo } from '../hooks/ordTypes';
import { useBottomMenu } from '../hooks/useBottomMenu';
import { useTbc } from '../hooks/useTbc';
import { getTokenName, ListOrdinal, OrdOperationResponse, useOrds, FThistoryData, NFThistoryData } from '../hooks/useOrds';
import { useSnackbar } from '../hooks/useSnackbar';
import { useTheme } from '../hooks/useTheme';
import { useWeb3Context } from '../hooks/useWeb3Context';
import { TBC_DECIMAL_CONVERSION, PAGE_SIZE } from '../utils/constants';
import { showAmount } from '../utils/ordi';
import { sleep } from '../utils/sleep';
import { TBC20Id } from '../components/TBC20Id';
import copyIcon from '../assets/copy-green.svg';
import circleplus from '../assets/circleplus.svg';
import leftArrow from '../assets/left-arrow.svg';
import rightArrow from '../assets/right-arrow.svg';
import noCollection from '../assets/collection.png';
import qr from '../assets/Qr.png';
import noFT from '../assets/FT.png';
import nonft from '../assets/nonft.png';
import { TopNav } from '../components/TopNav';
import { AssetRow } from '../components/AssetRow';
import { formatNumberWithCommasAndDecimals } from '../utils/format';
import { truncate } from '../utils/format';
import { useTranslation } from 'react-i18next';


const QrImage = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 3.25rem;
  right: 1rem;
  cursor: pointer;
`;
// background-color: ${({ theme }) => theme.darkAccent};
const Container = styled.div`
  display: flex;
  align-items: center;
 
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  transition: all 0.1s ease;
  transform: scale(1);
  margin:0.5% auto;
  &:active {
    transform: scale(0.95);
    background-color: ${({ theme }) => theme.primaryButton + '50'}; /* 点击时背景颜色变亮 */
  }
`;
const ImageWrapper = styled.div<{ url: string }>`
  max-height: 100%; 
  max-width: 100%;
  min-height: 10rem;
  min-width: 10rem;
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 1.25rem;
  cursor: pointer;
  margin: 1rem  auto;
`;
const FtWrapper = styled.div<{ url: string }>`
  max-height: 100%; 
  max-width: 100%;
  height: 5rem;
  width: 5rem;
  background-image: url(${(props) => props.url});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0.75rem;
  cursor: pointer;
  margin: 1rem  auto;
  border: 2px solid #202020;  // 添加白色边框
  box-shadow: 0px 0px 8px #ffffff;  // 添加白色阴影
  background-color: #202020;  // 添加比黑色稍浅的背景色
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  
`;

const DragDropArea = styled.div<{ isActive: boolean }>`
  border: 2px dashed ${({ theme, isActive }) => isActive ? theme.darkAccent : theme.gray}; // 根据是否处于拖放状态改变边框颜色
  padding: 1rem;
  text-align: center;
  color: ${({ theme }) => theme.gray};
  margin-top: 0rem;
  width: 80%;
  height: 8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.lightAccent : theme.mainBackground}; // 根据是否处于拖放状态改变背景颜色
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.primaryButton : theme.gray}; // 根据拖放状态改变边框颜色
  overflow: hidden;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out; // 添加过渡效果
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 1.2rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.primary};
  transition: background 0.2s;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }
`;

const UploadIcon = styled.div`
  font-size: 2rem; /* 设置图标大小 */
  color: ${({ theme }) => theme.primary};
  margin-bottom: 0.5rem;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%; 
  object-fit: contain; 
  max-width: 100%; 
  max-height: 100%; 
  border-radius: 10px;
  display: block;
`;

const HiddenInput = styled.input`
  display: none;
`;

const UploadText = styled.p`
  font-size: 0.9rem; /* 设置较小的字体尺寸 */
  margin: 0.25rem 0;
  color: ${({ theme }) => theme.text};
`;

const OrdinalsList = styled.div<{ $isMobile?: boolean }>`
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 100%;
  margin-top: 0.5rem;
  height: ${(props) => (props.$isMobile ? '100%' : '35rem')};
  padding-left:2.5%;
  overflow-x: hidden;
  scrollbar-width: none;
  msOverflow-style: none;
`;

const NftInList = styled.div<{ $isMobile?: boolean }>`
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  height: ${(props) => (props.$isMobile ? '90%' : '90%')};
  padding-left:2.5%;
  overflow-x: hidden;
  scrollbar-width: none;
  msOverflow-style: none;
`;

const NftList = styled.div<{ $isMobile?: boolean }>`
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: auto;
  width: 100%;
  margin-top: 0.5rem;
  height: ${(props) => (props.$isMobile ? '100%' : '35rem')};
  padding-left:2.5%;
  overflow-x: hidden;
  scrollbar-width: none;
  msOverflow-style: none;
`;

const TBC20List = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-top: 0.5rem;
  height: 95%;
  scrollbar-width: none;
  msOverflow-style: none;
`;

const NoInscriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  width: 100%;
`;

export const CheckBox = styled.div`
  margin: 0.5rem 0.5rem;
`;

const ContentWrapper = styled.div`
  width: 100%;
`;

const ScrollableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto; 
  overflow-x: hidden;
  width: 100%;
  scrollbar-width: none;
  msOverflow-style: none;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0rem;
  width: 100%;
  height: calc(100% - 6rem);
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none;
  msOverflow-style: none;
`;

const DetailContainer = styled.div`
  width: 90%;
  background-color: ${({ theme }) => theme.darkAccent};
  margin-left: 1.25rem ;
  border-radius: 1rem;
  margin-top: 0.75rem;
`;

const DetailText = styled.p`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.gray ?? theme.white};
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin:0 0 0 0.5rem;
  text-align: left;
  width: 100%;
`;

const TransferTBC20Header = styled(HeaderText)`
  overflow: hidden;
  max-width: 16rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
`;

export const OrdButtonContainer = styled(ButtonContainer)`
  margin: 0.5rem 0 0.5rem 0;
  position: fixed;
  bottom: 4.5rem;
`;

export const TBC20Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 1rem;
`;

const TokenIcon = styled.img`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  object-fit: cover;
`;

const Balance = styled(Text)`
  font-size: 0.85rem;
  white-space: pre-wrap;
  margin: 0;
  width: fit-content;
  cursor: pointer;
  text-align: center;
  width: 100%;
`;

const TBC20Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 80%;
  margin: 0 0 0.75rem 0;
  padding: 0 0;
`;

const CopyAddressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 2rem 0;
`;

const StyledCopy = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
`;

const CopyWrapper = styled.div`
  text-align: left;
  padding: 0.5rem;
  cursor: pointer;
`;
// width: ${props => props.width || '75px'}
// height:${props => props.width || '75px'}; /* 设置缩略图的高度 */
const NFTThumbnail = styled.img`
  width:75px;
  height:75px; /* 设置缩略图的高度 */
  margin-right: 1rem; /* 设置缩略图和文本之间的间距 */
  padding: 0 0.5rem;
  object-fit: contain; /* 确保图片保持宽高比 */
  border-radius: 10px; /* 图片圆角效果 */
`;
const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); // 两列
  grid-gap: 10px; // 设置网格之间的间隔
  width: 100%;
  height: 100%;
`;

const TransactionRow = styled.div`
  display: flex;
  align-items: center;
  width: 90%;
  background-color: ${({ theme }) => theme.darkAccent};
  margin: 0.3rem ;
  height: 4rem; /* 自动高度 */
  padding: 0.5rem; /* 添加一些内边距 */
  border-radius: 0.5rem;
  transition: all 0.3s ease;
  transform: scale(1);

  &:active {
    transform: scale(0.95);
    background-color: ${({ theme }) => theme.primaryButton + '50'}; /* 点击时背景颜色变亮 */
  }
`;

const LockDetailsText = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.white};
  width: 100%;
  font-size: 0.85rem;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  text-align: center;
`;

type PageState = 'main' | 'receive' | 'transfer' | 'list' | 'cancel' | 'sendTBC20' | 'createNFT' | 'collection' | 'createCollection' | 'nft' | 'nftDetails' | 'nftDetailsInColl' | 'collectionContent' | 'addft'
  | 'nftbyline' | 'ftDetail' | 'ftHistory' | 'nftHistory' | 'ftHistoryDetail' | 'nftHistoryDetail' | 'receiveft';

interface Token {
  isConfirmed: boolean;
  info: TBC20Txo;
}

export const OrdWallet = () => {
  const { theme } = useTheme();
  const { setSelected, hideMenu, showMenu } = useBottomMenu();
  const { verifyPassword, tbcAddress } = useKeys();
  const [pageState, setPageState] = useState<PageState>('main');
  const {
    ordinals,
    nftsInCollection,
    collections,
    fts,
    ftHistory,
    nftHistory,
    getFtHistory,
    getNftHistory,
    getOrdinals,
    getFts,
    isProcessing,
    nftCount,
    OrdCount,
    collectionCount,
    ftCount,
    ftHistoryCount,
    nftHistoryCount,
    IssueNFT,
    addFT,
    createCollection,
    getCollection,
    transferNFT,
    getNftbycollection,
    setIsProcessing,
    getOrdinalsBaseUrl,
    sendFtToMulti,
    transferFT,
    listOrdinalOnGlobalOrderbook,
    cancelGlobalOrderbookListing,
  } = useOrds();
  const [selectedOrdinal, setSelectedOrdinal] = useState<NFTTxo | undefined>();
  const [selectedCollection, setSelectedCollection] = useState<COLLECTIONTxo | undefined>();
  const [tabIndex, selectTab] = useState(0);
  const [ordinalOutpoint, setOrdinalOutpoint] = useState('');
  const [receiveAddress, setReceiveAddress] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [tbcListAmount, setTbcListAmount] = useState<number | null>();
  const [borderColor, setBorderColor] = useState('');
  const [successTxId, setSuccessTxId] = useState('');
  const { addSnackbar, message } = useSnackbar();
  const { isPasswordRequired, exchangeRate } = useWeb3Context();
  const { isMobile } = useViewport();
  const [token, setToken] = useState<Token | null>(null);
  const [tokenSendAmount, setTokenSendAmount] = useState<number | null>(null);
  const [priceData, setPriceData] = useState<{ id: string; satPrice: number }[]>([]);

  const { updateTbcUtxos } = useTbc();  // 从 hook 获取 NFT 发行函数和处理状态
  const [nftName, setNftName] = useState('');
  const [nftSymbol, setNftSymbol] = useState('');
  const [ftAddress, setFtAddress] = useState('');
  const [description, setDescription] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [collectionSupply, setCollectionSupply] = useState<number>(101);
  const [file, setFile] = useState<File | null>(null);  // 文件上传
  const [previewSrc, setPreviewSrc] = useState<string>('');  // 用于显示文件预览
  const [dragActive, setDragActive] = useState(false);                // 用于处理拖放状态
  const [error, setError] = useState<string | null>(null);            // 错误信息状态
  const [currentNftPage, setCurrentNftPage] = useState(0); // 当前页码
  const [currentColPage, setCurrentColPage] = useState(0); // 当前页码
  const [currentPage, setCurrentPage] = useState(0); // 当前页码
  const [currentHistoryPage, setCurrentHistoryPage] = useState(0); // 当前页码
  const [blockDisplay, setBlockDisplay] = useState(true);
  const [ftSelectedTransaction, setFtSelectedTransaction] = useState<FThistoryData | null>(null);
  const [nftSelectedTransaction, setNftSelectedTransaction] = useState<NFThistoryData | null>(null);
  const { t } = useTranslation();

  // useEffect(() => {
  //   if (!tbc20s.data.length) return;
  //   (async () => {
  //     const data = await getTokenPriceInSats(tbc20s.data.map((d) => d.id));
  //     setPriceData(data);
  //   })();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [tbc20s]);

  useEffect(() => {
    setSelected('ords');
  }, [setSelected]);

  useEffect(() => {
    getCollection(currentNftPage);
    if (selectedCollection?.collectionId) {
      getNftbycollection(selectedCollection.collectionId, currentNftPage);
    }
  }, [currentNftPage]);

  useEffect(() => {
    getCollection(currentColPage);
  }, [currentColPage]);

  useEffect(() => {
    getOrdinals(currentPage, true);
  }, [currentPage]);

  useEffect(() => {
    if (pageState === 'nftHistory') {
      getNftHistory(currentHistoryPage, true);
    }
    if (pageState === 'ftHistory' && token) {
      getFtHistory(token.info.ftContractId, currentHistoryPage, true);
    }
  }, [currentHistoryPage]);
  // useEffect(() => {
  //   if (ordAddress) {
  //     //getOrdinals(fasle);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [ordAddress]);

  useEffect(() => {
    if (!successTxId) return;
    // if (!message) {
    if (pageState === 'createCollection') {
      resetCollectionState();
      setPageState('main');
    }
    if (pageState === 'createNFT') {
      resetCollConState();
      setPageState('collectionContent');
    }
    if (pageState === 'transfer') {
      resetSendState();
      setPageState('main');
    }
    if (pageState === 'sendTBC20') {
      resetSendState();
      setPageState('main');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successTxId, message]);

  const resetSendState = () => {
    setReceiveAddress('');
    setPasswordConfirm('');
    setSuccessTxId('');
    setTbcListAmount(undefined);
    setIsProcessing(false);
    setTokenSendAmount(null);
    if(pageState === 'sendTBC20'){hideMenu();}
    else{showMenu();}
    setTimeout(() => {
      getOrdinals(0, false);
    }, 500);
  };

  const resetCollectionState = () => {
    setPasswordConfirm('');
    setSuccessTxId('');
    setTbcListAmount(undefined);
    setCollectionName('');
    setCollectionSupply(101);
    setDescription('');
    setFile(null);
    setIsProcessing(false);
    setTimeout(() => {
      getCollection(0, false);
    }, 500);
  };
  const resetCollConState = () => {
    setPasswordConfirm('');
    setSuccessTxId('');
    setIsProcessing(false);
    setFile(null);
    setNftSymbol('');
    // setTimeout(() => {
    //   if (selectedCollection?.collectionId) {
    //     getNftbycollection(selectedCollection.collectionId, 0, false);
    //   }
    // }, 500);
  };

  const generatePlaceholderIcon = (text: string) => {
    const canvas = document.createElement('canvas');
    const size = 64; // 图标尺寸
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext('2d');

    if (context) {
      // 清空画布
      context.clearRect(0, 0, size, size);

      // 绘制圆形背景
      context.fillStyle = theme.black; // 黑色背景
      context.beginPath();
      context.arc(size / 2, size / 2, size / 2, 0, Math.PI * 2);
      context.closePath();
      context.fill();

      // 绘制白色文字
      context.fillStyle = theme.white; // 白色文字
      context.font = 'bold 32px Arial'; // 字体设置
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(text.charAt(0).toUpperCase(), size / 2, size / 2); // 绘制首字母
    }

    return canvas.toDataURL('image/png'); // 返回数据 URL
  };
  const getErrorMessage = (response: OrdOperationResponse) => {
    return response.error === 'invalid-password'
      ? t('invalid-password')
      : response.error === 'no-keys'
        ? t('no-keys-were-found')
        : response.error === 'insufficient-funds'
          ? t('insufficient-funds')
          : response.error === 'fee-too-high'
            ? t('miner-fee-too-high')
            : response.error === 'no-tbc20-utxo'
              ? t('no-tbc20-token-found')
              : response.error === 'token-details'
                ? t('could-not-gather-token-details')
                : response.error === 'no-ord-utxo'
                  ? t('could-not-locate-the-ordinal')
                  : response.error === 'broadcast-error'
                    ? t('there-was-an-error-broadcasting-the-tx')
                    : t('an-unknown-error-has-occurred-try-again');
  };

  const handleTransferOrdinal = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);

    await sleep(25);
    if (!validate(receiveAddress)) {
      addSnackbar(t('you-must-enter-a-valid-address'), 'info');
      setIsProcessing(false);
      return;
    }

    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      setIsProcessing(false);
      return;
    }

    //const transferRes = await transferOrdinal(receiveAddress, ordinalOutpoint, passwordConfirm);
    if (!selectedOrdinal) {
      addSnackbar(t('no-nft-selected'), 'error');
      setIsProcessing(false);
      return;
    }

    const transferRes = await transferNFT(selectedOrdinal, receiveAddress, passwordConfirm);

    if (!transferRes.txid || transferRes.error) {
      const errorMessage = getErrorMessage(transferRes);
      addSnackbar(errorMessage, 'error');
      setIsProcessing(false);
      return;
    }
    addSnackbar(t('transfer-successful'), 'success');
    setSuccessTxId(transferRes.txid);
  };


  const handleListOrdinal = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);

    await sleep(25);
    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      setIsProcessing(false);
      return;
    }

    if (Number(tbcListAmount) < 0.00000001) {
      addSnackbar('Must be more than 1 sat', 'error');
      setIsProcessing(false);
      return;
    }

    const listing: ListOrdinal = {
      outpoint: ordinalOutpoint,
      password: passwordConfirm,
      price: Math.ceil(tbcListAmount! * TBC_DECIMAL_CONVERSION),
    };

    const listRes = await listOrdinalOnGlobalOrderbook(listing);

    if (!listRes.txid || listRes.error) {
      const errorMessage = getErrorMessage(listRes);
      addSnackbar(errorMessage, 'error');
      setIsProcessing(false);
      return;
    }

    setSuccessTxId(listRes.txid);
    addSnackbar('Listing Successful!', 'success');
  };

  const handleCancelListing = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);

    await sleep(25);
    if (!passwordConfirm && isPasswordRequired) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      setIsProcessing(false);
      return;
    }

    const cancelRes = await cancelGlobalOrderbookListing(ordinalOutpoint, passwordConfirm);

    if (!cancelRes.txid || cancelRes.error) {
      const errorMessage = getErrorMessage(cancelRes);
      addSnackbar(errorMessage, 'error');
      setIsProcessing(false);
      return;
    }

    setSuccessTxId(cancelRes.txid);
    addSnackbar('Successfully canceled the listing!', 'success');
  };

  const handleSendTBC20 = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);

    await sleep(25);

    if (!passwordConfirm) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      setIsProcessing(false);
      return;
    }

    const isVerified = await verifyPassword(passwordConfirm);
    if (!isVerified) {
      addSnackbar(t('invalid-password'), 'error');
      setIsProcessing(false);
      return;
    }

    if (token === null || tokenSendAmount === null) {
      setIsProcessing(false);
      return;
    }

    if (token.info.ftDecimal > 18) {
      addSnackbar(t('the-maximum-value-for-decimal-cannot-exceed-18'), 'error');
      setIsProcessing(false);
      return
    }

    if (tokenSendAmount > Math.pow(10, 18 - token.info.ftDecimal)) {
      addSnackbar(t('the-maximum-amount-exceed'), 'error');
      setIsProcessing(false);
      return;
    }

    if (receiveAddress.length !== 34 && receiveAddress.length !== 33) {
      addSnackbar(t('you-must-enter-a-valid-address'), 'info');
      setIsProcessing(false);
      return;
    } else if (!receiveAddress.startsWith("1")) {
      const sendFtToMultiRes = await sendFtToMulti(token.info.ftContractId, receiveAddress, tokenSendAmount, passwordConfirm);
      if (sendFtToMultiRes.error) {
        // if (!sendRes.txid ) {
        const message = 'Failed to send'
        addSnackbar(message, 'error');
        setPasswordConfirm('');
        return;
      }
      setIsProcessing(false);
      setSuccessTxId('1');
      addSnackbar(t('transaction-successful-please-wait-a-few-minutes-for-your-balance-to-refresh'), 'success');
    }
    else if (!validate(receiveAddress)) {
      addSnackbar(t("you-must-enter-a-valid-tbc-address-paymail-not-yet-supported"), 'info');
      setIsProcessing(false);
      return;
    } else {
      const sendTBC20Res = await transferFT(token.info.ftContractId, receiveAddress, tokenSendAmount, passwordConfirm);

      if (!sendTBC20Res.txid || sendTBC20Res.error) {
        const message = getErrorMessage(sendTBC20Res);
        addSnackbar(message, 'error');
        return;
      }

      setSuccessTxId(sendTBC20Res.txid);
      addSnackbar(t('tokens-sent'), 'success');
    }
  };

  const handleCopyToClipboard = (value: string | number) => {
    const textToCopy = typeof value === 'number' ? value.toString() : value;
    navigator.clipboard.writeText(textToCopy).then(() => {
      addSnackbar('Copied!', 'success');
    });
  };

  const userSelectedAmount = (inputValue: string, token: Token) => {
    // const amtStr = normalize(inputValue, token.info.ftDecimal);
    const amt = Number(inputValue);
    setTokenSendAmount(amt);
    const total = Number(token.info.ftBalance / Math.pow(10, token.info.ftDecimal));
    if (amt > total) {
      setTimeout(() => {
        setTokenSendAmount(total);
      }, 500);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files[0]);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragActive(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragActive(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setDragActive(false);
    const selectedFile = e.dataTransfer.files?.[0];
    handleFile(selectedFile);
  };

  const handleFile = (selectedFile: File | null) => {
    const MAX_FILE_SIZE_MB = 1; // 最大文件大小 1MB
    const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024; // 1MB 转换为字节

    if (selectedFile) {
      // 检查文件大小是否超过限制
      if (selectedFile.size > MAX_FILE_SIZE_BYTES) {
        setError(t('file-size-exceeds-limit'));
        return;
      }

      // 检查文件类型是否为图片
      if (!selectedFile.type.startsWith('image/')) {
        setError(t('please-select-a-valid-image-file-jpg-png-gif-etc'));
        return;
      }

      setFile(selectedFile);
      setError(null);

      // 生成文件预览 URL
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setPreviewSrc(fileReader.result as string);
      };
      fileReader.readAsDataURL(selectedFile);
    }
  };

  // 将文件转换为 Base64 编码
  const fileToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        // const base64String = reader.result as string;
        // console.log("Base64 encoded string length (in characters):", base64String.length);

        // // Convert the character length to byte size (approximate, 1 character = 1 byte)
        // const byteSize = (base64String.length * (3 / 4)); // Base64 encoding increases size by ~33%, 3/4 of the length is original size
        // console.log("Approximate byte size of the Base64 encoded image:", byteSize);

        // // Log the size in KB for better readability
        // console.log("Approximate size in KB:", (byteSize / 1024).toFixed(2));
        resolve(reader.result as string);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleIssueNFT = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);
    if (!file) {
      addSnackbar(t('please-upload-a-file'), 'error');
      setIsProcessing(false);
      return;
    }
    // 定义正则表达式，仅允许英文字符、数字和空格
    const validPattern = /^[a-zA-Z0-9\s]*$/;
    //const validPattern = /^[a-zA-Z0-9\s!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]*$/;
    // // 检查 NFT 名称和符号是否包含非法字符
    // if (!validPattern.test(nftName)) {
    //   addSnackbar('NFT Name contains invalid characters. Please use only English letters and numbers.', 'error');
    //   setIsProcessing(false);
    //   return;
    // }

    if (nftSymbol && !validPattern.test(nftSymbol)) {
      addSnackbar(t('symbol-contains-invalid-characters-please-use-only-english-letters-and-numbers'), 'error');
      setIsProcessing(false);
      return;
    }
    if (!passwordConfirm) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      setIsProcessing(false);
      return;
    }
    // 自动处理 UTXO，并调用发行 NFT 交易的函数
    if (!selectedCollection) {
      addSnackbar(t('no-collection-selected'), 'error');
      setIsProcessing(false);
      return;
    }

    // if(selectedCollection.usedNum>=selectedCollection.collectionSupply){
    //   addSnackbar(t('nft-quantity-reached-the-upper-limit'), 'error');
    //   setIsProcessing(false);
    //   return;
    // }
    const isVerified = await verifyPassword(passwordConfirm);//这里是等待确认密码的地方
    if (!isVerified) {
      addSnackbar(t('invalid-password'), 'error');
      setIsProcessing(false);
      return;
    }

    let base64File = '';
    try {
      base64File = await fileToBase64(file);
    } catch (error) {
      addSnackbar(t('failed-to-process-file'), 'error');
      setIsProcessing(false);
      return;
    }

    const nftData = {
      nftName: nftName,
      symbol: nftSymbol,
      collectionName: selectedCollection?.collectionName,
      // index:selectedCollection.usedNum+1,
      transTime: 0,
      file: base64File,
    };
    const NftRes = await IssueNFT(selectedCollection.collectionId, nftData, passwordConfirm);
    setPasswordConfirm('');
    if (NftRes.error) {
      addSnackbar(`${t('error-issuing-nft')}:${NftRes.error}`, 'error');
      setIsProcessing(false);
      return;
    }
    if (!NftRes.txid) {
      addSnackbar(t('an-error-occurred-while-sending-tbc-please-try-again'), 'error');
      setIsProcessing(false);
      return;
    }

    setSuccessTxId(NftRes.txid);
    addSnackbar(t('nft-issued-successfully'), 'success');
    setIsProcessing(false);
  };

  const handleCreateCollection = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);
    if (!collectionName || !file) {
      addSnackbar(t('please-enter-collection-name-and-upload-a-picture-file'), 'error');
      setIsProcessing(false);
      return;
    }
    // 定义正则表达式，仅允许英文字符、数字和空格
    const validPattern = /^[a-zA-Z0-9\s]*$/;

    // 检查 NFT 名称和符号是否包含非法字符
    if (!validPattern.test(collectionName)) {
      addSnackbar(t('collection-name-contains-invalid-characters-please-use-only-english-letters-and-numbers'), 'error');
      setIsProcessing(false);
      return;
    }

    if (!validPattern.test(description)) {
      addSnackbar(t('description-contains-invalid-characters-please-use-only-english-letters-and-numbers'), 'error');
      setIsProcessing(false);
      return;
    }

    if (!passwordConfirm) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      setIsProcessing(false);
      return;
    }

    const isVerified = await verifyPassword(passwordConfirm);
    if (!isVerified) {
      addSnackbar(t('invalid-password'), 'error');
      setIsProcessing(false);
      return;
    }

    let base64File = '';
    try {
      base64File = await fileToBase64(file);
    } catch (error) {
      addSnackbar(t('failed-to-process-file'), 'error');
      setIsProcessing(false);
      return;
    }

    const CollectionData = {
      collectionName: collectionName,
      description: description,
      supply: collectionSupply,
      file: base64File,
      // collectionDescription: description,
      // collectionSupply: collectionSupply,
      // collectionIcon: base64File,
    };

    const collectionRes = await createCollection(CollectionData, passwordConfirm);
    setPasswordConfirm('');
    if (collectionRes.error) {
      addSnackbar(`${t('error-creating-collection')}:${collectionRes.error}`, 'error');
      setIsProcessing(false);
      return;
    }
    if (!collectionRes.txid) {
      addSnackbar(t('an-error-occurred-while-creating-collection-please-try-again'), 'error');
      setIsProcessing(false);
      return;
    }

    setSuccessTxId(collectionRes.txid);
    addSnackbar(t('collection-created-successfully'), 'success');
    setIsProcessing(false);
  }
  const handleAddFT = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsProcessing(true);
    await sleep(25);
    if (!ftAddress) {
      addSnackbar(t('please-enter-ftaddress'), 'error');
      setIsProcessing(false);
      return;
    }
    // 定义正则表达式，仅允许 64 位十六进制字符（用于匹配比特币交易的 txid 格式）
    const validPattern = /^[a-fA-F0-9]{64}$/;

    // 检查 NFT 名称和符号是否包含非法字符
    if (!validPattern.test(ftAddress)) {
      addSnackbar(t('ftaddress-contains-invalid-characters'), 'error');
      setIsProcessing(false);
      return;
    }

    const addFTRes = await addFT(ftAddress, true);
    if (!addFTRes) {
      addSnackbar(t('an-error-occurred-while-adding-new-token-please-try-again'), 'error');
      setIsProcessing(false);
      return;
    }
    if (addFTRes.error) {
      addSnackbar(`${t('error-adding-new-tokens')}:${addFTRes.error}`, 'error');
      setIsProcessing(false);
      return;
    }
    if (!addFTRes.result) {
      addSnackbar(t('token-existed'), 'error');
      setIsProcessing(false);
      return;
    }
    addSnackbar(t('adding-new-tokens-successfully'), 'success');
    setFtAddress('');
    setIsProcessing(false);
  }


  const NFTDetailsInColl = (
    <ContentWrapper>
      <ConfirmContent>
        <HeaderText style={{ fontSize: '1.35rem' }} theme={theme}>
          {t('nft-details')} </HeaderText>
        {selectedOrdinal?.nftIcon && (
          <ImageWrapper url={selectedOrdinal.nftIcon}>
          </ImageWrapper>
        )}

        {/* 显示 NFT 详细信息 */}
        {selectedOrdinal &&
          <div style={{ textAlign: 'left', width: '100%', padding: '1rem' }}>
            <Text theme={theme} style={{ fontSize: '1rem', margin: '0.5rem 0' }}>
              <strong>{t('nft-name')}:</strong> {selectedOrdinal.nftName}
            </Text>
            <Text theme={theme} style={{ fontSize: '1rem', margin: '0.5rem 0' }}>
              <strong>{t('txid')}:</strong> {truncate(selectedOrdinal.nftUtxoId ?? '', 8, 10)}
            </Text>
            {/* <Text theme={theme} style={{ fontSize: '1rem', margin: '0.5rem 0' }}>
            <strong>Vout:</strong> {selectedOrdinal.vout}
          </Text> */}
            <Text theme={theme} style={{ fontSize: '1rem', margin: '0.5rem 0' }}>
              <strong>{t('collection')}:</strong> {truncate(selectedOrdinal.collectionName ?? '', 8, 10)}
            </Text>
            <Text theme={theme} style={{ fontSize: '1rem', margin: '0.5rem 0' }}>
              <strong>{t('symbol')}:</strong> {selectedOrdinal.nftSymbol}
            </Text>
            {selectedOrdinal.nftCreateTimestamp === 0 ? '' :
              <Text theme={theme} style={{ fontSize: '1rem', margin: '0.5rem 0' }}>
                <strong>{t('latest-transfer')}</strong> {new Date(selectedOrdinal.nftCreateTimestamp * 1000).toLocaleString(undefined, {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                  hour: '2-digit',
                  minute: '2-digit',
                  hour12: false,
                  timeZone: 'UTC',
                }).replace(',', '')}
              </Text>
            }
          </div>}

        {/* Transfer 和 List 按钮 */}
        <ButtonContainer style={{ marginTop: '1.5rem', justifyContent: 'center' }}>
          <Button
            theme={theme}
            type="primary"
            label={t('transfer')}
            onClick={() => { updateTbcUtxos(true); setPageState('transfer') }}
            style={{ margin: '0 0.5rem' }}
          />
          {/* <Button
            theme={theme}
            type="primary"
            label="List"
            onClick={() => setPageState('list')}
            style={{ margin: '0 0.5rem' }}
          /> */}
          <Button
            label={t('Go back')}
            theme={theme}
            type="secondary"
            onClick={() => {
              setPageState('collectionContent');
              setTimeout(() => {
                if (selectedCollection?.collectionId) {
                  //getNftbycollection(selectedCollection.collectionId, 0, false);
                }
              }, 500);
            }}
            style={{ margin: '0 0.5rem' }}
          />
        </ButtonContainer>
      </ConfirmContent>
    </ContentWrapper>
  );
  const NFTDetails = (
    <PageWrapper style={{ height: '93%' }}>
      <BackButton onClick={() => { showMenu(); setPageState('main') }} />
      {selectedOrdinal && (
        <div style={{
          width: '100%',
          color: theme.white,
          borderRadius: '0.5rem',
          marginTop: '2rem'
        }}>
          <Text style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: '0', marginBottom: '0.25rem', color: theme.white }}>
            {selectedOrdinal.collectionName}#{selectedOrdinal.collectionIndex}
          </Text>
          {selectedOrdinal?.nftIcon && (
            <ImageWrapper url={selectedOrdinal.nftIcon}>
            </ImageWrapper>
          )}

          <DetailContainer theme={theme}>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('holder')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(selectedOrdinal.nftHolder)}>
                <span>
                  {truncate(selectedOrdinal.nftHolder, 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('token-id')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(selectedOrdinal.nftContractId)}>
                <span>
                  {truncate(selectedOrdinal.nftContractId, 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>

            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('contract-address')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(selectedOrdinal.collectionId)}>
                <span>
                  {truncate(selectedOrdinal.collectionId, 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>

          </DetailContainer>
          <Text style={{ textAlign: 'left', margin: '0.2rem 0 0 2rem', padding: '0', fontSize: '0.9rem', fontWeight: 'bold' }}> {t('basic-info')}</Text>
          <DetailContainer theme={theme} style={{ marginTop: '0' }}>
            <div style={{ display: 'flex' }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('token-standards')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} >
                  TBC721
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem', marginLeft: 'auto', marginRight: '5rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('ordinal-index')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  {selectedOrdinal.collectionIndex}
                </DetailText>
              </CopyWrapper>
            </div>

            <div style={{ display: 'flex' }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }} >
                  {t('create-time')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  {selectedOrdinal.nftCreateTimestamp !== null
                    ? new Date(selectedOrdinal.nftCreateTimestamp * 1000).toLocaleDateString(undefined, {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      timeZone: 'UTC',
                    }).replace(',', '')
                    : t('waiting-for-chain')}
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem', marginLeft: 'auto', marginRight: '3.5rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('transfer-time')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} >
                  <span>
                    {selectedOrdinal.nftTransferTimeCount}
                  </span>
                </DetailText>
              </CopyWrapper>

            </div>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('traits')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} >
                <span>
                  {selectedOrdinal.nftSymbol}
                </span>
              </DetailText>
            </CopyWrapper>

            {selectedOrdinal.nftDescription && (<CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('description')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} >
                <span>
                  {selectedOrdinal.nftDescription}
                </span>
              </DetailText>
            </CopyWrapper>)}
          </DetailContainer>
          <DetailContainer theme={theme}>
            <Button
              theme={theme}
              type="primary"
              label={t('transfer')}
              onClick={() => { updateTbcUtxos(true); setPageState('transfer') }}
              style={{ margin: '0 0.5rem' }}
            />
          </DetailContainer>
        </div>
      )}
    </PageWrapper>
  )

  const CreateNFT = (
    <ReceiveContent style={{ overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
      <HeaderText theme={theme}>{t('create-an-nft')}</HeaderText>
      <Text theme={theme}>
        {t('provide-details-for-your-nft-and-upload-a-file')} </Text>

      <FormContainer noValidate onSubmit={handleIssueNFT}>
        {/* <Input
          theme={theme}
          placeholder="NFT Name"
          type='text'
          value={nftName}
          onChange={(e) => setNftName(e.target.value)}
        /> */}
        <Input
          theme={theme}
          placeholder={t('traits-optional')}
          value={nftSymbol}
          type='text'
          onChange={(e) => setNftSymbol(e.target.value)}
        />
        {/* <Input
          theme={theme}
          placeholder="External Link (Optional)"
          type="url"
          value={externalLink}
          onChange={(e) => setExternalLink(e.target.value)}
        /> */}
        <Input
          theme={theme}
          placeholder={t('enter-wallet-password')}
          type="password"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />

        <DragDropArea
          theme={theme}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          isActive={dragActive} // 传递 dragActive 作为 isActive 属性
        >
          {file ? (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <PreviewImage src={previewSrc || ''} alt={t('preview')} />
              <CloseButton
                onClick={() => {
                  setFile(null);
                  setPreviewSrc('');
                }}
                title={t('remove')}
              >
                &times;
              </CloseButton>
            </div>
          ) : (
            <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
              <UploadIcon>⬆️</UploadIcon>
              <UploadText>{t('drag-and-drop-media')}</UploadText>
              <UploadText>
                <UploadText style={{ color: '#007bff' }}>
                  {t('browse-files')} </UploadText>
              </UploadText>
              <UploadText>{t('max-size-1mb')}</UploadText>
              <UploadText>{t('jpg-png-gif-svg-mp4')}</UploadText>
              {error && <UploadText style={{ color: 'red' }}>{error}</UploadText>} {/* 显示错误提示 */}
            </label>
          )}
          <HiddenInput id="file-upload" type="file" onChange={handleFileChange} />
        </DragDropArea>

        <Button theme={theme} type="primary" label={t('create-nft')} isSubmit disabled={isProcessing || !file || !passwordConfirm} />
        <Button
          label={t('Go back')}
          theme={theme}
          type="secondary"
          onClick={() => {
            setPageState('collectionContent');
            setTimeout(() => {
              if (selectedCollection?.collectionId) {
                //getNftbycollection(selectedCollection.collectionId, 0, false);
              }
            }, 500);
          }}
        />
      </FormContainer>
    </ReceiveContent>
  );

  const CreateCollection = (
    <ReceiveContent style={{ overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
      <BackButton onClick={() => {
        setPageState('main');
      }} />
      <HeaderText theme={theme}>{t('create-a-collection')}</HeaderText>
      <Text theme={theme} style={{ margin: '0' }} >
        {t('provide-details-for-your-collection-and-upload-a-file')} </Text>

      <FormContainer noValidate onSubmit={handleCreateCollection}>
        <Input
          theme={theme}
          placeholder={t('collection-name')}
          type='text'
          value={collectionName}
          onChange={(e) => setCollectionName(e.target.value)}
        />
        <Input
          theme={theme}
          placeholder={t('supply-total-nft-amount-in-your-collectio')}
          value={collectionSupply !== 101 ? collectionSupply : t('min-1-max-100')}
          type="number"
          style={{
            borderColor: (collectionSupply < 1 || collectionSupply > 100) ? 'red' : ''
          }}
          onChange={(e) => {
            const value = e.target.value;
            if (value === '') {
              setCollectionSupply(101);
            } else {
              const parsedValue = parseInt(value, 10);
              if (!isNaN(parsedValue)) {
                setCollectionSupply(parsedValue);
              }
            }
          }}
        />
        {collectionSupply < 1 || collectionSupply > 100 ? (
          <Text style={{ color: 'red', margin: '0' }}>{t('supply-must-be-between-1-and-100')}</Text>
        ) : null}

        <Input
          theme={theme}
          placeholder={t('description-about-your-collection-optional')}
          type='text'
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <Input
          theme={theme}
          placeholder={t('enter-wallet-password')}
          type="password"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        />

        <DragDropArea
          theme={theme}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          isActive={dragActive}
        >
          {file ? (
            <div style={{ position: 'relative', width: '100%', height: '100%' }}>
              <PreviewImage src={previewSrc || ''} alt={t('preview')} />
              <CloseButton
                onClick={() => {
                  setFile(null);
                  setPreviewSrc('');
                }}
                title={t('remove')}
              >
                &times;
              </CloseButton>
            </div>
          ) : (
            <label htmlFor="file-upload" style={{ cursor: 'pointer' }}>
              <UploadIcon>⬆️</UploadIcon>
              <UploadText>{t('drag-and-drop-media')}</UploadText>
              <UploadText>
                <UploadText style={{ color: '#007bff' }}>
                  {t('browse-files')} </UploadText>
              </UploadText>
              <UploadText>{t('max-size-1mb')}</UploadText>
              <UploadText>{t('jpg-png-gif-svg-mp4')}</UploadText>
              {error && <UploadText style={{ color: 'red' }}>{error}</UploadText>}
            </label>
          )}
          <HiddenInput id="file-upload" type="file" onChange={handleFileChange} />
        </DragDropArea>

        <Button
          theme={theme}
          type="primary"
          label={t('create-collection')}
          isSubmit
          disabled={
            isProcessing ||
            !file ||
            !collectionName ||
            !passwordConfirm ||
            !collectionSupply ||
            collectionSupply < 1 || collectionSupply > 100 // 如果supply无效，禁用按钮
          }
        />
      </FormContainer>
    </ReceiveContent>
  );
  // const transferAndListButtons = (
  //   <>
  //     <Button
  //       theme={theme}
  //       type="primary"
  //       label="Transfer"
  //       disabled={ordinals.data.length === 0 || !selectedOrdinal}
  //       onClick={async () => {
  //         if (!selectedOrdinal?.outpoint.toString()) {
  //           addSnackbar('You must select an ordinal to transfer!', 'info');
  //           return;
  //         }
  //         setPageState('transfer');
  //       }}
  //     />
  //     <Button
  //       theme={theme}
  //       type="primary"
  //       label="List"
  //       disabled={ordinals.data.length === 0 || !selectedOrdinal}
  //       onClick={async () => {
  //         if (!selectedOrdinal?.outpoint.toString()) {
  //           addSnackbar('You must select an ordinal to list!', 'info');
  //           return;
  //         }
  //         setPageState('list');
  //       }}
  //     />
  //   </>
  // );
  const nftHistoryDetail = (
    <PageWrapper style={{ height: '93%' }}>
      {nftSelectedTransaction && (
        <div style={{
          width: '100%',
          color: theme.white,
          borderRadius: '0.5rem',
          marginTop: '2rem'
        }}>
          <BackButton onClick={() => setPageState('nftHistory')} />
          <Text style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: '0', marginBottom: '0.25rem', color: theme.white }}>
            {t('transaction-details')}
          </Text>
          {nftSelectedTransaction.nft_icon && (
            <ImageWrapper url={nftSelectedTransaction.nft_icon}>
            </ImageWrapper>
          )}
          {/* 
            <Text style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', color: theme.white }}>
                {selectedOrdinal.banlance_change} TBC
              </Text> */}
          <DetailContainer theme={theme}>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('txid')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(nftSelectedTransaction.txid)}>
                <span>
                  {truncate(nftSelectedTransaction.txid, 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>

            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('sender-address')}:
              </DetailText>
              {nftSelectedTransaction.sender_addresses.length > 0 ? (
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(nftSelectedTransaction.sender_addresses[0])}>
                  <span>
                    {truncate(nftSelectedTransaction.sender_addresses[0], 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>) : (<DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {t('self-creation')}
                  </span>
                </DetailText>)}
            </CopyWrapper>


            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('recipient-address')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(nftSelectedTransaction.recipient_addresses[0])}>
                <span>
                  {truncate(nftSelectedTransaction.recipient_addresses[0], 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>

            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('token-id')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(nftSelectedTransaction.nft_contract_id)}>
                <span>
                  {truncate(nftSelectedTransaction.nft_contract_id, 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>

            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('contract-address')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(nftSelectedTransaction.collection_id)}>
                <span>
                  {truncate(nftSelectedTransaction.collection_id, 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>

          </DetailContainer>
          <Text style={{ textAlign: 'left', margin: '0.2rem 0 0 2rem', padding: '0', fontSize: '0.9rem', fontWeight: 'bold' }}>{t('basic-info')}</Text>
          <DetailContainer theme={theme} style={{ marginTop: '0' }}>
            <div style={{ display: 'flex' }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('token-standards')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} >
                  TBC721
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem', marginLeft: 'auto', marginRight: '5rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('ordinal-index')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  {nftSelectedTransaction.collection_index}
                </DetailText>
              </CopyWrapper>
            </div>

            <div style={{ display: 'flex' }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }} >
                  {t('create-time')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  {nftSelectedTransaction.time_stamp !== null
                    ? new Date(nftSelectedTransaction.time_stamp * 1000).toLocaleDateString(undefined, {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      timeZone: 'UTC',
                    }).replace(',', '')
                    : t('waiting-for-chain')}
                </DetailText>
              </CopyWrapper>

            </div>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('traits')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} >
                <span>
                  {nftSelectedTransaction.nft_symbol}
                </span>
              </DetailText>
            </CopyWrapper>

            {nftSelectedTransaction.nft_description && (<CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('description')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} >
                <span>
                  {nftSelectedTransaction.nft_description}
                </span>
              </DetailText>
            </CopyWrapper>)}

          </DetailContainer>
        </div>
      )}
    </PageWrapper>
  );
  const nftHistoryView = (
    <PageWrapper style={{ height: '93%' }} >
      <BackButton onClick={() => { setPageState('main'); showMenu(); setCurrentHistoryPage(0) }} />
      {nftHistory && (
        <div style={{
          width: '100%',
          color: theme.white,
          borderRadius: '0.5rem',
          marginTop: '0rem'
        }}>

          <Text style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: '1.5rem', marginBottom: '0.25rem', color: theme.white }}>
            {t('transaction-history')}
          </Text>

        </div>)}
      <ScrollableContainer >
          {nftHistoryCount === 0 ? (
           <DetailContainer theme={theme} style={{ marginLeft: '0rem' }}>
              <div style={{ textAlign: 'center', marginTop: '4rem' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 24 24" fill="#F57B1F" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="12" cy="12" r="10" stroke="#F57B1F" fill="none" />
                  <line x1="9" y1="9" x2="15" y2="15" stroke="#F57B1F" />
                  <line x1="15" y1="9" x2="9" y2="15" stroke="#F57B1F" />
                </svg>
                <p style={{ color: 'white', marginTop: '1rem',  fontSize:  '1.2rem' }}>{t('no-history')}</p>
              </div>
          </DetailContainer>
            ) : (nftHistory.map((transaction, index) => (
          <TransactionRow
            key={index}
            theme={theme}
            onClick={() => {
              setNftSelectedTransaction(transaction);
              setPageState('nftHistoryDetail');
            }}
            style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
          >

            {transaction.recipient_addresses[0] === tbcAddress ? (
              <div style={{ marginRight: '8px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                  <circle cx="18" cy="18" r="18" fill="black" />
                  <path d="M18 18H8v-2h7.59L6 6.41 7.41 5 18 15.59V8h2v10z" fill="green" transform="translate(5, 5) scale(1.1)" />
                </svg>
              </div>
            ) : (
              <div style={{ marginRight: '8px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                  <circle cx="18" cy="18" r="18" fill="black" />
                  <path d="M6 6h10v2H8.41l9.59 9.59-1.41 1.41L7 9.41V16H6V6z" fill="#F57B1F" transform="translate(5, 5) scale(1.1)" />
                </svg>
              </div>
            )}
            <div>
              {transaction.recipient_addresses[0] === tbcAddress && transaction.sender_addresses.length > 0 ? (
                <LockDetailsText theme={theme} style={{ fontSize: "0.9rem" }}>
                  {truncate(transaction.sender_addresses[0], 7, 7)}
                </LockDetailsText>
              ) : (
                <LockDetailsText theme={theme} style={{ fontSize: "0.9rem" }}>
                  {truncate(transaction.recipient_addresses[0], 7, 7)}
                </LockDetailsText>
              )}
              <LockDetailsText theme={theme}
                style={{
                  color: 'rgba(255, 255, 255, 0.5)',
                  fontSize: '0.8rem',
                  textAlign: 'left',
                }}>
                {transaction.time_stamp !== null
                  ? new Date(transaction.time_stamp * 1000)
                    .toLocaleDateString(undefined, {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                      // year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      timeZone: 'UTC',
                    })
                    .replace(',', '')
                  : t('waiting-for-chain')}
              </LockDetailsText>
            </div>
            <LockDetailsText
              theme={theme}
              style={{
                fontSize: '0.8rem',
                fontWeight: 'bold',
                marginRight: '-2rem',
                wordWrap: 'break-word',
              }}
            >
              {transaction.collection_name}#{transaction.collection_index}
            </LockDetailsText>
          </TransactionRow>
        )))}
      </ScrollableContainer>

      {Math.ceil(nftHistoryCount / PAGE_SIZE) > 1 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: currentHistoryPage === 0 ? 'not-allowed' : 'pointer',
            opacity: currentHistoryPage === 0 ? 0.5 : 1,
            // marginLeft: '-0.5rem' // 向左对齐
          }}
          onClick={() => currentHistoryPage > 0 && setCurrentHistoryPage((prev) => Math.max(prev - 1, 0))}
        >
          <img src={leftArrow} alt={t('last-page')} style={{ width: '20px', height: '20px', marginRight: '0.5rem', marginTop: '0.2rem' }} />
          <span style={{ color: theme.white, fontSize: '1rem' }}>{t('last-page')}</span>
        </div>

        {/* 当前页面/总页面数指示器 */}
        <div style={{ margin: '0 1.5rem', color: theme.white, fontSize: '1rem' }}>
          {currentHistoryPage + 1}/{Math.ceil(nftHistoryCount / PAGE_SIZE)}
        </div>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: currentHistoryPage === Math.ceil(nftHistoryCount / PAGE_SIZE) - 1 ? 'not-allowed' : 'pointer',
            opacity: currentHistoryPage === Math.ceil(nftHistoryCount / PAGE_SIZE) - 1 ? 0.5 : 1,
            // marginLeft: 'auto' // 向右对齐
          }}
          onClick={() => currentHistoryPage < Math.ceil(nftHistoryCount / PAGE_SIZE) - 1 && setCurrentHistoryPage((prev) => Math.min(prev + 1, Math.ceil(nftHistoryCount / PAGE_SIZE) - 1))}
        >
          <span style={{ color: theme.white, fontSize: '1rem', marginRight: '0.5rem' }}>{t('next-page')} </span>
          <img src={rightArrow} alt={t('next-page')} style={{ width: '20px', height: '20px', marginTop: '0.2rem' }} />
        </div>
      </div>}
    </PageWrapper>
  );
  const ftHistoryView = (
    <PageWrapper >
      <BackButton onClick={() => { setPageState('main'); setCurrentHistoryPage(0); showMenu() }} />
      <Text style={{
        width: '4rem',
        fontSize: '0.8rem',
        fontWeight: 'bold',
        position: 'absolute',
        top: '3rem',
        right: '0rem',
        cursor: 'pointer',
        color: theme.white
      }} onClick={() => setPageState('ftDetail')} >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
          <g>
            <rect x="3" y="4" width="18" height="2" fill="#F57B1F" />
            <rect x="3" y="10" width="18" height="2" fill="#F57B1F" />
            <rect x="3" y="16" width="18" height="2" fill="#F57B1F" />
          </g>
        </svg>
      </Text>
      {token && (
        <div style={{
          width: '100%',
          color: theme.white,
          borderRadius: '0.5rem',
          marginTop: '2rem'
        }}>

          <Text style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: '0', marginBottom: '0.25rem', color: theme.white }}>
            {t('transaction-history')}
          </Text>

          <FtWrapper url={token.info.ftIconUrl ? token.info.ftIconUrl : generatePlaceholderIcon(token.info.ftName)} >
          </FtWrapper>
          <Text style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', color: theme.white }}>
            {Number(showAmount(BigInt(token.info.ftBalance), token.info.ftDecimal))} {token.info.ftName}
          </Text>
        </div>)}
      <Show
        when={ftHistoryCount > 0}
        whenFalseContent={
          <NoInscriptionWrapper>
            <div style={{ textAlign: 'center', marginTop: '-2rem' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 24 24" fill="#F57B1F" stroke="currentColor" stroke-width="1" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="12" cy="12" r="10" stroke="#F57B1F" fill="none" />
                <line x1="9" y1="9" x2="15" y2="15" stroke="#F57B1F" />
                <line x1="15" y1="9" x2="9" y2="15" stroke="#F57B1F" />
              </svg>
              <p style={{ color: 'white', marginTop: '1rem', fontSize: '1.2rem' }}>{t('no-history')}</p>
            </div>
            <Button theme={theme} type="primary" label={t('Receive')} onClick={() => setPageState('receiveft')} />
          </NoInscriptionWrapper>
        }
      >
        <ScrollableContainer theme={theme} style={{ marginTop: '-1rem' }}>
          {ftHistory.map((transaction, index) => (
            <TransactionRow
              key={index}
              theme={theme}
              onClick={() => {
                setFtSelectedTransaction(transaction);
                setPageState('ftHistoryDetail');
              }}
              style={{ cursor: 'pointer', marginLeft: '0.5rem' }}
            >
              {/* 动态添加箭头 */}
              {transaction.ft_balance_change > 0 ? (
                <div style={{ marginRight: '8px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                    <circle cx="18" cy="18" r="18" fill="black" />
                    <path d="M18 18H8v-2h7.59L6 6.41 7.41 5 18 15.59V8h2v10z" fill="green" transform="translate(5, 5) scale(1.1)" />
                  </svg>
                </div>
              ) : (
                <div style={{ marginRight: '8px' }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
                    <circle cx="18" cy="18" r="18" fill="black" />
                    <path d="M6 6h10v2H8.41l9.59 9.59-1.41 1.41L7 9.41V16H6V6z" fill="#F57B1F" transform="translate(5, 5) scale(1.1)" />
                  </svg>
                </div>
              )}
              <div>
                {transaction.ft_balance_change >= 0 && transaction.sender_combine_script?.length > 0 && transaction.recipient_combine_script?.length > 0 ? (
                  <LockDetailsText theme={theme} style={{ fontSize: "0.9rem" }}>
                    {truncate(transaction.sender_combine_script[0], 7, 7)}
                  </LockDetailsText>
                ) : (
                  <LockDetailsText theme={theme} style={{ fontSize: "0.9rem" }}>
                    {truncate(transaction.recipient_combine_script[0], 7, 7)}
                  </LockDetailsText>
                )}
                <LockDetailsText theme={theme}
                  style={{
                    color: 'rgba(255, 255, 255, 0.5)',
                    fontSize: '0.8rem',
                    textAlign: 'left',
                  }}>
                  {transaction.time_stamp !== null
                    ? new Date(transaction.time_stamp * 1000)
                      .toLocaleDateString(undefined, {
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: false,
                        // year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        timeZone: 'UTC',
                      })
                      .replace(',', '')
                    : t('waiting-for-chain')}
                </LockDetailsText>
              </div>
              <LockDetailsText
                theme={theme}
                style={{
                  color:
                    transaction.ft_balance_change > 0 ? 'green' : theme.primaryButton,
                  fontSize: '1rem',
                  fontWeight: 'bold',
                  marginRight: '0',
                  wordWrap: 'break-word',
                }}
              >
                {transaction.ft_balance_change >= 0
                  ? `+${Number(showAmount(BigInt(transaction.ft_balance_change), transaction.ft_decimal))} `
                  : `-${Number(showAmount(BigInt(Math.abs(transaction.ft_balance_change)), transaction.ft_decimal))} `}
                {/* {token?.info.ftName} */}
              </LockDetailsText>
            </TransactionRow>
          ))}
          {Math.ceil(ftHistoryCount / PAGE_SIZE) > 1 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: currentHistoryPage === 0 ? 'not-allowed' : 'pointer',
                opacity: currentHistoryPage === 0 ? 0.5 : 1,
                // marginLeft: '-0.5rem' // 向左对齐
              }}
              onClick={() => currentHistoryPage > 0 && setCurrentHistoryPage((prev) => Math.max(prev - 1, 0))}
            >
              <img src={leftArrow} alt={t('last-page')} style={{ width: '20px', height: '20px', marginRight: '0.5rem', marginTop: '0.2rem' }} />
              <span style={{ color: theme.white, fontSize: '1rem' }}>{t('last-page')}</span>
            </div>

            {/* 当前页面/总页面数指示器 */}
            <div style={{ margin: '0 1.5rem', color: theme.white, fontSize: '1rem' }}>
              {currentHistoryPage + 1}/{Math.ceil(ftHistoryCount / PAGE_SIZE)}
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: currentHistoryPage === Math.ceil(ftHistoryCount / PAGE_SIZE) - 1 ? 'not-allowed' : 'pointer',
                opacity: currentHistoryPage === Math.ceil(ftHistoryCount / PAGE_SIZE) - 1 ? 0.5 : 1,
                // marginLeft: 'auto' // 向右对齐
              }}
              onClick={() => currentHistoryPage < Math.ceil(ftHistoryCount / PAGE_SIZE) - 1 && setCurrentHistoryPage((prev) => Math.min(prev + 1, Math.ceil(ftHistoryCount / PAGE_SIZE) - 1))}
            >
              <span style={{ color: theme.white, fontSize: '1rem', marginRight: '0.5rem' }}>{t('next-page')} </span>
              <img src={rightArrow} alt={t('next-page')} style={{ width: '20px', height: '20px', marginTop: '0.2rem' }} />
            </div>
          </div>}
        </ScrollableContainer>

        <ButtonContainer style={{
          position: 'absolute',
          bottom: '0rem',
          cursor: 'pointer',
        }}>
          <Button theme={theme} type="primary" label={t('Send')} onClick={() => setPageState('sendTBC20')} />
          <Button theme={theme} type="primary" label={t('Receive')} onClick={() => setPageState('receiveft')} />
        </ButtonContainer>
      </Show>
    </PageWrapper>
  );

  const ftHistoryDetail = (
    <PageWrapper >
      <BackButton onClick={() => setPageState('ftHistory')} />
      {(token && ftSelectedTransaction) && (
        <div style={{
          width: '100%',
          color: theme.white,
          borderRadius: '0.5rem',
          marginTop: '2rem'
        }}>
          <Text style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: '0', marginBottom: '0.25rem', color: theme.white }}>
            {t('transaction-details')}
          </Text>

          <FtWrapper url={token.info.ftIconUrl ? token.info.ftIconUrl : generatePlaceholderIcon(token.info.ftName)} >
          </FtWrapper>
          <Text style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', color: theme.white }}>
            {ftSelectedTransaction.ft_balance_change >= 0
              ? `+${Number(showAmount(BigInt(ftSelectedTransaction.ft_balance_change), ftSelectedTransaction.ft_decimal))} `
              : `-${Number(showAmount(BigInt(Math.abs(ftSelectedTransaction.ft_balance_change)), ftSelectedTransaction.ft_decimal))} `}
            {token?.info.ftName}
          </Text>

          <DetailContainer theme={theme}>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('txid')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(ftSelectedTransaction.txid)}>
                <span>
                  {truncate(ftSelectedTransaction.txid, 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('sender-address')}:
              </DetailText>
              {ftSelectedTransaction.sender_combine_script?.length > 0 ? (
                <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(ftSelectedTransaction.sender_combine_script[0])}>
                  <span>
                    {truncate(ftSelectedTransaction.sender_combine_script[0], 12, 12)}
                    <StyledCopy src={copyIcon} />
                  </span>
                </DetailText>) : (<DetailText style={{ fontSize: '1rem' }}>
                  <span>
                    {t('self-mint')}
                  </span>
                </DetailText>)}
            </CopyWrapper>

            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('recipient-address')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(ftSelectedTransaction.recipient_combine_script[0])}>
                <span>
                  {truncate(ftSelectedTransaction.recipient_combine_script[0], 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>

          </DetailContainer>
          <Text style={{ textAlign: 'left', margin: '0.2rem 0 0 2rem', padding: '0', fontSize: '0.9rem', fontWeight: 'bold' }}> 基本信息</Text>
          <DetailContainer theme={theme} style={{ marginTop: '0' }}>
            <div style={{ display: 'flex' }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('token-standards')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} >
                  TBC20
                </DetailText>
              </CopyWrapper>

            </div>

            <div style={{ display: 'flex' }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }} >
                  {t('transaction-time')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  {ftSelectedTransaction.time_stamp !== null
                    ? new Date(token.info.ftCreateTimestamp * 1000).toLocaleDateString(undefined, {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      timeZone: 'UTC',
                    }).replace(',', '')
                    : t('waiting-for-chain')}
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem', marginLeft: 'auto', marginRight: '3.5rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('decimal')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} >
                  <span>
                    {token.info.ftDecimal}
                  </span>
                </DetailText>
              </CopyWrapper>


            </div>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('traits')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} >
                <span>
                  {token.info.ftSymbol}
                </span>
              </DetailText>
            </CopyWrapper>

          </DetailContainer>
        </div>
      )}
    </PageWrapper>
  );
  const ftDetail = (
    <PageWrapper >
      <BackButton onClick={() => setPageState('ftHistory')} />
      {token && (
        <div style={{
          width: '100%',
          color: theme.white, // 假设你的主题指定白色文字颜色
          borderRadius: '0.5rem',
          marginTop: '2rem'
        }}>
          <Text style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: '0', marginBottom: '0.25rem', color: theme.white }}>
            {t('token-details')}
          </Text>

          <FtWrapper url={token.info.ftIconUrl ? token.info.ftIconUrl : generatePlaceholderIcon(token.info.ftName)} >
          </FtWrapper>
          <Text style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem', color: theme.white }}>
            {Number(showAmount(BigInt(token.info.ftBalance), token.info.ftDecimal))} {token.info.ftName}
          </Text>

          <DetailContainer theme={theme}>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('contract-address')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(token.info.ftContractId)}>
                <span>
                  {truncate(token.info.ftContractId, 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('price')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }}>
                <span>
                  {token.info.ftTokenPrice}
                </span>
              </DetailText>
            </CopyWrapper>

          </DetailContainer>
          <Text style={{ textAlign: 'left', margin: '0.2rem 0 0 2rem', padding: '0', fontSize: '0.9rem', fontWeight: 'bold' }}> 基本信息</Text>
          <DetailContainer theme={theme} style={{ marginTop: '0' }}>
            <div style={{ display: 'flex' }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('token-standards')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} >
                  TBC20
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem', marginLeft: 'auto', marginRight: '1.5rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('supply')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  {token.info.ftSupply}
                </DetailText>
              </CopyWrapper>
            </div>

            <div style={{ display: 'flex' }}>
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }} >
                  {t('create-time')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }}>
                  {token.info.ftCreateTimestamp !== null
                    ? new Date(token.info.ftCreateTimestamp * 1000).toLocaleDateString(undefined, {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      timeZone: 'UTC',
                    }).replace(',', '')
                    : t('waiting-for-chain')}
                </DetailText>
              </CopyWrapper>

              <CopyWrapper style={{ marginBottom: '0.25rem', marginLeft: 'auto', marginRight: '3.5rem' }}>
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('decimal')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} >
                  <span>
                    {token.info.ftDecimal}
                  </span>
                </DetailText>
              </CopyWrapper>


            </div>
            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('traits')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} >
                <span>
                  {token.info.ftSymbol}
                </span>
              </DetailText>
            </CopyWrapper>

          </DetailContainer>
        </div>
      )}
    </PageWrapper>
  );
  const ft = (
    <>
      <Show
        when={fts.initialized && ftCount > 0}
        whenFalseContent={
          <NoInscriptionWrapper>
            <img src={noFT} style={{ width: '12rem', height: 'auto' }} />
            <Text
              style={{
                color: theme.white,
                fontSize: '1rem',
                marginTop: '2rem',
              }}
            >
              {t('you-dont-have-any-tokens')} </Text>
            <Button theme={theme} type="primary" label={t('add-new-tokens')} onClick={() => setPageState('addft')} />
            <Button theme={theme} type="primary" label={t('Receive')} onClick={() => setPageState('receive')} />
          </NoInscriptionWrapper>
        }
      >
        <TBC20List>
          <TBC20Header>
            <SubHeaderText style={{ marginLeft: '1rem', color: theme.primaryButton }} theme={theme}>
              {t('ft-balance')}
            </SubHeaderText>
            <SubHeaderText style={{ marginLeft: 'auto', marginRight: '2rem', marginBottom: '-0.3rem', cursor: 'pointer' }} theme={theme}
              onClick={() => setPageState('addft')} >
              <img src={circleplus} />
            </SubHeaderText>
          </TBC20Header>
          <div style={{ width: '100%' }}>
            {fts.data
              .filter((d) => d.ftBalance >= 0)
              .map((b) => {
                return (
                  <div
                    key={b.ftContractId}
                    style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
                    onClick={async () => {
                      setToken({
                        isConfirmed: true,
                        info: b,
                      });
                      getFtHistory(b.ftContractId, 0, true);
                      hideMenu();
                      setPageState('ftHistory');
                    }}
                  >
                    <AssetRow
                      balance={Number(showAmount(BigInt(b.ftBalance), b.ftDecimal))}
                      icon={b.ftIconUrl ? b.ftIconUrl : generatePlaceholderIcon(b.ftName)}
                      ticker={getTokenName(b)}
                      usdBalance={
                        (priceData.find((p) => p.id === b.ftContractId)?.satPrice ?? 0) *
                        (exchangeRate / TBC_DECIMAL_CONVERSION) *
                        Number(showAmount(BigInt(b.ftBalance), b.ftDecimal))
                      }
                    />
                  </div>
                );
              })}
          </div>
        </TBC20List>
      </Show>
    </>
  );

  const addft = (
    <ReceiveContent>
      <BackButton onClick={() => {
        setPageState('main');
      }} />
      <HeaderText theme={theme}>{t('add-a-ft')}</HeaderText>
      <Text theme={theme}>
        {t('provide-contract-address-for-a-ft')} </Text>

      <FormContainer noValidate onSubmit={handleAddFT}>
        <Input
          theme={theme}
          placeholder={t('contract-address-0')}
          value={ftAddress}
          type='text'
          onChange={(e) => setFtAddress(e.target.value)}
        />

        {/* <Input
          theme={theme}
          placeholder={t('enter-wallet-password')}
          type="password"
          value={passwordConfirm}
          onChange={(e) => setPasswordConfirm(e.target.value)}
        /> */}

        <Button theme={theme} type="primary" label={t('add-ft')} isSubmit disabled={isProcessing || !ftAddress} />
        <Button
          label={t('Go back')}
          theme={theme}
          type="secondary"
          onClick={() => {
            setPageState('main');
          }}
        />
      </FormContainer>
    </ReceiveContent>
  );
  const receive = (
    <ReceiveContent>
      <HeaderText style={{ marginTop: '1rem' }} theme={theme}>
        {t('receive-tokens')}</HeaderText>
      <Text style={{ marginBottom: '1.25rem' }} theme={theme}>
        {t('send-nfts-and-tbc20-to-this-address')} {/* <Warning theme={theme}>Do not send TBC here.</Warning> */}
      </Text>
      <QrCode address={tbcAddress} onClick={() => handleCopyToClipboard(tbcAddress)} />
      <CopyAddressWrapper onClick={() => handleCopyToClipboard(tbcAddress)}>
        <StyledCopy src={copyIcon} />
        <Text theme={theme} style={{ margin: '0', color: theme.white, fontSize: '0.75rem' }}>
          {tbcAddress}
        </Text>
      </CopyAddressWrapper>
      <Button
        label={t('Go back')}
        theme={theme}
        type="secondary"
        onClick={() => {
          setPageState('main');
          setTimeout(() => {
            getOrdinals(0, false);
          }, 500);
        }}
      />
    </ReceiveContent>
  );

  const receiveft = (
    <ReceiveContent>
      <HeaderText style={{ marginTop: '1rem' }} theme={theme}>
        {t('receive-tokens')}</HeaderText>
      <Text style={{ marginBottom: '1.25rem' }} theme={theme}>
        {t('send-nfts-and-tbc20-to-this-address')}
      </Text>
      <QrCode address={tbcAddress} onClick={() => handleCopyToClipboard(tbcAddress)} />
      <CopyAddressWrapper onClick={() => handleCopyToClipboard(tbcAddress)}>
        <StyledCopy src={copyIcon} />
        <Text theme={theme} style={{ margin: '0', color: theme.white, fontSize: '0.75rem' }}>
          {tbcAddress}
        </Text>
      </CopyAddressWrapper>
      <Button
        label={t('Go back')}
        theme={theme}
        type="secondary"
        onClick={() => {
          setPageState('ftHistory');
        }}

      />
    </ReceiveContent>
  );

  const transfer = (
    <ContentWrapper>
      <ConfirmContent>
        <HeaderText style={{ fontSize: '1.35rem' }} theme={theme}>{`${selectedOrdinal?.origin?.data?.map?.name ??
          selectedOrdinal?.origin?.data?.map?.subTypeData?.name ??
          t('transfer-nft')
          }`}</HeaderText>
        {selectedOrdinal?.nftIcon && (
          <ImageWrapper url={selectedOrdinal.nftIcon}>
          </ImageWrapper>
        )}

        {/* <Ordinal
          theme={theme}
          inscription={selectedOrdinal as NFTTxo}
          url={`${getOrdinalsBaseUrl()}/content/${selectedOrdinal?.origin?.outpoint.toString()}`}
          isTransfer
        /> */}
        <FormContainer noValidate onSubmit={(e) => handleTransferOrdinal(e)}>
          <Input
            theme={theme}
            placeholder={t('receive-address')}
            type="text"
            name="address"
            onChange={(e) => setReceiveAddress(e.target.value)}
            value={receiveAddress}
          />
          <Show when={isPasswordRequired}>
            <Input
              theme={theme}
              placeholder={t('password')}
              name="password"
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </Show>
          <Button theme={theme} type="primary" label={t('transfer-now')} disabled={isProcessing} isSubmit />
        </FormContainer>
        <Button
          theme={theme}
          type="secondary"
          label={t('Go back')}
          onClick={() => {
            setPageState('nftDetails');

          }}
        />
      </ConfirmContent>
    </ContentWrapper>
  );

  const cancel = (
    <ContentWrapper>
      <BackButton
        onClick={() => {
          setPageState('main');
          resetSendState();
        }}
      />
      <ConfirmContent>
        <HeaderText style={{ fontSize: '1.35rem' }} theme={theme}>
          {t('cancel-listing')}
        </HeaderText>
        <Text style={{ margin: 0 }} theme={theme}>{`#${selectedOrdinal?.origin?.num}`}</Text>
        <Ordinal
          theme={theme}
          inscription={selectedOrdinal as NFTTxo}
          url={`${getOrdinalsBaseUrl()}/content/${selectedOrdinal?.origin?.outpoint.toString()}`}
          selected
          isTransfer
        />
        <FormContainer noValidate onSubmit={(e) => handleCancelListing(e)}>
          <Show when={isPasswordRequired}>
            <Input
              theme={theme}
              placeholder={t('password')}
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </Show>
          <Button theme={theme} type="primary" label={t('cancel-now')} disabled={isProcessing} isSubmit />
        </FormContainer>
      </ConfirmContent>
    </ContentWrapper>
  );
  const collectionContent = (
    <Content style={{ overflowY: 'auto', scrollbarWidth: 'none', msOverflowStyle: 'none' }}>
      <BackButton onClick={() => { setPageState('main'); showMenu(); setCurrentNftPage(0); }} />
      {selectedCollection && (
        <div style={{
          width: '100%',
          color: theme.white,
          borderRadius: '0.5rem',
          marginTop: '1rem'
        }}>
          <Text style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: '0', marginBottom: '0.25rem', color: theme.white }}>
            {selectedCollection.collectionName}
          </Text>
          {selectedCollection?.collectionIcon && (
            <ImageWrapper url={selectedCollection.collectionIcon} style={{ minWidth: '5rem', minHeight: '5rem' }}>
            </ImageWrapper>
          )}

          <DetailContainer theme={theme}>
            <CopyWrapper >
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('creator')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(selectedCollection.collectionCreator)}>
                <span>
                  {truncate(selectedCollection.collectionCreator, 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>

            <CopyWrapper>
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('contract-address')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }} onClick={() => handleCopyToClipboard(selectedCollection.collectionId)}>
                <span>
                  {truncate(selectedCollection.collectionId, 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>

            <div style={{ display: 'flex' }}>
              <CopyWrapper >
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('created')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} >
                  <span>
                    {nftCount}
                  </span>
                </DetailText>
              </CopyWrapper>

              <CopyWrapper >
                <DetailText style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}>
                  {t('supply')}:
                </DetailText>
                <DetailText style={{ fontSize: '1rem' }} >
                  <span>
                    {selectedCollection.collectionSupply}
                  </span>
                </DetailText>
              </CopyWrapper>
            </div>

            <CopyWrapper >
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }} >
                {t('create-time')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }}>
                {selectedCollection.collectionCreateTimestamp !== null
                  ? new Date(selectedCollection.collectionCreateTimestamp * 1000).toLocaleDateString(undefined, {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    timeZone: 'UTC',
                  }).replace(',', '')
                  : t('waiting-for-chain')}
              </DetailText>
            </CopyWrapper>

            <CopyWrapper >
              <DetailText style={{
                color: 'rgba(255, 255, 255, 0.75)',
                fontSize: '0.8rem',
              }}>
                {t('description')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }}>
                <span>
                  {truncate(selectedCollection.collectionDescription, 50, 0)}
                </span>
              </DetailText>
            </CopyWrapper>
          </DetailContainer>
        </div>)}

      <Show
        when={nftsInCollection.initialized && nftCount > 0}
        whenFalseContent={
          <>
            <NoInscriptionWrapper style={{ marginTop: '0.5rem' }}>
              <img src={nonft} style={{ width: '12rem', height: 'auto' }} />
              <p style={{ color: theme.white, fontSize: '1rem', marginTop: '1rem' }}>{t('you-dont-have-any-nft-in-the-collection')}</p>
              <Button theme={theme} type="primary" label={t('create')} onClick={() => { updateTbcUtxos(true); setPageState('createNFT') }} />
              <Button
                label={t('Go back')}
                theme={theme}
                type="secondary"
                onClick={() => {
                  setPageState('main');
                  setCurrentNftPage(0);
                }}
                style={{ margin: '0 0.5rem' }}
              />
            </NoInscriptionWrapper>
          </>
        }
      >
        <NftInList $isMobile={isMobile}>
          {Math.ceil(nftCount / PAGE_SIZE) > 1 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0.2rem' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: currentNftPage === 0 ? 'not-allowed' : 'pointer',
                opacity: currentNftPage === 0 ? 0.5 : 1,
                // marginLeft: '-0.5rem' // 向左对齐
              }}
              onClick={() => currentNftPage > 0 && setCurrentNftPage((prev) => Math.max(prev - 1, 0))}
            >
              <img src={leftArrow} alt={t('last-page')} style={{ width: '20px', height: '20px', marginRight: '0.5rem', marginTop: '0.2rem', marginLeft: '-1.5rem' }} />
              <span style={{ color: theme.white, fontSize: '1rem' }}>{t('last-page')}</span>
            </div>

            {/* 当前页面/总页面数指示器 */}
            <div style={{ margin: '0 1.5rem', color: theme.white, fontSize: '1rem' }}>
              {currentNftPage + 1}/{Math.ceil(nftCount / PAGE_SIZE)}
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: currentNftPage === Math.ceil(nftCount / PAGE_SIZE) - 1 ? 'not-allowed' : 'pointer',
                opacity: currentNftPage === Math.ceil(nftCount / PAGE_SIZE) - 1 ? 0.5 : 1,
                // marginLeft: 'auto' // 向右对齐
              }}
              onClick={() => currentNftPage < Math.ceil(nftCount / PAGE_SIZE) - 1 && setCurrentNftPage((prev) => Math.min(prev + 1, Math.ceil(nftCount / PAGE_SIZE) - 1))}
            >
              <span style={{ color: theme.white, fontSize: '1rem', marginRight: '0.5rem' }}>{t('next-page')}</span>
              <img src={rightArrow} alt={t('next-page')} style={{ width: '20px', height: '20px', marginTop: '0.2rem' }} />
            </div>
          </div>}
          <div>
            <GridWrapper style={{ marginTop: '0.5rem' }}>
              {Array.isArray(nftsInCollection.data) && nftsInCollection.data.map((ord: NFTTxo) => (
                <div
                  key={ord.nftUtxoId + ord.vout}
                  style={{
                    display: 'flex',
                    //border: selectedOrdinal?.txid === ord.txid && selectedOrdinal?.vout === ord.vout ? `2px solid ${theme.gray}` : '2px solid transparent',
                    cursor: 'pointer',
                    height: '40%',
                  }}
                >
                  <Container theme={theme} style={{ flexDirection: 'column' }}>
                    {ord.image && <NFTThumbnail src={ord.image} alt={`${ord.nftName} thumbnail`} style={{ width: '90%', minHeight: '125px' }} />}

                    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, textAlign: 'left', marginLeft: '-1rem', width: '90%' }}>

                      <div style={{ fontSize: '1.2rem', color: theme.white, fontWeight: 'bold' }}>
                        {ord.collectionName}#{ord.collectionIndex}
                      </div>
                      <div >
                        <div style={{ fontSize: '0.8rem', color: theme.gray }}>
                          {t('token-id')} :
                        </div>
                        <div style={{ fontSize: '0.8rem', color: theme.primaryButton }}>{truncate(ord.nftContractId, 8, 8)}</div>
                      </div>
                      <div >
                        <div style={{ fontSize: '0.8rem', color: theme.gray }} >
                          {t('contract-address')}:
                        </div>
                        <span style={{ fontSize: '0.8rem', color: theme.primaryButton }}>{truncate(ord.collectionId ?? '', 8, 8)}</span>
                      </div>
                      {/* <div style={{ fontSize: '0.8rem', color: theme.gray }}>
                  {t('traits')}: <span style={{ color: theme.primaryButton }}>{ord.nftSymbol}</span>
                  </div> */}
                    </div>
                  </Container>
                </div>
              ))}

            </GridWrapper>
          </div>
        </NftInList>

        <ButtonContainer style={{
          position: 'absolute',
          bottom: '0rem',
          cursor: 'pointer',
        }}>
          <Button theme={theme}
            type="primary"
            label={isProcessing || !selectedCollection || nftCount >= selectedCollection.collectionSupply ? t('creation-full') : t('create')}
            disabled={isProcessing || !selectedCollection || nftCount >= selectedCollection.collectionSupply}
            onClick={() => { updateTbcUtxos(true); setPageState('createNFT') }} />
        </ButtonContainer>
      </Show>
    </Content>
  );

  const nft = (
    <Content>
      <Show
        when={ordinals.initialized && OrdCount > 0}
        whenFalseContent={
          <NoInscriptionWrapper>
            <img src={nonft} style={{ width: '12rem', height: 'auto' }} />
            <p style={{ color: theme.white, fontSize: '1rem', marginTop: '4rem' }}>{t('you-dont-own-any-nfts')}</p>
            <Button theme={theme} type="primary" label={t('Receive')} onClick={() => setPageState('receive')} />
            <Button
              label={t('Go back')}
              theme={theme}
              type="secondary"
              onClick={() => {
                setPageState('main');
                resetSendState();
              }}

            />
          </NoInscriptionWrapper>
        }
      >
        <div style={{ height: isMobile ? '80%' : 'auto' }}>
          <NftList $isMobile={isMobile}>
            <TBC20Header>
              <SubHeaderText style={{ color: theme.primaryButton }} theme={theme}>
                NFT
              </SubHeaderText>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <SubHeaderText style={{ marginBottom: '0rem', cursor: 'pointer', marginRight: '1rem' }} theme={theme}
                  //  onClick={() => { getOrdinals(0,false);setBlockDisplay(false) }}
                  onClick={() => { getNftHistory(0, true); hideMenu(); setPageState('nftHistory') }} >
                  {/* <svg width="25px" height="25px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'scale(1)' }}>
                    <rect x="10" y="10" width="80" height="80" fill="none" stroke="#F57B1F" stroke-width="5" />
                    <rect x="20" y="20" width="25" height="25" fill="#F57B1F" />
                    <rect x="55" y="20" width="25" height="25" fill="#F57B1F" />
                    <rect x="20" y="55" width="25" height="25" fill="#F57B1F" />
                    <rect x="55" y="55" width="25" height="25" fill="#F57B1F" />
                  </svg> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <g>
                      <rect x="3" y="4" width="18" height="2" fill="#F57B1F" />
                      <rect x="3" y="10" width="18" height="2" fill="#F57B1F" />
                      <rect x="3" y="16" width="18" height="2" fill="#F57B1F" />
                    </g>
                  </svg>
                </SubHeaderText>

                <SubHeaderText style={{ marginBottom: '-0.5rem', marginRight: '3.05rem', cursor: 'pointer', padding: '0' }} theme={theme}
                  onClick={() => { updateTbcUtxos(true); setPageState('createCollection') }} >
                  <img src={circleplus} style={{ marginBottom: '-0.3rem' }} />
                </SubHeaderText>
              </div>
            </TBC20Header>
            <div >
              <GridWrapper style={{ marginTop: '1rem' }}>
                {Array.isArray(ordinals.data) && ordinals.data.map((ord: NFTTxo) => (
                  <div
                    key={ord.nftUtxoId + ord.vout}
                    onClick={() => {
                      setSelectedOrdinal(ord);
                      setOrdinalOutpoint(ord.outpoint.toString());
                      hideMenu();
                      setPageState('nftDetails');
                    }}
                    style={{
                      display: 'flex',
                      //border: selectedOrdinal?.txid === ord.txid && selectedOrdinal?.vout === ord.vout ? `2px solid ${theme.gray}` : '2px solid transparent',
                      cursor: 'pointer',
                      height: '40%',
                    }}
                  >
                    <Container theme={theme} style={{ flexDirection: 'column' }}>
                      {ord.image && <NFTThumbnail src={ord.image} alt={`${ord.nftName} thumbnail`} style={{ width: '90%', minHeight: '125px' }} />}

                      <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, textAlign: 'left', marginLeft: '-1rem', width: '90%' }}>

                        <div style={{ fontSize: '1.2rem', color: theme.white, fontWeight: 'bold' }}>
                          {ord.collectionName}#{ord.collectionIndex}
                        </div>
                        <div >
                          <div style={{ fontSize: '0.8rem', color: theme.gray }}>
                            {t('token-id')} :
                          </div>
                          <div style={{ fontSize: '0.8rem', color: theme.primaryButton }}>{truncate(ord.nftContractId, 8, 8)}</div>
                        </div>
                        <div >
                          <div style={{ fontSize: '0.8rem', color: theme.gray }} >
                            {t('contract-address')}:
                          </div>
                          <span style={{ fontSize: '0.8rem', color: theme.primaryButton }}>{truncate(ord.collectionId ?? '', 8, 8)}</span>
                        </div>
                        {/* <div style={{ fontSize: '0.8rem', color: theme.gray }}>
                  {t('traits')}: <span style={{ color: theme.primaryButton }}>{ord.nftSymbol}</span>
                  </div> */}
                      </div>
                    </Container>
                  </div>

                ))}
              </GridWrapper>
              {Math.ceil(OrdCount / PAGE_SIZE) > 1 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginLeft: '-1rem' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: currentPage === 0 ? 'not-allowed' : 'pointer',
                    opacity: currentPage === 0 ? 0.5 : 1,
                    // marginLeft: '-0.5rem' // 向左对齐
                  }}
                  onClick={() => currentPage > 0 && setCurrentPage((prev) => Math.max(prev - 1, 0))}
                >
                  <img src={leftArrow} alt={t('last-page')} style={{ width: '20px', height: '20px', marginRight: '0.5rem', marginTop: '0.2rem' }} />
                  <span style={{ color: theme.white, fontSize: '1rem' }}>{t('last-page')}</span>
                </div>

                {/* 当前页面/总页面数指示器 */}
                <div style={{ margin: '0 1.5rem', color: theme.white, fontSize: '1rem' }}>
                  {currentPage + 1}/{Math.ceil(OrdCount / PAGE_SIZE)}
                </div>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: currentPage === Math.ceil(OrdCount / PAGE_SIZE) - 1 ? 'not-allowed' : 'pointer',
                    opacity: currentPage === Math.ceil(OrdCount / PAGE_SIZE) - 1 ? 0.5 : 1,
                    // marginLeft: 'auto' // 向右对齐
                  }}
                  onClick={() => currentPage < Math.ceil(OrdCount / PAGE_SIZE) - 1 && setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(OrdCount / PAGE_SIZE) - 1))}
                >
                  <span style={{ color: theme.white, fontSize: '1rem', marginRight: '0.5rem' }}>{t('next-page')} </span>
                  <img src={rightArrow} alt={t('next-page')} style={{ width: '20px', height: '20px', marginTop: '0.2rem' }} />
                </div>
              </div>}
            </div>
          </NftList>
        </div>

      </Show>
    </Content>
  );
  const nftbyline = (
    <Content>

      <Show
        when={ordinals.initialized && OrdCount > 0}
        whenFalseContent={
          <NoInscriptionWrapper>
            <img src={nonft} style={{ width: '12rem', height: 'auto' }} />
            <p style={{ color: theme.white, fontSize: '1rem', marginTop: '4rem' }}>{t('you-dont-own-any-nfts')}</p>
            <Button theme={theme} type="primary" label={t('Receive')} onClick={() => setPageState('receive')} />
            <Button
              label={t('Go back')}
              theme={theme}
              type="secondary"
              onClick={() => {
                setPageState('main');
                resetSendState();
              }}

            />
          </NoInscriptionWrapper>
        }
      >
        <div style={{ height: isMobile ? '80%' : 'auto' }}>
          <NftList $isMobile={isMobile}>
            <TBC20Header>
              <SubHeaderText style={{ color: theme.primaryButton }} theme={theme}>
                NFT
              </SubHeaderText>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                <SubHeaderText style={{ marginBottom: '-0.5rem', cursor: 'pointer', marginRight: '1rem' }} theme={theme}
                  onClick={() => { getOrdinals(0, false); setBlockDisplay(true) }} >
                  <svg width="25px" height="25px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'scale(1)' }}>
                    <rect x="10" y="10" width="80" height="80" fill="none" stroke="#F57B1F" stroke-width="5" />
                    <rect x="20" y="20" width="25" height="25" fill="#F57B1F" />
                    <rect x="55" y="20" width="25" height="25" fill="#F57B1F" />
                    <rect x="20" y="55" width="25" height="25" fill="#F57B1F" />
                    <rect x="55" y="55" width="25" height="25" fill="#F57B1F" />
                  </svg>
                </SubHeaderText>

                <SubHeaderText style={{ marginBottom: '-0.5rem', marginRight: '3.05rem', cursor: 'pointer', padding: '0' }} theme={theme}
                  onClick={() => { updateTbcUtxos(true); setPageState('createCollection') }} >
                  <img src={circleplus} style={{ marginBottom: '-0.3rem' }} />
                </SubHeaderText>
              </div>
            </TBC20Header>
            {Array.isArray(ordinals.data) && ordinals.data.map((ord: NFTTxo) => (
              <div
                key={ord.nftUtxoId + ord.vout}
                onClick={() => {
                  setSelectedOrdinal(ord);
                  setOrdinalOutpoint(ord.outpoint.toString());
                  setPageState('nftDetails');
                }}
                style={{
                  display: 'flex',
                  //border: selectedOrdinal?.txid === ord.txid && selectedOrdinal?.vout === ord.vout ? `2px solid ${theme.gray}` : '2px solid transparent',
                  cursor: 'pointer',
                  width: '95%',
                  height: '20%',
                }}
              >
                <Container theme={theme} >
                  {ord.image && <NFTThumbnail src={ord.image} alt={`${ord.nftName} thumbnail`} />}

                  <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, textAlign: 'left' }}>

                    <div style={{ fontSize: '1.2rem', color: theme.white, fontWeight: 'bold' }}>
                      {ord.collectionName}#{ord.collectionIndex}
                    </div>
                    <div style={{ fontSize: '0.8rem', color: theme.gray }}>
                      {t('token-id')} : <span style={{ color: theme.primaryButton }}>{truncate(ord.nftContractId, 8, 10)}</span>
                    </div>
                    <div style={{ fontSize: '0.8rem', color: theme.gray }} >
                      {t('contract-address')}: <span style={{ color: theme.primaryButton }}>{truncate(ord.collectionId ?? '', 8, 8)}</span>
                    </div>
                    <div style={{ fontSize: '0.8rem', color: theme.gray }}>
                      {t('traits')}: <span style={{ color: theme.primaryButton }}>{ord.nftSymbol}</span>
                    </div>
                  </div>
                </Container>
              </div>
            ))}
            {Math.ceil(OrdCount / PAGE_SIZE) > 1 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: currentPage === 0 ? 'not-allowed' : 'pointer',
                  opacity: currentPage === 0 ? 0.5 : 1,
                  // marginLeft: '-0.5rem' // 向左对齐
                }}
                onClick={() => currentPage > 0 && setCurrentPage((prev) => Math.max(prev - 1, 0))}
              >
                <img src={leftArrow} alt={t('last-page')} style={{ width: '20px', height: '20px', marginRight: '0.5rem', marginTop: '0.2rem' }} />
                <span style={{ color: theme.white, fontSize: '1rem' }}>{t('last-page')}</span>
              </div>

              {/* 当前页面/总页面数指示器 */}
              <div style={{ margin: '0 1.5rem', color: theme.white, fontSize: '1rem' }}>
                {currentPage + 1}/{Math.ceil(OrdCount / PAGE_SIZE)}
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: currentPage === Math.ceil(OrdCount / PAGE_SIZE) - 1 ? 'not-allowed' : 'pointer',
                  opacity: currentPage === Math.ceil(OrdCount / PAGE_SIZE) - 1 ? 0.5 : 1,
                  // marginLeft: 'auto' // 向右对齐
                }}
                onClick={() => currentPage < Math.ceil(OrdCount / PAGE_SIZE) - 1 && setCurrentPage((prev) => Math.min(prev + 1, Math.ceil(OrdCount / PAGE_SIZE) - 1))}
              >
                <span style={{ color: theme.white, fontSize: '1rem', marginRight: '0.5rem' }}>{t('next-page')} </span>
                <img src={rightArrow} alt={t('next-page')} style={{ width: '20px', height: '20px', marginTop: '0.2rem' }} />
              </div>
            </div>}
          </NftList>
        </div>

      </Show>
    </Content>
  );
  const collection = (
    <Content>
      {/* <HeaderText style={{ textAlign:'left',fontSize: '1rem' ,marginTop:'0.25rem',marginBottom:'0.25rem'}} theme={theme}>
       {t('my-collections')} </HeaderText> */}

      <Show
        when={collections.initialized && collectionCount > 0}
        whenFalseContent={
          <NoInscriptionWrapper>
            <img src={noCollection} style={{ width: '15rem', height: 'auto' }} />
            <p style={{ color: theme.white, fontSize: '1rem', marginTop: '4rem' }}>{t('you-dont-have-any-collection')}</p>
            <Button theme={theme} type="primary" label={t('create-collection')} onClick={() => { updateTbcUtxos(true); setPageState('createCollection') }} />
            <Button theme={theme} type="primary" label={t('owned-nft')} onClick={() => {
              getOrdinals(0, false);
              setPageState('nft')
            }} />
          </NoInscriptionWrapper>
        }
      >
        <div style={{ height: isMobile ? '80%' : 'auto' }}>
          <OrdinalsList $isMobile={isMobile}>
            <TBC20Header>
              <SubHeaderText style={{ color: theme.primaryButton }} theme={theme}>
                {t('my-collections')}
              </SubHeaderText>
              <div style={{ display: 'flex', marginLeft: 'auto' }}>
                {/* <SubHeaderText style={{  marginBottom: '-0.5rem',cursor:'pointer',marginRight:'1rem' }} theme={theme}
         onClick={() => { getOrdinals(0,false);setPageState('nft') }} >
        <svg width="25px" height="25px" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" style={{ transform: 'scale(1)' }}>
  <rect x="10" y="10" width="80" height="80" fill="none" stroke="#F57B1F" stroke-width="5"/>
  <rect x="20" y="20" width="25" height="25" fill="#F57B1F"/>
  <rect x="55" y="20" width="25" height="25" fill="#F57B1F"/>
  <rect x="20" y="55" width="25" height="25" fill="#F57B1F"/>
  <rect x="55" y="55" width="25" height="25" fill="#F57B1F"/>
</svg>
          </SubHeaderText> */}

                <SubHeaderText style={{ marginBottom: '-0.5rem', marginRight: '3.05rem', cursor: 'pointer', padding: '0' }} theme={theme}
                  onClick={() => { updateTbcUtxos(true); setPageState('createCollection') }} >
                  <img src={circleplus} style={{ marginBottom: '-0.3rem' }} />
                </SubHeaderText>
              </div>
            </TBC20Header>
            {collections?.data?.map((coll: COLLECTIONTxo) => (
              <div
                key={coll.collectionId + coll.vout} // 使用 `txid` 和 `vout` 作为唯一键值
                onClick={() => {
                  setSelectedCollection(coll);
                  getNftbycollection(coll.collectionId, 0, true);
                  hideMenu();
                  setPageState('collectionContent');
                }}
                style={{
                  display: 'flex',
                  //border: selectedOrdinal?.txid === ord.txid && selectedOrdinal?.vout === ord.vout ? `2px solid ${theme.gray}` : '2px solid transparent',
                  cursor: 'pointer',
                  width: '90%',
                  height: '20%',
                }}
              >
                <Container theme={theme}>
                  {coll.image && <NFTThumbnail src={coll.image} alt={`${coll.collectionName} thumbnail`} />}

                  <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, textAlign: 'left' }}>

                    <div style={{ fontSize: '1.2rem', color: theme.white, fontWeight: 'bold' }}>
                      {coll.collectionName}
                    </div>
                    <div style={{ display: 'flex' }}>
                      <div>
                        <div style={{ fontSize: '0.7rem', color: theme.gray, cursor: 'pointer', padding: '0', margin: '0' }} >
                          {t('contract-address')}:

                        </div>
                        <div style={{ display: 'flex', fontSize: '0.8rem', color: theme.primaryButton, padding: '0', marginTop: '-0.15rem' }}>{truncate(coll.collectionId, 8, 8)}
                          <StyledCopy src={copyIcon} onClick={(e) => {
                            e.stopPropagation();
                            handleCopyToClipboard(coll.collectionId);
                          }} />
                        </div>
                      </div>
                      <div style={{ display: 'flex', justifyContent: 'space-between', fontSize: '0.8rem', color: theme.gray, marginLeft: 'auto', marginRight: '2rem' }}>
                        <span>{t('supply')}: <span style={{ color: theme.primaryButton }}>{coll.collectionSupply}</span></span>
                        {/* <span style={{ marginRight:'6rem' }}>{t('used')}: <span style={{ color: theme.primaryButton }}>{coll.usedNum}</span></span> */}
                      </div>
                    </div>
                    <div>
                      <div style={{ fontSize: '0.7rem', color: theme.gray, padding: '0', marginTop: '0.1rem' }}>
                        {t('description')}:
                      </div>
                      <div style={{ display: 'flex', fontSize: '0.8rem', color: theme.primaryButton, padding: '0', marginTop: '-0.15rem' }}>{truncate(coll.collectionDescription, 40, 0)}</div>
                    </div>
                  </div>
                </Container>
              </div>
            ))}
            {/* <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'sticky', bottom: 0 }}> */}
            {Math.ceil(collectionCount / PAGE_SIZE) > 1 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '-1.2rem' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: currentColPage === 0 ? 'not-allowed' : 'pointer',
                  opacity: currentColPage === 0 ? 0.5 : 1,
                  // marginLeft: '-0.5rem' // 向左对齐
                }}
                onClick={() => currentColPage > 0 && setCurrentColPage((prev) => Math.max(prev - 1, 0))}
              >
                <img src={leftArrow} alt={t('last-page')} style={{ width: '20px', height: '20px', marginRight: '0.5rem', marginTop: '0.2rem' }} />
                <span style={{ color: theme.white, fontSize: '1rem' }}>{t('last-page')}</span>
              </div>

              {/* 当前页面/总页面数指示器 */}
              <div style={{ margin: '0 1.5rem', color: theme.white, fontSize: '1rem' }}>
                {currentColPage + 1}/{Math.ceil(collectionCount / PAGE_SIZE)}
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  cursor: currentColPage === Math.ceil(collectionCount / PAGE_SIZE) - 1 ? 'not-allowed' : 'pointer',
                  opacity: currentColPage === Math.ceil(collectionCount / PAGE_SIZE) - 1 ? 0.5 : 1,
                  // marginLeft: 'auto' // 向右对齐
                }}
                onClick={() => currentColPage < Math.ceil(collectionCount / PAGE_SIZE) - 1 && setCurrentColPage((prev) => Math.min(prev + 1, Math.ceil(collectionCount / PAGE_SIZE) - 1))}
              >
                <span style={{ color: theme.white, fontSize: '1rem', marginRight: '0.5rem' }}>{t('next-page')}</span>
                <img src={rightArrow} alt={t('next-page')} style={{ width: '20px', height: '20px', marginTop: '0.2rem' }} />
              </div>
            </div>}

          </OrdinalsList>
        </div>

        {/* <ButtonContainer style={{position: 'absolute',marginTop:'0rem' }}>
          <Button theme={theme} type="primary" label={t('create-collection')} onClick={() => { updateTbcUtxos(true); setPageState('createCollection') }} />
          <Button theme={theme} type="primary" label={t('owned-nft')} onClick={() => { getOrdinals(0,false);
          setPageState('nft') }} />
        </ButtonContainer> */}
      </Show>
    </Content>
  );
  const main = (
    <>
      <QrImage src={qr} onClick={() => setPageState('receive')} />
      <Tabs tabIndex={tabIndex} selectTab={selectTab} theme={theme}
        onTabSelect={(index) => {
          if (index === 0) {
            getFts(false);
          } else if (index === 1) {
            getOrdinals(0, false);
          } else if (index === 2) {
            getCollection(0, false);
          }
        }} >
        {/* <Tabs.Panel theme={theme} label="NFT">
          {nft}
        </Tabs.Panel> */}
        <Tabs.Panel theme={theme} label="FT">
          {ft}
        </Tabs.Panel>
        <Tabs.Panel theme={theme} label="NFT" >
          {blockDisplay ? nft : nftbyline}
        </Tabs.Panel>
        <Tabs.Panel theme={theme} label={t('collection')}>
          {collection}
        </Tabs.Panel>
      </Tabs>
    </>
    //<img src={qr} style={{marginLeft:'auto'}}/>
  );

  const sendTBC20View = (
    <Show when={token !== null}>
      {token ? (
        <ConfirmContent>
          <Show when={!!token.info.ftIconUrl && token.info.ftIconUrl.length > 0}>
            <TokenIcon style={{ marginBottom: '0.5rem' }} src={`${getOrdinalsBaseUrl()}/content/${token.info.ftIconUrl}`} />
          </Show>
          <TransferTBC20Header theme={theme}>{t('Send')} {getTokenName(token.info)}</TransferTBC20Header>
          <TBC20Container>
            <Balance theme={theme} onClick={() => userSelectedAmount(String(Number(token.info.ftBalance)), token)}>
              {`${t('Balance')}: ${formatNumberWithCommasAndDecimals(
                Number(showAmount(BigInt(token.info.ftBalance), token.info.ftDecimal)),
                token.info.ftDecimal,
              )}`}
            </Balance>
          </TBC20Container>
          <TBC20Container>
            <TBC20Id
              theme={theme}
              id={token.info.ftContractId}
              onCopyTokenId={() => {
                addSnackbar(t('Copied'), 'success');
              }}
            ></TBC20Id>
          </TBC20Container>
          <FormContainer noValidate onSubmit={(e) => handleSendTBC20(e)}>
            <Input
              theme={theme}
              name="address"
              placeholder={t('receive-address')}
              type="text"
              onChange={(e) => setReceiveAddress(e.target.value)}
              value={receiveAddress}
            />
            <Input
              name="amt"
              theme={theme}
              placeholder={t('enter-token-amount')}
              type="number"
              step={'1'}
              value={tokenSendAmount !== null ? Number(tokenSendAmount) : ''}
              onChange={(e) => {
                const inputValue = e.target.value;
                const decimalPrecision = token.info.ftDecimal;
                const decimalPart = inputValue.split('.')[1];

                if (inputValue === '') {
                  setTokenSendAmount(null);
                } else if (Number(inputValue) <= 0) {
                  setTokenSendAmount(0);
                  setBorderColor('red');
                } else if (decimalPart && decimalPart.length > decimalPrecision) {
                  setBorderColor('red');
                  addSnackbar('decimal-precision-exceeded', 'error');
                  setTokenSendAmount(0);
                } else {
                  setBorderColor('');
                  userSelectedAmount(inputValue, token);
                }
              }}
              style={{
                borderColor: borderColor,
              }}
            />
            <Input
              theme={theme}
              name="password"
              placeholder={t('password')}
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <Button theme={theme} type="primary" label={t('Send')} disabled={isProcessing || !receiveAddress || !tokenSendAmount || !passwordConfirm || borderColor === 'red'} isSubmit />
          </FormContainer>
          <Button
            theme={theme}
            type="secondary"
            label={t('Go back')}
            style={{ marginTop: '0.5rem' }}
            disabled={isProcessing}
            onClick={() => {
              setTokenSendAmount(null);
              setPageState('ftHistory');
              resetSendState();
            }}
          />
        </ConfirmContent>
      ) : (
        <></>
      )}
    </Show>
  );

  const list = (
    <ContentWrapper>
      <ConfirmContent>
        {/* <HeaderText style={{ fontSize: '1.35rem' }} theme={theme}>{`List ${selectedOrdinal?.nftName ?? 'List Ordinal'
          }`}</HeaderText> */}
        <Text style={{ margin: 0 }} theme={theme}>{`#${selectedOrdinal?.origin?.num}`}</Text>
        <Ordinal
          theme={theme}
          inscription={selectedOrdinal as NFTTxo}
          url={`${getOrdinalsBaseUrl()}/content/${selectedOrdinal?.origin?.outpoint.toString()}`}
          selected
          isTransfer
        />
        {/* {selectedOrdinal?.image && (
        <img
          src={selectedOrdinal.image} // 使用选中 Ordinal 的 image 属性
          alt={`${selectedOrdinal.nftName} thumbnail`} // 设置图片的 alt 属性
          style={{ width: '100%', maxWidth: '300px', marginBottom: '1rem' }} // 设置样式
        />
      )} */}
        <FormContainer noValidate onSubmit={(e) => handleListOrdinal(e)}>
          <Input
            theme={theme}
            placeholder={t('enter-tbc-amount')}
            type="number"
            step="0.00000001"
            onChange={(e) => {
              const inputValue = e.target.value;

              // Check if the input is empty and if so, set the state to null
              if (inputValue === '') {
                setTbcListAmount(null);
              } else {
                setTbcListAmount(Number(inputValue));
              }
            }}
            value={tbcListAmount !== null && tbcListAmount !== undefined ? tbcListAmount : ''}
          />
          <Show when={isPasswordRequired}>
            <Input
              theme={theme}
              placeholder={t('password')}
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </Show>
          <Text theme={theme} style={{ margin: '1rem 0 0 0' }}>
            {t('confirm-global-orderbook-listing')} </Text>
          <Button theme={theme} type="primary" label={t('list-now')} disabled={isProcessing} isSubmit />
        </FormContainer>
        <Button
          theme={theme}
          type="secondary"
          label={t('Go back')}
          onClick={() => {
            setPageState('main');
            resetSendState();
          }}
        />
      </ConfirmContent>
    </ContentWrapper>
  );

  return (
    <Content>
      <TopNav />
      <Show when={isProcessing && pageState === 'main'}>
        <PageLoader theme={theme} message={t('loading-tokens')} />
      </Show>
      <Show when={isProcessing && pageState === 'addft'}>
        <PageLoader theme={theme} message={t('adding-tokens')} />
      </Show>
      <Show when={isProcessing && pageState === 'transfer'}>
        <PageLoader theme={theme} message={t('transferring-nft')} />
      </Show>
      <Show when={isProcessing && pageState === 'list'}>
        <PageLoader theme={theme} message={t('listing-ordinal')} />
      </Show>
      <Show when={isProcessing && pageState === 'cancel'}>
        <PageLoader theme={theme} message={t('cancelling-listing')} />
      </Show>
      <Show when={isProcessing && pageState === 'sendTBC20'}>
        <PageLoader theme={theme} message={t('sending-tbc20')} />
      </Show>
      <Show when={isProcessing && pageState === 'createNFT'}>
        <PageLoader theme={theme} message={t('creating-nft')} />
      </Show>
      <Show when={isProcessing && pageState === 'createCollection'}>
        <PageLoader theme={theme} message={t('creating-collection')} />
      </Show>
      <Show when={isProcessing && pageState === 'collectionContent'}>
        <PageLoader theme={theme} message={t('loading-nfts')} />
      </Show>
      <Show when={isProcessing && pageState === 'nft'}>
        <PageLoader theme={theme} message={t('loading-nfts')} />
      </Show>
      <Show when={isProcessing && pageState === 'nftHistory'}>
        <PageLoader theme={theme} message={t('loading')} />
      </Show>
      <Show when={isProcessing && pageState === 'ftHistory'}>
        <PageLoader theme={theme} message={t('loading')} />
      </Show>
      <Show when={!isProcessing && pageState === 'main'}>{main}</Show>
      <Show when={!isProcessing && pageState === 'receive'}>{receive}</Show>
      <Show when={!isProcessing && pageState === 'receiveft'}>{receiveft}</Show>
      <Show when={!isProcessing && pageState === 'transfer'}>{transfer}</Show>
      <Show when={!isProcessing && pageState === 'sendTBC20'}>{sendTBC20View}</Show>
      <Show when={!isProcessing && pageState === 'list'}>{list}</Show>
      <Show when={!isProcessing && pageState === 'cancel'}>{cancel}</Show>
      <Show when={!isProcessing && pageState === 'nft'}>{nft}</Show>
      <Show when={!isProcessing && pageState === 'createNFT'}>{CreateNFT}</Show>
      <Show when={!isProcessing && pageState === 'createCollection'}>{CreateCollection}</Show>
      <Show when={!isProcessing && pageState === 'collection'}>{collection}</Show>
      <Show when={!isProcessing && pageState === 'collectionContent'}>{collectionContent}</Show>
      <Show when={!isProcessing && pageState === 'nftDetailsInColl'}>{NFTDetailsInColl}</Show>
      <Show when={!isProcessing && pageState === 'nftDetails'}>{NFTDetails}</Show>
      <Show when={!isProcessing && pageState === 'addft'}>{addft}</Show>
      <Show when={!isProcessing && pageState === 'nftbyline'}>{nftbyline}</Show>
      <Show when={!isProcessing && pageState === 'ftDetail'}>{ftDetail}</Show>
      <Show when={!isProcessing && pageState === 'ftHistory'}>{ftHistoryView}</Show>
      <Show when={!isProcessing && pageState === 'nftHistory'}>{nftHistoryView}</Show>
      <Show when={!isProcessing && pageState === 'ftHistoryDetail'}>{ftHistoryDetail}</Show>
      <Show when={!isProcessing && pageState === 'nftHistoryDetail'}>{nftHistoryDetail}</Show>
    </Content>
  );
};
