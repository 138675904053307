import axios from 'axios';
import { P2PKHAddress } from 'bsv-wasm-web';
import { TBC_DECIMAL_CONVERSION, WOC_BASE_URL, WOC_TESTNET_BASE_URL, FEE_PER_BYTE, P2PKH_INPUT_SIZE } from '../utils/constants';
import { NetWork } from '../utils/network';
import { storage } from '../utils/storage';
import { StoredUtxo, UTXO } from './useTbc';
import { useNetwork } from './useNetwork';
import { sleep } from '../utils/sleep';
import { useAccounts } from './useAccounts';

export type WocUtxo = {
  height: number;
  tx_pos: number;
  tx_hash: string;
  value: number;
};

export type ChainInfo = {
  chain: string;
  blocks: number;
  headers: number;
  bestblockhash: string;
  difficulty: number;
  mediantime: number;
  verificationprogress: number;
  pruned: boolean;
  chainwork: string;
};

export const useTuringBitChain = () => {
  const { getStorage, updateStorage } = useAccounts();
  const { network } = useNetwork();
  const config =
    network === NetWork.Mainnet
      ? {
        headers: {
          // 'tbc-api-key': apiKey,
        },
      }
      : undefined;

  const getBaseUrl = () => {
    return network === NetWork.Mainnet ? WOC_BASE_URL : WOC_TESTNET_BASE_URL;
  };


  const getTbcBalance = async (address: string): Promise<number | undefined> => {//这里是计算币的余额
    const { data: { data: innerData } } = await axios.get(`https://turingwallet.xyz/v1/tbc/main/address/${address}/get/balance`);
    const tbcTotal = innerData.balance / TBC_DECIMAL_CONVERSION;
    return tbcTotal;
  };

  const getUtxos = async (fromAddress: string, pullFresh = true): Promise<StoredUtxo[]> => {//这里是获取utxos
    return new Promise(async (resolve) => {
      const StorageData = await getStorage();
      
      const currAccount = StorageData.accounts[fromAddress];
      
      const paymentUtxos = currAccount.paymentUtxos;

      if (pullFresh) {
        const emptyPaymentUtxos: StoredUtxo[] = [];
        const newCurrAccount = { [fromAddress]: { ...currAccount, emptyPaymentUtxos } };
        await updateStorage({ accounts: { ...StorageData.accounts, newCurrAccount } });
      }
      try {
        let localUtxos: StoredUtxo[] = paymentUtxos || [];

        if (!pullFresh && localUtxos.length > 0) {
          resolve(
            localUtxos.filter((utxo) => !utxo.spent).sort((a: UTXO, b: UTXO) => (a.satoshis > b.satoshis ? -1 : 1)),
          );
          return;
        }

        const { data } = await axios.get(`https://turingwallet.xyz/v1/tbc/main/address/${fromAddress}/unspent/`, config);//这里是本地获取btc的网址 注意cors问题
        const explorerUtxos: UTXO[] = data
          .filter((u: WocUtxo) => u.value !== 1) // Ensure we are never spending 1 sats
          .map((utxo: WocUtxo) => {
            return {
              satoshis: utxo.value,
              vout: utxo.tx_pos,
              txid: utxo.tx_hash,
              script: P2PKHAddress.from_string(fromAddress).get_locking_script().to_hex(),
            } as UTXO;
          });
        // Add new UTXOs from explorer that are not in the local storage
        const newUtxos = explorerUtxos.filter(
          (explorerUtxo) => !localUtxos.some((storedUtxo) => storedUtxo.txid === explorerUtxo.txid),
        );
        localUtxos.push(...newUtxos.map((newUtxo) => ({ ...newUtxo, spent: false, spentUnixTime: 0 })));

        // Remove spent UTXOs older than 3 days
        const currentDate = new Date();
        const thresholdUnixTime = currentDate.getTime() - 3 * 24 * 60 * 60; // 3 days in seconds
        const recentUtxos = localUtxos.filter(
          (utxo) => !utxo.spent || (utxo.spentUnixTime >= thresholdUnixTime && utxo.spent),
        );

        // Update local storage to include both unspent and recently spent transactions
        const newCurrAccount = { [fromAddress]: { ...currAccount, paymentUtxos: recentUtxos } };
        await updateStorage({ accounts: { ...StorageData.accounts, ...newCurrAccount } });

        const unspent = recentUtxos
          .filter((utxo) => !utxo.spent)
          .sort((a: UTXO, b: UTXO) => (a.satoshis > b.satoshis ? -1 : 1));

        resolve(unspent);
      } catch (error) {
        //console.log(error);
        resolve([]);
      }
    });
  };


  const getftUtxosSuitableByContractId = async (fromAddress: string, ft_amount: number | BigInt, contract_id: string) => {//这里是获取utxos
    try {
      const { data } = await axios.get(`https://turingwallet.xyz/v1/tbc/main/ft/utxo/address/${fromAddress}/contract/${contract_id}`, config);
      if (!data) throw new Error('Failed to fetch FT utxo!');
      const decimal = data.ftUtxoList[0].ftDecimal;
      const totalFtBalance = typeof ft_amount === 'number' ? BigInt(Math.floor(ft_amount * Math.pow(10, decimal))) : ft_amount;
      // const suitableFtutxos = data.ftUtxoList.filter((ftutxo: { ftBalance: number; }) => ftutxo.ftBalance > totalFtBalance + 1);
      // 遍历所有的ftutxo，检查是否有ftBalance大于 totalFtBalance + 1
      for (let i = 0; i < data.ftUtxoList.length; i++) {
        const ftutxo = data.ftUtxoList[i];
        if (ftutxo.ftBalance > totalFtBalance) {
          // 如果找到符合条件的单个ftutxo，直接返回该ftutxo
          return ftutxo;
        }
      }
      return null;
    } catch (error: any) {
      //console.log(error);
      throw new Error(error.message);
    }
  };

  const getftUtxosSuitableByLpHash = async (fromAddress: string, ft_amount: number, hash: string) => {//这里是获取utxos
    try {
      const { data } = await axios.get(`https://turingwallet.xyz/v1/tbc/main/ft/lp/unspent/by/script/hash${hash}`, config);
      if (!data) throw new Error('Failed to fetch FT utxo!');
      const decimal = data.ftUtxoList[0].ftDecimal;
      const totalFtBalance = BigInt(Math.floor(ft_amount * Math.pow(10, decimal)));
      // 遍历所有的ftutxo，检查是否有ftBalance大于 totalFtBalance + 1
      for (let i = 0; i < data.ftUtxoList.length; i++) {
        const ftutxo = data.ftUtxoList[i];
        if (ftutxo.ftBalance > totalFtBalance) {
          // 如果找到符合条件的单个ftutxo，直接返回该ftutxo
          return ftutxo;
        }
      }
      return null;
    } catch (error) {
      //console.log(error);
      throw new Error("Failed to merge FT UTXO.");
    }
  };

  const getExchangeRate = async (): Promise<any> => {
    const res = await axios.get(`https://turingwallet.xyz/v1/tbc/main/exchangerate/`, config);
    if (!res.data) {
      throw new Error('Could not fetch exchange rate ');
    }
    const changePercent = res.data.change_percent;
    const rate = Number(res.data.rate.toFixed(3));
    return ({ rate, changePercent });
  };


  const getRawTxById = async (txid: string): Promise<string | undefined> => {
    try {
      const { data } = await axios.get(`${getBaseUrl()}/tx/${txid}/hex`, config);
      return data;
    } catch (error) {
      //console.log(error);
    }
  };

  const broadcastRawTx = async (txhex: string): Promise<string | undefined> => {
    try {
      const { data: txid } = await axios.post(`${getBaseUrl()}/tx/raw`, { txhex }, config);
      return txid;
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        // Access to config, request, and response
        console.error('broadcast rawtx failed:', error.response.data);
      } else {
        console.error('broadcast rawtx failed:', error);
      }
    }
  };

  const getSuitableUtxo = (utxos: UTXO[], minimum: number) => {
    const suitableUtxos = utxos.filter((utxo) => utxo.satoshis >= minimum);

    if (suitableUtxos.length === 0) {
      return;
    }
    // Select a random UTXO from the suitable ones
    const randomIndex = Math.floor(Math.random() * suitableUtxos.length);
    return suitableUtxos[randomIndex];
  };

  const getInputs = (utxos: UTXO[], satsOut: number, isSendAll: boolean = false) => {
    if (isSendAll) return utxos;
    let sum = 0;
    let index = 0;
    let inputs: UTXO[] = [];

    while (sum <= satsOut) {
      if (index >= utxos.length) {
        return { error: ('insufficient-funds') };
      }
      const utxo = utxos[index];
      sum += utxo.satoshis;
      inputs.push(utxo);
      index++;
      satsOut += P2PKH_INPUT_SIZE * FEE_PER_BYTE
    }
    return inputs;
  };

  const getInputs_merge = (utxos: UTXO[], satsOut: number, isSendAll: boolean = false) => {
    if (isSendAll) return utxos;

    const adjustedSatsOut = satsOut + P2PKH_INPUT_SIZE * FEE_PER_BYTE;
    for (let i = 0; i < utxos.length; i++) {
      if (utxos[i].satoshis >= adjustedSatsOut) {
        return [utxos[i]];
      }
    }

    let sum = 0;
    let index = utxos.length - 1;
    let inputs: UTXO[] = [];

    while (sum <= satsOut) {
      if (index < 0) {
        return { error: ('insufficient balance') };
      }
      const utxo = utxos[index];
      sum += utxo.satoshis;
      inputs.push(utxo);
      index--;
      satsOut += P2PKH_INPUT_SIZE * FEE_PER_BYTE
    }
    return inputs;
  };

  const getChainInfo = async (): Promise<ChainInfo | undefined> => {
    try {
      const { data } = await axios.get(`${getBaseUrl()}/chain/info`, config);
      return data as ChainInfo;
    } catch (error) {
      //console.log(error);
    }
  };

  return {
    getUtxos,
    getTbcBalance,
    getExchangeRate,
    getRawTxById,
    getBaseUrl,
    broadcastRawTx,
    getSuitableUtxo,
    getInputs,
    getInputs_merge,
    getChainInfo,
    getftUtxosSuitableByContractId,
    getftUtxosSuitableByLpHash,
  };
};
