import styled from 'styled-components';
import { TransactionData, useMultisigTransaction } from '../../hooks/useMultisigTransaction';
import { ColorThemeProps } from '../../theme';
import { useTheme } from '../../hooks/useTheme';
import { SelectSwitchRow } from '../../components/SelectSwitchRow';
import { useState } from 'react';
import { BackButton } from '../../components/BackButton';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import leftArrow from '../../assets/left-arrow.svg';
import rightArrow from '../../assets/right-arrow.svg';
import copyIcon from '../../assets/copy-green.svg';
import { truncate } from '../../utils/format';
import { useSnackbar } from '../../hooks/useSnackbar';
import { Button } from '../../components/Button';
import { FormContainer } from '../../components/Reusable';
import { Input } from '../../components/Input';
import { useOrds } from '../../hooks/useOrds';
import { sleep } from '../../utils/sleep';
import { useKeys } from '../../hooks/useKeys';
import { useTbc } from '../../hooks/useTbc';
import { FC } from 'react';
import axios from 'axios';

interface MsTransactionProps {
  pubkey: string;
}

export const Text = styled.p<ColorThemeProps>`
  font-size: 0.6rem;
  color: ${({ theme }) => theme.gray ?? theme.white};
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin: 0.25rem 0 1rem 0;
  text-align: center;
  width: 100%;
`;

const DetailContainer = styled.div`
  width: 90%;
  background-color: ${({ theme }) => theme.darkAccent};
  padding: 0.5rem;
  border-radius: 1rem;
  margin-top: 0.75rem;
`;

const CopyWrapper = styled.div`
  text-align: left;
  padding: 0.5rem;
  cursor: pointer;
`;

const DetailText = styled.p<ColorThemeProps>`
  font-size: 0.85rem;
  color: ${({ theme }) => theme.gray ?? theme.white};
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  margin: 0 0 0 0.5rem;
  text-align: left;
  width: 100%;
`;

const StyledCopy = styled.img`
  width: 1rem;
  height: 1rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
`;

export const MsTransaction: FC<MsTransactionProps> = ({ pubkey }) => {
  const { theme } = useTheme();
  const [selected, setSelected] = useState('0');
  const { addSnackbar } = useSnackbar();
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { filteredTransactions, currentPage, totalPages, fetchNextPage, fetchPrevPage, changeStatusFilter } =
    useMultisigTransaction(pubkey);
  const { isProcessing, setIsProcessing } = useOrds();
  const { verifyPassword, tbcPubKey } = useKeys();
  const { signMultiTbcTransaction, signMultiFTTransaction, finishMultiTransaction } = useTbc();

  const options = [
    { value: '0', label: t('Transaction completed') },
    { value: '1', label: t('Transaction to be broadcast') },
    { value: '2', label: t('Transaction to be completed') },
    { value: '3', label: t('Transaction to be signed') },
  ];

  const handleChange = (selectedOption: { value: string; label: string }) => {
    if (selectedOption && selectedOption.value !== selected) {
      setSelected(selectedOption.value);

      switch (selectedOption.value) {
        case '0':
          changeStatusFilter(0);
          break;
        case '1':
          changeStatusFilter(1);
          break;
        case '2':
          changeStatusFilter(2);
          break;
        case '3':
          changeStatusFilter(3);
          break;
        default:
          console.log('Unknown status value');
          break;
      }
    }
  };

  const handleCopyToClipboard = (value: string | number) => {
    const textToCopy = typeof value === 'number' ? value.toString() : value;
    navigator.clipboard.writeText(textToCopy).then(() => {
      addSnackbar('Copied!', 'success');
    });
  };

  const handleFinishTransaction = async (filteredTransaction: TransactionData) => {
    const sigs: string[][] = filteredTransaction.json_info.collected_sig_list[0].sig_list.map((_, colIndex) =>
      filteredTransaction.json_info.collected_sig_list.map((item) => item.sig_list[colIndex]),
    );
    try {
      const txId = await finishMultiTransaction(
        { txraw: filteredTransaction.tx_raw, amounts: filteredTransaction.json_info.vin_balance_list },
        sigs,
        filteredTransaction.json_info.pubkey_list,
      );
      const response = await axios.get(
        `https://turingwallet.xyz/multy/sig/notice/${filteredTransaction.unsigned_txid}`, {
        params: { txid: txId }
      }
      );
      if (response.data.status == 0) {
        addSnackbar(t('send-successful'), 'success');
      } else {
        throw new Error(`Transaction failed: ${response.data.message}`);
      }
    } catch (error) {
      addSnackbar(t('send fail'), 'error');
      return;
    }
  };

  const handleWithDrawTransaction = async (txId: string) => {
    try {
      const response = await axios.get(
        `https://turingwallet.xyz/multy/sig/withdraw/history/${txId}`);
      if (response.data.status == 0) {
        addSnackbar(t('withdraw-successful'), 'success');
      } else {
        throw new Error(`Withdraw failed: ${response.data.message}`);
      }
    } catch (error) {
      addSnackbar(t('withdraw fail'), 'error');
      return;
    }
  };

  const handleSignTransaction = async (filteredTransaction: TransactionData) => {
    setIsProcessing(true);
    await sleep(25);
    if (!passwordConfirm) {
      addSnackbar(t('you-must-enter-a-password'), 'error');
      setIsProcessing(false);
      return;
    }

    const isVerified = await verifyPassword(passwordConfirm);
    if (!isVerified) {
      addSnackbar(t('invalid-password'), 'error');
      setIsProcessing(false);
      return;
    }
    try {
      if (filteredTransaction.ft_contract_id.length === 64) {
        const sigs = await signMultiFTTransaction(
          passwordConfirm,
          filteredTransaction.multi_sig_address,
          { txraw: filteredTransaction.tx_raw, amounts: filteredTransaction.json_info.vin_balance_list },
          filteredTransaction.ft_contract_id,
        );
        const request = { pubkey: tbcPubKey, sig_list: sigs };
        const response = await axios.post(
          `https://turingwallet.xyz/multy/sig/add/multi/sig/${filteredTransaction.unsigned_txid}`,
          request,
        );
        if (response.data.status == 0) {
          addSnackbar(t('sig-successful'), 'success');
        } else {
          throw new Error(`Transaction failed: ${response.data.message}`);
        }
      } else {
        const sigs = await signMultiTbcTransaction(passwordConfirm, filteredTransaction.multi_sig_address, {
          txraw: filteredTransaction.tx_raw,
          amounts: filteredTransaction.json_info.vin_balance_list,
        });
        const request = { pubkey: tbcPubKey, sig_list: sigs };
        const response = await axios.post(
          `https://turingwallet.xyz/multy/sig/add/multi/sig/${filteredTransaction.unsigned_txid}`,
          request,
        );
        if (response.data.status == 0) {
          addSnackbar(t('send-successful'), 'success');
        } else {
          throw new Error(`Transaction failed: ${response.data.message}`);
        }
      }
    } catch (error) {
      addSnackbar(t('sign fail'), 'error');
      return;
    }
  };

  return (
    <div
      style={{
        width: '100%',
        color: theme.white,
        borderRadius: '0.5rem',
        marginTop: '2rem',

        height: '100%',
      }}
    >
      <BackButton onClick={() => navigate('/')} />
      <Text style={{ fontSize: '1.5em', fontWeight: 'bold', marginBottom: '1rem', color: theme.white }}>
        {t('multisig-transaction-details')}
      </Text>

      <div style={{ marginLeft: '1.5rem', marginRight: '1.5rem' }}>
        <SelectSwitchRow options={options} selected={selected} theme={theme} onChange={handleChange} />
      </div>

      <div
        style={{
          marginTop: '1.5rem',
          marginLeft: '1.2rem',
          overflowY: 'auto',
          height: '65%',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        }}
      >
        {filteredTransactions.map((filteredTransaction) => (
          <DetailContainer theme={theme} key={filteredTransaction.unsigned_txid}>
            {filteredTransaction.tx_status === 0 && (
              <CopyWrapper style={{ marginBottom: '0.25rem' }}>
                <DetailText
                  style={{
                    color: 'rgba(255, 255, 255, 0.75)',
                    fontSize: '0.8rem',
                  }}
                >
                  {t('txid')}:
                </DetailText>
                <DetailText
                  style={{ fontSize: '1rem' }}
                  onClick={() => handleCopyToClipboard(filteredTransaction.txid)}
                >
                  <span>
                    {filteredTransaction.txid ? truncate(filteredTransaction.txid, 12, 12) : ""}
                    {filteredTransaction.txid && <StyledCopy src={copyIcon} />}
                  </span>
                </DetailText>
              </CopyWrapper>
            )}

            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText
                style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}
              >
                {t('sender-address')}:
              </DetailText>
              <DetailText
                style={{ fontSize: '1rem' }}
                onClick={() => handleCopyToClipboard(filteredTransaction.multi_sig_address)}
              >
                <span>
                  {truncate(filteredTransaction.multi_sig_address, 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>

            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText
                style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}
              >
                {t('recipient-address')}:
              </DetailText>
              <DetailText
                style={{ fontSize: '1rem' }}
                onClick={() => handleCopyToClipboard(filteredTransaction.json_info.receiver_addresses[0])}
              >
                <span>
                  {truncate(filteredTransaction.json_info.receiver_addresses[0], 12, 12)}
                  <StyledCopy src={copyIcon} />
                </span>
              </DetailText>
            </CopyWrapper>

            <CopyWrapper style={{ marginBottom: '0.25rem' }}>
              <DetailText
                style={{
                  color: 'rgba(255, 255, 255, 0.75)',
                  fontSize: '0.8rem',
                }}
              >
                {t('Balance')}:
              </DetailText>
              <DetailText style={{ fontSize: '1rem' }}>
                {filteredTransaction.ft_contract_id.length != 0
                  ? `${(filteredTransaction.balance * Math.pow(10, -filteredTransaction.ft_decimal)).toFixed(6)} ft`
                  : `${(filteredTransaction.balance * Math.pow(10, -6)).toFixed(6)} tbc`}
              </DetailText>
            </CopyWrapper>

            {filteredTransaction.tx_status === 1 && (
              <Button
                label={t('finish')}
                theme={theme}
                type="primary"
                onClick={(e) => {
                  e.preventDefault();
                  handleFinishTransaction(filteredTransaction);
                }}
              />
            )}

            {filteredTransaction.tx_status === 3 && (
              <FormContainer
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSignTransaction(filteredTransaction);
                }}
              >
                <Input
                  theme={theme}
                  name="password"
                  placeholder={t('password')}
                  type="password"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                />
                <Button theme={theme} type="primary" label={t('Sign')} disabled={isProcessing} isSubmit />
              </FormContainer>
            )}

            {filteredTransaction.tx_status !== 0 && (
              <Button
                label={t('withdraw')}
                theme={theme}
                type="warn"
                onClick={(e) => {
                  e.preventDefault();
                  handleWithDrawTransaction(filteredTransaction.unsigned_txid);
                }}
              />
            )}
          </DetailContainer>
        ))}
      </div>

      {/* <div>
                <button onClick={fetchPrevPage} disabled={currentPage === 1}>
                    Previous Page
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={fetchNextPage} disabled={currentPage === totalPages}>
                    Next Page
                </button>
            </div> */}

      {totalPages > 1 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            bottom: '0.5rem',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: currentPage === 1 ? 'not-allowed' : 'pointer',
              opacity: currentPage === 1 ? 0.5 : 1,
            }}
            onClick={currentPage > 1 ? fetchPrevPage : undefined}
          >
            <img
              src={leftArrow}
              alt={t('last-page')}
              style={{ width: '20px', height: '20px', marginRight: '0.5rem', marginTop: '0.2rem' }}
            />
            <span style={{ color: theme.white, fontSize: '1rem' }}>{t('last-page')}</span>
          </div>

          <div style={{ margin: '0 1.5rem', color: theme.white, fontSize: '1rem' }}>{currentPage}</div>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              cursor: currentPage === totalPages ? 'not-allowed' : 'pointer',
              opacity: currentPage === totalPages ? 0.5 : 1,
            }}
            onClick={currentPage < totalPages ? fetchNextPage : undefined}
          >
            <span style={{ color: theme.white, fontSize: '1rem', marginRight: '0.5rem' }}>{t('next-page')} </span>
            <img src={rightArrow} alt={t('next-page')} style={{ width: '20px', height: '20px', marginTop: '0.2rem' }} />
          </div>
        </div>
      )}
    </div>
  );
};
