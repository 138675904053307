import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import init from 'bsv-wasm-web';
import styled from 'styled-components';
import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import { PageLoader } from '../../components/PageLoader';
import { HeaderText, Text, BigCoinLogo } from '../../components/Reusable';
import { Show } from '../../components/Show';
import { useBottomMenu } from '../../hooks/useBottomMenu';
import { useKeys} from '../../hooks/useKeys';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useTheme } from '../../hooks/useTheme';
import turingsLogo from '../../assets/turingsuccess.png';
import { useTranslation } from 'react-i18next';
import { useAccounts } from '../../hooks/useAccounts';
import { useLocation } from 'react-router-dom';

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
`;
export type ImportAccountProps = {
  onNavigateBack: () => void;
  isFirstLoaded?: boolean;
  isTaproot?:boolean;
};
export const ImportWallet = ({ onNavigateBack, isFirstLoaded = false,isTaproot=false }: ImportAccountProps) => {
  const { theme } = useTheme();
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const location = useLocation(); // 用于获取当前页面的URL
  const urlParams = new URLSearchParams(location.search);
  const type = urlParams.get('type') 
  urlParams.delete('type');
  const {switchAccount}=useAccounts();
  const [step, setStep] = useState(1);
  const [accountName, setAccountName] = useState('');
  const [payPk, setPayPk] = useState('');
  const [ordPk, setOrdPk] = useState('');
  const [identityPk, setIdentityPk] = useState('');

  const { addSnackbar } = useSnackbar();
  const { generateKeysFromWifAndStoreEncrypted,generateTaprootKeysFromWifAndStoreEncrypted } = useKeys();
  const { hideMenu, showMenu } = useBottomMenu();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    hideMenu();

    return () => {
      showMenu();
    };
  }, [hideMenu, showMenu]);

  const handleImport = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      setLoading(true);
      if (password.length < 8) {
        setLoading(false);
        addSnackbar(t('the-password-must-be-at-least-8-characters'), 'error');
        return;
      }

      if (isFirstLoaded&&password !== passwordConfirm) {
        addSnackbar(t('the-passwords-do-not-match'), 'error');
        return;
      }

      // if (!payPk || !ordPk) {
      //   addSnackbar('Both payPk and ordPk WIFs are required!', 'error');
      //   return;
      // }

      if (!payPk) {
        addSnackbar(t('paypk-wifs-are-required'), 'error');
        return;
      }
     
      if (payPk.length < 32) {
        setLoading(false);
        addSnackbar(t('the-payPk-must-be-at-least-32-characters'), 'error');
        return;
      }
      let keys;
      await init();
      if(type==='wif'||!isTaproot){
       keys = await generateKeysFromWifAndStoreEncrypted(
        accountName,
        password, {
        payPk,
        ordPk,
        identityPk,
      },
      isFirstLoaded);
    }else{
      keys=await generateTaprootKeysFromWifAndStoreEncrypted(
        accountName,
        password, {
        payPk,
        ordPk,
        identityPk,
      },
      isFirstLoaded);
    }
      if (!keys.walletAddress) {
        addSnackbar(t('an-error-occurred-while-restoring-the-wallet'), 'error');
        setLoading(false);
        setStep(2);
        return;
      }
      if(isFirstLoaded){
        await switchAccount(keys.walletAddress);
        }
      setStep(3);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  };

  // const handleJsonUploadClick = () => {
  //   hiddenFileInput.current?.click();
  // };

  // const handleFileRead = async (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const file = event.target.files?.[0];
  //   if (file && file.type === 'application/json') {
  //     const reader = new FileReader();
  //     reader.onload = async (e) => {
  //       const text = e.target?.result as string;
  //       try {
  //         const jsonData = JSON.parse(text) as WifKeys;
  //         if (!jsonData.payPk || !jsonData.ordPk) {
  //           addSnackbar('Invalid 1Sat Ord Wallet format!', 'error');
  //           return;
  //         }
  //         if (jsonData.mnemonic) {
  //           addSnackbar(
  //             'Invalid 1Sat Ord Wallet format. File contains seed phrase. Please use a different restore method using your seed phrase!',
  //             'error',
  //             7000,
  //           );
  //           return;
  //         }
  //         setPayPk(jsonData.payPk ? jsonData.payPk : '');
  //         setOrdPk(jsonData.ordPk ? jsonData.ordPk : '');
  //         setIdentityPk(jsonData.identityPk ? jsonData.identityPk : '');
  //         setStep(2);
  //       } catch (error) {
  //         console.error('Error parsing JSON file', error);
  //         addSnackbar('Error parsing JSON file!', 'error');
  //         return;
  //       }
  //     };
  //     reader.readAsText(file);
  //   } else {
  //     console.error('Unsupported file type. Please upload a JSON file.');
  //     addSnackbar('Unsupported file type. Please upload a JSON file.', 'error');
  //   }
  // };

  const passwordStep = (
    <>
      {/* <BackButton onClick={() => navigate('/')} /> */}
      <Content style={{marginTop:'4rem'}}>
        <HeaderText theme={theme}>{isFirstLoaded?t('create-a-password'):t('Import Account')}</HeaderText>
        <Text theme={theme}>{isFirstLoaded?t('this-is-used-to-unlock-your-wallet'):t('Enter your existing password')}</Text>
        <FormContainer onSubmit={handleImport}>
        <Input
          theme={theme}
          placeholder={t('Account Name')}
          type="text"
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          style={{borderColor: accountName.length>10 ? 'red' : ''} }
        />
        {accountName.length>10 ? (
          <Text style={{ color: 'red', margin: '0' }}>name too long</Text>
        ) : null}
          <Input
            theme={theme}
            placeholder={t('password')}
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Show when={isFirstLoaded}>
          <Input
            theme={theme}
            placeholder={t('confirm-password-0')}
            type="password"
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            style={{ marginBottom: '2rem' }}
          />
          </Show>
          <Button theme={theme} type="primary" label={t('finish')} isSubmit disabled={accountName.length>10 ? true:false}/>
          <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => (isFirstLoaded ? navigate('/') : onNavigateBack())} />
        </FormContainer>
      </Content>
    </>
  );

  const enterWifsStep = (
    <>
      {/* <BackButton onClick={() => navigate('/')} /> */}
      <Content style={{marginTop:'4rem'}}>
        <HeaderText theme={theme}>{(type==='wif'||!isTaproot)?t('import-a-wif-wallet'):t('import-taproot-keys')}</HeaderText>
        <Text theme={theme}>{t('input-assets-directly-from-your-wif-private-keys')}</Text>
        <FormContainer onSubmit={() => setStep(2)}>
          <Input
            theme={theme}
            placeholder={t('pay-wif-private-key')}
            type="text"
            value={payPk}
            onChange={(e) => setPayPk(e.target.value.trim())}
            style={{ margin: '0.25rem' }}
          />
          {/* <Input
            theme={theme}
            placeholder="Ord WIF private key"
            type="text"
            value={ordPk}
            onChange={(e) => setOrdPk(e.target.value)}
            style={{ margin: '0.25rem' }}
          />
          <Input
            theme={theme}
            placeholder="Identity WIF private key"
            type="text"
            value={identityPk}
            onChange={(e) => setIdentityPk(e.target.value)}
            style={{ margin: '0.25rem' }}
          /> */}
          <Text theme={theme} style={{ margin: '1rem 0 1rem' }}>
            {t('make-sure-you-are-in-a-safe-place-and-no-one-is-watching')} </Text>
          <Button theme={theme} type="primary" label={t('next')} isSubmit />
          <Button theme={theme} type="secondary" label={t('Go back')} onClick={() => (isFirstLoaded ? navigate('/') : onNavigateBack())} />
        </FormContainer>
        {/* <Text style={{ margin: '1rem' }} theme={theme}>
          ------ OR ------
        </Text>
        <Button
          theme={theme}
          type="secondary"
          onClick={handleJsonUploadClick}
          label="Upload JSON"
          style={{ margin: 0 }}
        />
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileRead}
          style={{ display: 'none' }}
          accept="application/json"
        /> */}
      </Content>
    </>
  );

  const successStep = (
    <>
      <Content style={{marginTop:'4rem'}}>
        <BigCoinLogo src={turingsLogo} />
        <div style={{ marginTop: "50px", marginBottom: "40px" }}>
          <HeaderText theme={theme}>{t('success')}!</HeaderText>
          <Text theme={theme} style={{ marginBottom: '1rem' }}>
            {t('your-wallet-has-been-imported')} </Text>
        </div>
        <Button
          theme={theme}
          type="primary"
          label={t('enter')}
          onClick={() => {
            window.location.reload();
          }}
        />
      </Content>
    </>
  );

  return (
    <>
      <Show when={loading}>
        <PageLoader theme={theme} message={t('importing-wallet')} />
      </Show>
      <Show when={!loading && step === 1}>{enterWifsStep}</Show>
      <Show when={!loading && step === 2}>{passwordStep}</Show>
      <Show when={!loading && step === 3}>{successStep}</Show>
    </>
  );
};
